import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed `;
const AddressLine = tw.span`block text-blue-500`;
const Email = tw.span`text-sm mt-6 block text-black font-bold`;
const Phone = tw.span`text-sm mt-0 block text-black font-bold`;

export default () => {
  return (
    <AnimationRevealPage>
      <ContactUsForm />
      {/* <ContactDetails
        {
          title: "Mumbai",
          description: (
            <>

              <Address>
                <AddressLine>KINGSTON, off JP Road, Shastri Nagar,</AddressLine>
                <AddressLine>Andheri West, Mumbai, 400053</AddressLine>
              </Address>
              <Email>contact@procareer.com</Email>
              <Phone>+91 9916988566</Phone>
            </>
          )
        },
        {
          title: "Pune",
          description: (
            <>
              <Address>
                <AddressLine>GVG4+W3R, Shivajinagar Court Campus</AddressLine>
                <AddressLine>Pune, Maharashtra 411005</AddressLine>
              </Address>
              <Email>contact@procareer.com</Email>
              <Phone>+91 8998665766</Phone>
            </>
          )
        },
        cards={[
          {
            title: "Bangalore",
            description: (
              <>
                <Address>
                  <AddressLine>
                    #12 Second Floor 3rd Cross <br />
                    Patel Narayana Reddy Layout
                    <br />
                    6th Block Koramangala
                    <br />
                    Bengaluru - 560095
                  </AddressLine>
                </Address>
                <Email>hrd@procareers.in</Email>
                <Phone>+91 9606451628</Phone>
              </>
            ),
          },
        ]}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
