import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { localUrl, localUrlImage } from "pages/env";
import { isMobile } from "react-device-detect";
import Applynow from "components/headers/Applynow";
import HCL from "../../images/HCL.png";
import IBM from "../../images/IBM.png";
import Tata2 from "../../images/Tata2.png";
import EMPHASYS from "../../images/EMPHASYS.png";
import TECHMAHINDRA from "../../images/TECHMAHINDRA.png";
import TCS from "../../images/TCS.png";
import Footer from "components/footers/FiveColumnWithInputForm";
import "./Internshipstyle.css";

const Intrenship = () => {
  const mobheading = { height: "auto" };
  const deskheading = {
    height: "500px",
    marginTop: "30px",
    borderRadius: "10px",
  };
  const mob2 = { height: "auto", marginLeft:"25px",marginRight:"20px"};
  const desk2 = { height: "auto", marginTop: "20px",  };
  let { id } = useParams();
  const [internshipDetails, setinternshipDetails] = useState([]);
  const [skill, setSkill] = useState([]);
  const getinternshipDetails = async () => {
    try {
      const res = await fetch(localUrl + `taining/trainingdetails/${id}/`);
      const data = await res.json();
      setinternshipDetails(data);
      console.log(
        data?.students?.skillCoverList,
        "data?.students?.skillCoverList"
      );
      setSkill(data?.students?.skillCoverList);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(internshipDetails, "cc");

  useEffect(() => {
    getinternshipDetails();
  }, [id]);

  console.log("data", skill);

  //for mobile
  const mob = {
    marginLeft: "auto",
    marginRight: "auto",
    padding: "40px",
    marginBottom: "0!important",
    paddingBottom: 0,
  };
  const desk = {};

  const mob1 = {
    marginBottom: "20px",
  };
  const desk1 = {};
  return (
    <div style={{ overflowX: "hidden" }}>
      <main id="main" style={{ minHeight: "60vh" }}>
        <div className="_1jug6qr SDPPage" data-e2e="sdp-page">
          <div className="BannerS12n" data-e2e="banner">
            <div>
              <div className="row">
                <div
                  className="col-lg-6 _giqd p-3"
                  style={isMobile ? mobheading : deskheading}
                >
                  {" "}
                  <div
                    className="_1bjlpa11 BannerTitle text-xs-left"
                    data-test="banner-title-container"
                  >
                    <h1
                      className="banner-title m-b-0 text-white mt-3 text-center"
                      data-e2e="xdp-banner-title"
                    >
                      {internshipDetails?.students?.title} for Everybody
                      Specialization
                    </h1>
                    <p
                      className="max-text-width m-b-0 text-white mt-5 text-center"
                      dir="ltr"
                    >
                      {internshipDetails?.students?.intro}
                    </p>
                  </div>
                  <div className="_1t452v5 text-center mt-3">
                    <div className="_1qfi0x77 XDPRating">
                      <div className="sdpRatingLink">
                        <div className="_1srkxe1s XDPRating">
                          <div
                            data-track="true"
                            data-track-app="xdp_v1"
                            data-track-page="xdp"
                            data-track-action="click"
                            data-track-component="banner_star_rating"
                            role="presentation"
                            style={{ borderBottom: "none" }}
                            data-test="star-rating"
                          >
                            <div
                              aria-hidden="true"
                              className="_1mzojlvw"
                              role="img"
                              style={{ cursor: "inherit" }}
                            >
                              {/* <span className="_13xsef79 d-inline-block">
                                <svg
                                  aria-hidden="true"
                                  className="_ufjrdd"
                                  style={{
                                    fill: "#F7BB56",
                                    height: "14px",
                                    width: "14px",
                                    marginRight: "2px",
                                    verticalAlign: "text-bottom",
                                  }}
                                  viewBox="0 0 46 44"
                                  role="img"
                                  aria-labelledby="FilledStar45c3b098-db3e-4af8-f034-082ca3e3bb43 FilledStar45c3b098-db3e-4af8-f034-082ca3e3bb43Desc"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <title id="FilledStar45c3b098-db3e-4af8-f034-082ca3e3bb43">
                                    Filled Star
                                  </title>
                                  <path
                                    stroke="#7C6919"
                                    strokeWidth={3}
                                    d="M23 36.5L8.893 43.916l2.694-15.708L.175 17.084l15.772-2.292L23 .5l7.053 14.292 15.772 2.292-11.412 11.124 2.694 15.708z"
                                    fillRule="evenodd"
                                    role="presentation"
                                  />
                                </svg>
                              </span>
                              <span className="_13xsef79 d-inline-block">
                              <svg xmlns="http://www.w3.org/2000/svg" 
                              width="16" 
                              height="16" 
                              fill="#F7BB56" 
                              class="bi bi-star-fill" 
                              viewBox="0 0 16 16">
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                              </svg>
                              </span>
                              {/* <span className="_13xsef79 d-inline-block">
                                <svg
                                  aria-hidden="true"
                                  className="_ufjrdd"
                                  style={{
                                    fill: "#F7BB56",
                                    height: "14px",
                                    width: "14px",
                                    marginRight: "2px",
                                    verticalAlign: "text-bottom",
                                  }}
                                  viewBox="0 0 46 44"
                                  role="img"
                                  aria-labelledby="FilledStar752cf74e-f06b-4449-ec2c-92838b633eb3 FilledStar752cf74e-f06b-4449-ec2c-92838b633eb3Desc"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <title id="FilledStar752cf74e-f06b-4449-ec2c-92838b633eb3">
                                    Filled Star
                                  </title>
                                  <path
                                    stroke="#7C6919"
                                    strokeWidth={3}
                                    d="M23 36.5L8.893 43.916l2.694-15.708L.175 17.084l15.772-2.292L23 .5l7.053 14.292 15.772 2.292-11.412 11.124 2.694 15.708z"
                                    fillRule="evenodd"
                                    role="presentation"
                                  />
                                </svg>
                              </span>
                              <span className="_13xsef79 d-inline-block">
                                <svg
                                  aria-hidden="true"
                                  className="_ufjrdd"
                                  style={{
                                    fill: "#F7BB56",
                                    height: "14px",
                                    width: "14px",
                                    marginRight: "2px",
                                    verticalAlign: "text-bottom",
                                  }}
                                  viewBox="0 0 46 44"
                                  role="img"
                                  aria-labelledby="FilledStar97dc7642-acc1-4101-cec2-d6a19bfd3f42 FilledStar97dc7642-acc1-4101-cec2-d6a19bfd3f42Desc"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <title id="FilledStar97dc7642-acc1-4101-cec2-d6a19bfd3f42">
                                    Filled Star
                                  </title>
                                  <path
                                    stroke="#7C6919"
                                    strokeWidth={3}
                                    d="M23 36.5L8.893 43.916l2.694-15.708L.175 17.084l15.772-2.292L23 .5l7.053 14.292 15.772 2.292-11.412 11.124 2.694 15.708z"
                                    fillRule="evenodd"
                                    role="presentation"
                                  />
                                </svg>
                              </span> */}

                              {/* <span className="_13xsef79 d-inline-block">
                                <svg
                                  aria-hidden="true"
                                  className="_ufjrdd"
                                  style={{
                                    fill: "#F7BB56",
                                    height: "14px",
                                    width: "14px",
                                    marginRight: "2px",
                                    verticalAlign: "text-bottom",
                                  }}
                                  viewBox="0 0 46 44"
                                  role="img"
                                  aria-labelledby="FilledStarb08c166d-a862-4105-f93e-3427f772e3c2 FilledStarb08c166d-a862-4105-f93e-3427f772e3c2Desc"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <title id="FilledStarb08c166d-a862-4105-f93e-3427f772e3c2">
                                    Filled Star
                                  </title>
                                  <path
                                    stroke="#7C6919"
                                    strokeWidth={3}
                                    // d="M23 36.5L8.893 43.916l2.694-15.708L.175 17.084l15.772-2.292L23 .5l7.053 14.292 15.772 2.292-11.412 11.124 2.694 15.708z"
                                    fillRule="evenodd"
                                    role="presentation"
                                  />
                                </svg>
                              </span> */}

                              {/* <span className="_13xsef79 d-inline-block">
                                <svg
                                  aria-hidden="true"
                                  className="_ufjrdd"
                                  style={{
                                    fill: "#F7BB56",
                                    height: "30px",
                                    width: "30px",
                                    marginRight: "2px",
                                    verticalAlign: "text-bottom",
                                  }}
                                  viewBox="0 0 20 20"
                                  role="img"
                                  // width="16" height="16" 
                                  aria-labelledby="FilledStar88ac4fdf-3f46-4854-a083-0b2133ea8998 FilledStar88ac4fdf-3f46-4854-a083-0b2133ea8998Desc"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <title id="FilledStar88ac4fdf-3f46-4854-a083-0b2133ea8998">
                                    Filled Star
                                  </title>
                                  <path
                                    stroke="#7C6919"
                                    strokeWidth={3}
                                    d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"
                    
                                    fillRule="evenodd"
                                    role="presentation"
                                  />
                                </svg>
                              </span> */}
                              <span className="_13xsef79 d-inline-block">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#F7BB56"
                                  class="bi bi-star-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                </svg>
                              </span>
                              <span className="_13xsef79 d-inline-block">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#F7BB56"
                                  class="bi bi-star-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                </svg>
                              </span>
                              <span className="_13xsef79 d-inline-block">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#F7BB56"
                                  class="bi bi-star-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                </svg>
                              </span>
                              <span className="_13xsef79 d-inline-block">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#F7BB56"
                                  class="bi bi-star-half"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                </svg>
                              </span>
                              <span className="_13xsef79 d-inline-block">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#F7BB56"
                                  class="bi bi-star-half"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z" />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <span
                            className="_16ni8zai m-b-0 rating-text number-rating number-rating-expertise"
                            style={{ color: "#F7BB56" }}
                            data-test="number-star-rating"
                          >
                            4.5
                            <div className="screenreader-only">stars</div>
                          </span>
                          <div className="_wmgtrl9 color-white ratings-count-expertise-style">
                            <span data-test="ratings-count-without-asterisks">
                              <span>24 ratings</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="_bxbqi1">
                    <div className="rc-S12nBanner">
                      <div className="horizontal-box1 text-center">
                        <div className="rc-ProductMetrics">
                          <div className="_1fpiay2" style={{ color: "#FFF" }}>
                            <span>
                              <strong>
                                <span>127</span>
                              </strong>{" "}
                              already enrolled
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg hidden-sm hidden-xs visible-md-block visible-lg-block"
                  style={{ marginTop: "20px" }}
                >
                  {/* <Applynow updateFormData ={resetFormData} /> */}
                  <Applynow location1={id} />
                </div>
                <div
                  style={{ overflowX: "hidden", height: "auto" }}
                  className="col-lg-12 _giqd p-0 "
                // style={isMobile ? mobheading : deskheading}
                >
                  {" "}
                  <div
                    style={isMobile ? mob2 : desk2}
                    className="_1bjlpa11 BannerTitle text-xs-left"
                    data-test="banner-title-container"
                  >
                    <h1
                      className="banner-title m-b-0 text-white mt-3 text-center"
                      data-e2e="xdp-banner-title"
                    >
                      PROCESS OF INTERNSHIP
                      <hr />
                    </h1>
                    <p
                      className="max-text-width m-b-4 text-white mt-5 text-left"
                      dir="ltr"
                      style={{ fontSize: "15px" }}
                    >
                      <div
                        style={{ marginTop: "-50px", fontSize: "20px" }}
                        className="text-white text-left"
                      >
                        <br />
                        *&nbsp; Real time Project work
                        <br />
                        *&nbsp; certifications
                        <br />
                        *&nbsp; work with experienced developers
                        <br />
                        *&nbsp; company level professioanl skills
                        <br />
                        *&nbsp; Hands-on experience
                        <br />
                        *&nbsp; Exposure to different technologies and tools
                        <br />
                        <br />
                      </div>
                      ✓ &nbsp; The specific things offered in a software
                      development internship can vary depending on the company,
                      the team, and the goals of the internship program.
                      However, in general, a software development internship may
                      offer the following:
                      <br />
                      <br />
                      ✓ &nbsp; Hands-on experience: Interns may work on
                      real-world projects and tasks alongside experienced
                      developers, giving them practical experience in software
                      development.
                      <br />
                      <br />
                      ✓ &nbsp; Exposure to different technologies and tools:
                      Interns may have the opportunity to work with different
                      programming languages, frameworks, and tools that they may
                      not have had experience with before.
                      <br />
                      <br />
                      ✓ &nbsp; Mentorship and guidance: Interns may be paired
                      with a mentor who can provide guidance, feedback, and
                      support as they work on their projects.
                      <br />
                      <br />
                      ✓ &nbsp; Collaboration: Interns may work as part of a
                      team, collaborating with other developers, designers, and
                      stakeholders to create software solutions.
                      <br />
                      <br />
                      ✓ &nbsp; Learning opportunities: Interns may attend
                      training sessions or participate in educational programs
                      to learn more about software development practices and
                      industry trends.
                      <br />
                      <br />
                      ✓ &nbsp; Professional development: Interns may have the
                      opportunity to attend career development events, network
                      with other professionals, and gain insight into the
                      industry.
                      <br />
                      <br />
                      ✓ &nbsp; Feedback and evaluation: Interns may receive
                      feedback on their work and be evaluated on their
                      performance throughout the internship. This can provide
                      valuable insights into their strengths and areas for
                      improvement.
                      <br />
                      <br />✓ &nbsp; Overall, a software development internship
                      can be a valuable learning experience for students or
                      recent graduates who want to pursue a career in software
                      development.
                    </p>
                    <div className="_1uoqnqiz text-center">
                      <div className="with-enroll-modal">
                        <div className="BannerEnroll" data-e2e="banner">
                          <div className="_1qfi0x77">
                            <div className="EnrollButton">
                              <input type="hidden" />
                              <button
                                className="cds-30 cds-1 cds-3 css-on79oc cds-12 cds-button-disableElevation"
                                style={{
                                  backgroundColor: "#FFCB05",
                                  color: "black",
                                  fontWeight: "bold",
                                  borderColor: "transparent",
                                  height: "50px",
                                }}
                              >
                                <span className="cds-2 cds-button-label">
                                  <Link to={`/TrainingPricing/${id}`}>
                                    <span
                                      data-test="enroll-button-label"
                                      className="text-white"
                                    >
                                      Enroll Now
                                    </span>
                                  </Link>
                                </span>
                              </button>
                            </div>
                            <div className="desktop-wrap p-l-2">
                              <p className="m-b-0" />
                              <div
                                className="subtitle-container"
                                style={{
                                  fontSize: "0.75rem",
                                  fontWeight: "bold",
                                  margin: "4px 0",
                                  maxWidth: "400px",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="_1d3lkver">
            <div>
              <div className="css-8c0k8a">
                <a
                  data-click-key="enterprise.xdp.click.c4b_xdp_m2"
                  data-click-value='{"href":"https://www.coursera.org/business?utm_medium=coursera&utm_source=xdp&utm_campaign=website&utm_content=c4b-xdp-m2&utm_term=out","namespace":{"action":"click","app":"enterprise","component":"c4b_xdp_m2","page":"xdp"},"s12nId":"F-h1g0w7EeWeOApO_l5R1w","schema_type":"FRONTEND"}'
                  data-track="true"
                  data-track-app="enterprise"
                  data-track-page="xdp"
                  data-track-action="click"
                  data-track-component="c4b_xdp_m2"
                  data-track-href="https://www.coursera.org/business?utm_medium=coursera&utm_source=xdp&utm_campaign=website&utm_content=c4b-xdp-m2&utm_term=out"
                  href="#"
                  to=""
                  className="css-mwu97z"
                >
                  <p className="cds-33 css-1q6egms cds-35">
                    See how employees at top companies are mastering in-demand
                    skills
                  </p>
                </a>
              </div>
              <div className="row mb-4" style={isMobile ? mob : desk}>
                <div className="col-lg-4 mb-3" style={isMobile ? mob1 : desk1}>
                  <img
                    src={Tata2}
                    alt="tata"
                    style={{
                      height: "90px",
                      width: "250px",
                      alignItems: "center",
                    }}
                  />
                </div>
                <div className="col-lg-4 mb-3" style={isMobile ? mob1 : desk1}>
                  <img
                    src={TCS}
                    alt="TCS"
                    style={{
                      height: "100px",
                      width: "230px",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  />
                </div>
                <div className="col-lg-4 mb-3" style={isMobile ? mob1 : desk1}>
                  <img
                    src={IBM}
                    alt="IBM"
                    style={{
                      height: "110px",
                      width: "250px",
                      alignItems: "center",
                    }}
                  />
                </div>
              </div>
              <div className="row" style={isMobile ? mob : desk}>
                {/* // style={{ padding: "47px", marginLeft: "auto" }}> */}
                <div className="col-lg-4">
                  <img
                    src={TECHMAHINDRA}
                    alt="TECHMAHINDRA"
                    style={{
                      height: "100px",
                      width: "230px",
                      alignItems: "center",
                    }}
                  />
                </div>
                <div className="col-lg-4">
                  <img
                    src={EMPHASYS}
                    alt="EMPHASYS"
                    style={{
                      marginTop: "15px",
                      height: "80px",
                      width: "230px",
                      alignItems: "center",
                    }}
                  />
                </div>
                <div className="col-lg-4 ">
                  <img
                    src={HCL}
                    alt="HCL"
                    style={{
                      height: "180px",
                      width: "290px",
                      alignItems: "center",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="rc-XdpSection css-yw2k7b" tabIndex={-1}>
            <a className="rc-Anchor" name="courses" />
            <div
              className="AboutS12nCourseList p-t-5 p-b-2 border-top border-bottom"
              data-e2e="course-list"
            ></div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Intrenship;
