import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
// import Pro1 from '../../images/demo/Pro1.png';
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import { Link } from "react-router-dom";
// import logo from "../../images/logo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Popover,
  Select,
} from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  ConsoleSqlOutlined,
  DownOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import "./Fixedheader.css";
import { localUrl } from "pages/env.js";
import { useDispatch } from "react-redux";
import { Modal, Space, Tooltip } from "antd";
import { Option } from "antd/lib/mentions";
import { isMobile } from "react-device-detect";
import ModalForm from "./ModalForm.jsx";
import { MenuProps } from "antd";
// import { Dropdown } from "antd";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import "./light.css";
import { BsChevronDown } from "react-icons/bs";
const Header = tw.header`
 justify-between items-center
  // max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-5 lg:text-sm lg:mx-3 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-900 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-2 py-1 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-900 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */
  const mob = {
    marginBottom: "4px",
    marginTop: "15px",
  };

  const desk = {};

  const mob1 = { paddingTop: 0, paddingBottom: 0 };
  const desk1 = { paddingTop: "8px", paddingBottom: "8px" };
  const mob2 = {
    marginRight: "5px",
  };

  const desk2 = {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const { confirm } = Modal;
  const [open, setOpen] = useState(false);

  const showConfirm = () => {
    confirm({
      title: "Are you sure, Do you want to Logout?",
      icon: <ExclamationCircleFilled />,

      onOk: handleOk,
      onCancel: handleCancel,
    });
  };

  const handleOk = () => {
    localStorage.clear();

    navigate("/");
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const [name, setName] = useState();
  const navigate = useNavigate();
  //for routerchange
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    console.log(closeTime);
    if (closeTime < 2) {
      setTimeout(() => {
        setModalOpen(true);
      }, 5000);
    }
  }, [location]);
  // whenever modal get close
  const [closeTime, setCloseTime] = useState(1);
  const modalClose = () => {
    setCloseTime(closeTime + 1);
    setModalOpen(false);
  };

  const count = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();

  const handleClick = () => {
    toggleNavbar();
    showConfirm();
  };
  useEffect(() => {
    console.log(localStorage, "local");
    if (localStorage.getItem("access_token") != null) {
      var myHeaders = new Headers();
      myHeaders.append(
        "authorization",
        "Bearer " + localStorage.getItem("access_token")
      );

      var formdata = new FormData();

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(localUrl + "user/profile/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result, "re");
          setName(result?.name);
        })
        .catch((error) => console.log("error", error));
    }
  }, [count]);
  console.log(name, "nnn");
  const [course, setCourse] = useState([]);
  const [Internship, setInternship] = useState([]);
  const getTrainingCourse = async () => {
    try {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      const res = await fetch(
        localUrl + "taining/traininglist",
        requestOptions
      );
      const data = await res?.json();
      setCourse(data);
    } catch (error) {
      console.log("api is not working");
    }
  };

  const getIntrenship = async () => {
    try {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      const res = await fetch(
        localUrl + "taining/intrenshiplist",
        requestOptions
      );
      const data = await res.json();
      setInternship(data?.students);
      console.log(data);
    } catch (error) {
      console.log("api is not working");
    }
  };

  useEffect(() => {
    getTrainingCourse();
    getIntrenship();
  }, []);
  let items = [
    {
      key: course?.students?.map((val) => val.id),
      label: course?.students?.map((val) => (
        <>
          <button key={val?.id} className="mt-2 w-full">
            <Link
              to={`/training-certificate/${val?.id}`}
              onClick={toggleNavbar}
            >
              {val?.title}
            </Link>
          </button>

          <br />
        </>
      )),
    },
  ];

  console.log("internship", Internship, course);

  const content = (
    <div>
      {Internship.map((intern) => (
        <>
          <Link
            onClick={toggleNavbar}
            to={`/training-certificate/${intern?.id}`}
          >
            {intern.title}
          </Link>
          <br />
        </>
      ))}
    </div>
  );

  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink style={isMobile ? mob : desk}>
        {" "}
        <Link to="/" onClick={toggleNavbar}>
          Home
        </Link>
      </NavLink>
      <NavLink style={isMobile ? mob : desk}>
        {" "}
        <Link to="/about" onClick={toggleNavbar}>
          About Us
        </Link>
      </NavLink>

      <NavLink style={isMobile ? mob : desk}>
        <Link to="/Training" onClick={toggleNavbar}>
          All Courses
        </Link>
      </NavLink>
      <NavLink style={isMobile ? mob : desk}>
        <div class="py-1">
          <div class="group inline-block relative">
            <button class="font-semibold text-[#2b5876] py-2 px-0 inline-flex items-center">
              <span class="mr-1">Training / Certificate</span>
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </button>
            <ul
              className="absolute h-80 overflow-y-auto hidden  bg-white pt-1 group-hover:block"
              style={{ zIndex: 1 }}
            >
              <div className="">
                {course?.students
                  ?.sort(function (a, b) {
                    if (a.title < b.title) {
                      return -1;
                    }
                    if (a.title > b.title) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((train) => (
                    <li className="">
                      <Link
                        onClick={toggleNavbar}
                        className="bg-white hover:bg-blue-400 px-4"
                        to={`/training-certificate/${train?.id}`}
                      >
                        {train.title}
                      </Link>
                    </li>
                  ))}
              </div>
            </ul>
          </div>
        </div>
      </NavLink>
      <NavLink style={isMobile ? mob : desk}>
        <Link to="/hire-talent" onClick={toggleNavbar}>
          Hire Talent
        </Link>
      </NavLink>
      <NavLink style={isMobile ? mob : desk}>
        <Link to="/CareerPage" onClick={toggleNavbar}>
          Career
        </Link>
      </NavLink>
      <NavLink style={isMobile ? mob : desk}>
        <Link to="/contact" onClick={toggleNavbar}>
          Contact Us
        </Link>
      </NavLink>

      <NavLink style={isMobile ? mob : desk}>
        <Link to="/Batch" onClick={toggleNavbar}>
          Batch Trainings
        </Link>
      </NavLink>
      <NavLink style={isMobile ? mob : desk}>
        <div class="py-1">
          <div class="group inline-block relative">
            <button class="font-semibold text-[#2b5876] py-2 px-0 inline-flex items-center">
              <span class="mr-1">Internship</span>
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </button>
            <ul className="absolute h-60 overflow-y-auto hidden  bg-white pt-1 group-hover:block">
              <div className="">
                {Internship.sort(function (a, b) {
                  if (a.title < b.title) {
                    return -1;
                  }
                  if (a.title > b.title) {
                    return 1;
                  }
                  return 0;
                }).map((intern) => (
                  <li className="">
                    <Link
                      onClick={toggleNavbar}
                      className="bg-white hover:bg-blue-400 px-4 "
                      to={`/Intrenship/${intern?.id}`}
                    >
                      {intern.title}
                    </Link>
                  </li>
                ))}
              </div>
            </ul>
          </div>
        </div>
      </NavLink>

      {localStorage.getItem("access_token") != null ? (
        <PrimaryLink>
          <Link
            onClick={toggleNavbar}
            css={roundedHeaderButton && tw`rounded-full`}
            style={{ color: "white" }}
            to="/Applynow"
          >
            APPLY NOW
          </Link>
        </PrimaryLink>
      ) : null}

      <NavLink>
        {localStorage.getItem("access_token") == null ? (
          <div>
            <PrimaryLink>
              <Link
                onClick={toggleNavbar}
                style={{ color: "white", borderRadius: "15%" }}
                to="/LoginHeader"
              >
                Log in
              </Link>
            </PrimaryLink>

            <NavLink style={{ marginLeft: "4px" }}>
              <PrimaryLink>
                <Link
                  onClick={toggleNavbar}
                  style={{
                    color: "white",
                    borderRadius: "15%",
                  }}
                  to="/SignupHeader2"
                >
                  Sign Up
                </Link>
              </PrimaryLink>
            </NavLink>
          </div>
        ) : (
          <div>
            {" "}
            <PrimaryLink>
              <NavDropdown
                id="nav-dropdown-dark-example"
                // style={{backgroundColor:"darkblue",color:"white"}}
                title={name}
                // css={roundedHeaderButton && tw`rounded-full `}
                style={{ color: "white" }}
                menuVariant="light"
                className="uppercase"
              >
                <NavDropdown.Item href="">
                  <Link to="/UserProfile" style={{ color: "black" }}>
                    Dashboard
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item href="">
                  <button
                    className="dropdown-item"
                    onClick={handleClick}
                    style={{ color: "black" }}
                  >
                    Logout
                  </button>
                </NavDropdown.Item>
                {/* <Modal
                    title="confirmation"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}>
                    <p>Are you sure you want to Logout?</p>
                  </Modal> */}
              </NavDropdown>
            </PrimaryLink>
          </div>
        )}
      </NavLink>
    </NavLinks>,
  ];

  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    // <img src={Pro1} alt="logo" />
    <Link to="/">
      <LogoLink>Procareers</LogoLink>
    </Link>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <>
      <Header className="headerfixed" style={isMobile ? mob1 : desk1}>
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          {logoLink}
          {links}
        </DesktopNavLinks>

        <MobileNavLinksContainer
          css={collapseBreakpointCss.mobileNavLinksContainer}
        >
          {logoLink}
          <MobileNavLinks
            initial={{ x: "170%", display: "none" }}
            animate={animation}
            css={collapseBreakpointCss.mobileNavLinks}
          >
            {links}
          </MobileNavLinks>
          <NavToggle
            onClick={toggleNavbar}
            className={showNavLinks ? "open" : "closed"}
          >
            {showNavLinks ? (
              <CloseIcon tw="w-6 h-6" />
            ) : (
              <MenuIcon tw="w-6 h-6" />
            )}
          </NavToggle>
        </MobileNavLinksContainer>
      </Header>
      {/* <Modal
        className="bg-white"
        title="Get in touch with us"
        open={modalOpen}
        onOk={modalClose}
        okType="success"
        onCancel={modalClose}
        style={{ margin: "auto" }}
      >
        <ModalForm />
      </Modal> */}
    </>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
