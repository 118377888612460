import React, { Component } from "react";
import Slider from "react-slick";

import { isMobile } from "react-device-detect";

const CertificateSlider = () => {
  const paramob = {
    height: "auto",
  };
  const paradesk = {
    height: "100px",
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="row p-2">
        <Slider {...settings}>
          <div className="col-lg-2">
            <div className="card testimonial-card">
              <div className="card-up" style={{ backgroundColor: "#9d789b" }} />
              <div className="avatar mx-auto bg-white">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_mm4ZjIgRXxl9j-Bx4sKdAFKhCKrhlLYjFQ&usqp=CAU"
                  className="rounded-circle img-fluid"
                  style={{ height: "100px", width: "auto" }}
                />
              </div>
              <div className="card-body">
                <h4 className="mb-4">Rakesh Reddy</h4>
                <hr />
                <p
                  className="dark-grey-text mt-4"
                  style={isMobile ? paramob : paradesk}
                >
                  <i className="fas fa-quote-left pe-2" />
                  "Procareers is the consultancy among any consultancy that i
                  have experienced."
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="card testimonial-card">
              <div className="card-up" style={{ backgroundColor: "#7a81a8" }} />
              <div className="avatar mx-auto bg-white">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjcWqayndiwlIVO7aHGLMZQV5ARxNXA6O8-g&usqp=CAU"
                  className="rounded-circle img-fluid"
                  style={{ height: "100px", width: "auto" }}
                />
              </div>
              <div className="card-body">
                <h4 className="mb-4">Indira </h4>
                <hr />
                <p
                  className="dark-grey-text mt-4"
                  style={isMobile ? paramob : paradesk}
                >
                  <i className="fas fa-quote-left pe-2" />
                  "Procareers placement service is one of the best placement
                  service they won't charge for the candidate to get a job."
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="card testimonial-card">
              <div className="card-up" style={{ backgroundColor: "#6d5b98" }} />
              <div className="avatar mx-auto bg-white">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbTdCmBdxMRDHwxwCBRe8BlZ6Kzh4kl2Owng&usqp=CAU"
                  className="rounded-circle img-fluid"
                  style={{ height: "100px", width: "auto" }}
                />
              </div>
              <div className="card-body">
                <h4 className="mb-4">John Smith</h4>
                <hr />
                <p
                  className="dark-grey-text mt-4"
                  style={isMobile ? paramob : paradesk}
                >
                  <i className="fas fa-quote-left pe-2" />
                  "Procareers is a very good place to work and learn for both
                  fresher and experienced to grow."
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="card testimonial-card">
              <div className="card-up" style={{ backgroundColor: "#9d789b" }} />
              <div className="avatar mx-auto bg-white">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGuMCa8m5w4ZcMQ3U6WpNF3YXn7RZiLEQlXw&usqp=CAU"
                  className="rounded-circle img-fluid"
                  style={{ height: "100px", width: "auto" }}
                />
              </div>
              <div className="card-body">
                <h4 className="mb-4">Dahila</h4>
                <hr />
                <p
                  className="dark-grey-text mt-4"
                  style={isMobile ? paramob : paradesk}
                >
                  <i className="fas fa-quote-left pe-2" />
                  "Procareers is the consultancy among any consultancy that i
                  have experienced."
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="card testimonial-card">
              <div className="card-up" style={{ backgroundColor: "#7a81a8" }} />
              <div className="avatar mx-auto bg-white">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4IeeD_1X92DgJ4iDWbXXTyQu2riVtet4BWQ&usqp=CAU"
                  className="rounded-circle img-fluid"
                  style={{ height: "100px", width: "auto" }}
                />
              </div>
              <div className="card-body">
                <h4 className="mb-4">Amith Laskar yadav</h4>
                <hr />
                <p
                  className="dark-grey-text mt-4"
                  style={isMobile ? paramob : paradesk}
                >
                  <i className="fas fa-quote-left pe-2" />
                  "Procareers placement service is one of the best placement
                  service they won't charge for the candidate to get a job."
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="card testimonial-card">
              <div className="card-up" style={{ backgroundColor: "#6d5b98" }} />
              <div className="avatar mx-auto bg-white">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLd0s1j2s76m_h13q38pjKNcclNBntTiT8eA&usqp=CAU"
                  className="rounded-circle img-fluid"
                  style={{ height: "100px", width: "auto" }}
                />
              </div>
              <div className="card-body">
                <h4 className="mb-4">Viswanath Gowda</h4>
                <hr />
                <p
                  className="dark-grey-text mt-4"
                  style={isMobile ? paramob : paradesk}
                >
                  <i className="fas fa-quote-left pe-2" />
                  "Procareers is a very good place to work and learn for both
                  fresher and experienced to grow."
                </p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};

export default CertificateSlider;
