import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { profile, profileData } from "../../../../store/slices/userProfile";
import { useSelector } from "react-redux";
import { localUrl } from "pages/env";

const mob = {
  width: "100%",
  justifyContent: "center",
};
const desk = {
  margin: "auto",
};
const EditProfile = () => {
  const [user, setUser] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [PhoneNumber, setPhoneNumber] = useState();
  const [address, setAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipcode, setZipcode] = useState();
  const [image, setImage] = useState();
  const [error, setError] = useState();
  const [id, setId] = useState();
  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.userProfile);
  console.log(profiles, "kkl");
  const count = useSelector((state) => state.userSlice);

  useEffect(() => {
    dispatch(profileData());
  }, [count]);
  console.log(count, "phone");

  const handleSubmit = () => {
    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("PhoneNumber", PhoneNumber);
    formdata.append("address", address);
    formdata.append("state", state);
    formdata.append("city", city);
    formdata.append("zipcode", zipcode);
    formdata.append("image", image);
    formdata.append("user", profiles.data.id);
    formdata.append("idvalues", profiles.data.id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(localUrl + "user/updateProfileApi/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "df");
        setName(result.name);
        setAddress(result.address);
        setPhoneNumber(result.PhoneNumber);
        setCity(result.city);
        setZipcode(result.zipcode);
        setUser(result.user);
        setId(result.profiles.data.id);
        setImage(result.image);
        // setId(result.profiles.data.id);
      })
      .catch((error) => console.log("error", error));
  };
  console.log(PhoneNumber, "adp");
  console.log(user, "userid");

  useEffect(() => {
    var formdata = new FormData();
    formdata.append("idvalues", profiles.data.id);
    console.log(profiles.data.id, "yy");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(localUrl + "user/updateProfileApi/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "up");
        setName(result[0]?.fields?.name);
        setEmail(result[0]?.fields?.email);
        setAddress(result[0]?.fields?.address);
        setPhoneNumber(result[0]?.fields?.PhoneNumber);
        setCity(result[0]?.fields?.city);
        setState(result[0]?.fields?.state);
        setZipcode(result[0]?.fields?.zipcode);
        setUser(result[0]?.fields?.user);
        setImage(result[0]?.fields?.image);
        setId(result[0]?.fields?.profiles.data.id);
      })
      .catch((error) => console.log("error", error));
  }, [count]);
  console.log("!!!", name, city);
  return (
    <>
      <p
        className="text-center font-bold text-decoration-underline"
        style={{ fontSize: "22px" }}
      >
        EDIT PROFILE
      </p>
      <div className="col-lg-7" style={isMobile ? mob : desk}>
        <div className="border rounded p-2 bg-white">
          <div className="field-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={profiles?.data?.name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="form-control p-2"
              // disabled
            />
          </div>
          <div className="field-group">
            <label htmlFor="name" className="mt-2">
              {" "}
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={profiles?.data?.email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className="form-control p-2"
              // disabled
            />
          </div>
          <div className="field-group">
            <label htmlFor="name" className="mt-2">
              Phone Number:
            </label>
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              value={PhoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              className="form-control p-2"
            />
          </div>
          <div className="field-group">
            <label htmlFor="name" className="mt-2">
              Address:
            </label>
            <input
              type="text"
              id="address"
              name="address"
              defaultValue={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              className="form-control p-2"
            />
          </div>
          <div className="field-group">
            <label htmlFor="name" className="mt-2">
              State:
            </label>
            <input
              type="text"
              id="state"
              name="state"
              Value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
              className="form-control p-2"
            />
          </div>
          <div className="field-group">
            <label htmlFor="name" className="mt-2">
              City:
            </label>
            <input
              type="text"
              id="city"
              name="city"
              defaultValue={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              className="form-control p-2"
            />
          </div>
          <div className="field-group ">
            <label htmlFor="name" className="mt-2">
              zipcode:
            </label>
            <input
              type="text"
              id="zipcode"
              name="zipcode"
              defaultValue={zipcode}
              onChange={(e) => {
                setZipcode(e.target.value);
              }}
              className="form-control p-2"
            />
          </div>
          <div className="field-group">
            <input
              type="text"
              id="id"
              name="id"
              value={profiles.data.id}
              className="form-control p-2 mt-3"
            />
          </div>
          <div className="field-group mt-3">
            <input
              type="file"
              id="image"
              name="ImageStyle"
              // value={image}
              onChange={(e) => {
                setImage(e.target.value);
              }}
            />
          </div>

          <button
            onClick={handleSubmit}
            className="mt-3 p-1 border rounded w-100 h-25 text-center border-primary text-primary font-bold"
            style={{ fontSize: "20px" }}
          >
            submit
          </button>
          {error ? <p className="text-danger">{error}</p> : null}
        </div>
      </div>
    </>
  );
};

export default EditProfile;
