import React from "react";
import { BsFileSpreadsheet } from "react-icons/bs";
import { GrDocumentUser } from "react-icons/gr";
import { TbUsers } from "react-icons/tb";
import { TiMessages } from "react-icons/ti";
import { TfiDesktop } from "react-icons/tfi";
import { AiOutlineUserAdd } from "react-icons/ai";
import EducationSlider from "./EducationSlider";
import Wegrow from "../../src/images/Wegrow.png";
import { isMobile } from "react-device-detect";

const EducationTrust = () => {
  const edumob = {};
  const edudesk = {
    marginLeft: "100px",
  };
  const edumob1 = {
    marginLeft: "-15px",
  };
  const edudesk1 = {};
  return (
    <div style={{ marginTop: "100px" }}>
      <h2
        style={{
          fontWeight: "900",
          lineHeight: "1.25",
          fontSize: "3rem",
          textAlign: "center",
        }}
      >
        Education that gets you{" "}
        <span style={{ color: "blue", fontWeight: "900" }}>Industry Ready</span>
      </h2>
      <div className="row p-3 mt-2">
        <div className="col-lg-2 d-flex justify-content-evenly ">
          <div>
            <BsFileSpreadsheet style={{ fontSize: "30px" }} />
          </div>
          <div>
            <p style={{ color: "blue", fontWeight: "900", fontSize: "2rem" }}>
              15+
            </p>
            <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              Total Courses
            </p>
          </div>
        </div>
        <div className="col-lg-2 d-flex justify-content-evenly">
          <div>
            <GrDocumentUser style={{ fontSize: "30px" }} />
          </div>
          <div>
            <p
              style={{
                color: "blue",
                fontWeight: "900",
                fontSize: "2rem",
                marginLeft: "10px",
              }}
            >
              20+
            </p>
            <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              Expert Mentors
            </p>
          </div>
        </div>
        <div className="col-lg-2 d-flex justify-content-evenly">
          <div style={isMobile ? edumob1 : edudesk1}>
            <TbUsers style={{ fontSize: "40px" }} />
          </div>
          <div style={isMobile ? edumob1 : edudesk1}>
            <p style={{ color: "blue", fontWeight: "900", fontSize: "2rem" }}>
              100+
            </p>
            <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              Students
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <img src={Wegrow} style={isMobile ? edumob : edudesk} />
        </div>
        <div className="col-lg-8  p-3">
          <div className="d-flex justify-content-center">
            <div className="mx-2">
              <TiMessages style={{ fontSize: "52px" }} />
            </div>
            <div className="mx-2">
              <p style={{ fontWeight: "900", fontSize: "24px" }}>
                Interact with the best
              </p>
              <p style={{ fontWeight: "500", fontSize: "22px" }}>
                Eg: Live Courses / Video,Doubt logs etc
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <div className="d-flex mx-2">
              {" "}
              <TfiDesktop style={{ fontSize: "42px" }} />
              <AiOutlineUserAdd
                style={{ fontSize: "52px", marginLeft: "-5px" }}
              />
            </div>
            <div>
              <p style={{ fontWeight: "900", fontSize: "24px" }}>
                Learn By Doing and Practicing
              </p>
              <p style={{ fontWeight: "500", fontSize: "22px" }}>
                Actionable Programs
              </p>
              <p style={{ fontWeight: "500", fontSize: "22px" }}>
                Execute Projects using What You Learn
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <EducationSlider /> */}
    </div>
  );
};

export default EducationTrust;
