import FiveColumnWithInputForm from "components/footers/FiveColumnWithInputForm";
import Light from "components/headers/light";
import { localUrl, url } from "pages/env";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import "./CerticationStyle.css";
import Certificateimg from "../../images/Certificateimg.jpg";

const Certification = () => {
  const [pro, setPro] = useState([{}]);
  const { id } = useParams();
  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(localUrl + `jobs/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPro(result);

        console.log(result, "res");
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <>
      <AnimationRevealPage>
        <div className="jp_top_header_img_wrapper">
          <div className="gc_main_menu_wrapper">
            <div
              className="row"
              style={{
                border: "1px solid black",
                backgroundColor: "black",
                maxHeight: 400,
              }}>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h2 style={{ padding: 10, color: "white" }}>
                  Training &amp; certifications
                </h2>
                <p style={{ marginLeft: 20, color: "white" }}>
                  hye there im using whatsapp
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <img
                  className="img "
                  alt="ITIL 4 Foundation"
                  style={{ width: "100%", height: "96%" }}
                  src={Certificateimg}
                />
              </div>
            </div>
            <section>
              <div
                className="container overview sticky_place"
                id="Overview"
                data-sticky-name="Overview">
                <h2>
                  {pro?.title}
                  <b />
                </h2>
                <div className="col-md-8">
                  <p>An interest in {pro?.title}</p>
                </div>
                <div className="col-md-4 col-sm-12 intro" id="coIntro">
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <a className="" href="/en/memberplanPricing/2">
                      {/* <img data-gumlet="false"
          class="gm-added gm-lazy blend-mode"
          src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.istockphoto.com%2Fvector%2Fbuy-now-button-buy-now-rounded-blue-sign-buy-now-gm1175734204-327523245&psig=AOvVaw0NNxW9ZvBvx8Pa9zm9-Yg8&ust=1650191184725000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCLDV26msmPcCFQAAAAAdAAAAABAD"
          width="375"
          height="211"
          alt="AWS Solutions Architect" /> */}
                      {/* <span class="intro-text"> Click <b></b></span></a> */}
                      <span className="intro-text">
                        <Link
                          to={`/TrainingPricing/${id}`}
                          className="button2"
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            border: "2px solid #008CBA",
                            width: 200,
                            fontSize: 18,
                            padding: 10,
                            borderRadius: 10,
                          }}>
                          Buy
                        </Link>
                      </span>
                    </a>
                  </div>
                </div>
                <div></div>
                <div className="clear" />
                <div className="col-md-8 key-features">
                  <h3>{pro.title}</h3>
                  <div id="moneyBackGuarantee" className="moneyBackGuarantee">
                    <div className="course-tip">
                      100% Money Back Guarantee
                      <div className="q-mark">
                        <div className="course-tiptext">
                          Learn the key concepts of {pro.title}
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="icon-list">
                    <li>Learn the key concepts of {pro.title}</li>
                    <li> {pro.title} basic reporting</li>
                    <li> Editing {pro.title} report layouts</li>
                    <li>
                      {" "}
                      Use various search methods to find data, run and export
                      reports from {pro.title}
                    </li>
                    <li> Learn about SAP modules</li>
                    <li> {pro.title} roles</li>
                  </ul>
                </div>
                <div className="col-md-4 skill">
                  <h3>Skills Covered</h3>
                  <ul id="skill-covered" className="icon-list">
                    <li className="">An interest in SAP ERP</li>
                    <li className="">sap software</li>
                    <li className="">
                      SAP ERP is an enterprise resource planning platform, it
                      helps businesses run their business processes, including
                      accounting
                    </li>
                    <li className="">500</li>
                    {/* <li class="hide">AWS implementation optimization</li>
      <li class="hide">AWS data IO</li> */}
                  </ul>
                </div>
                <div className="clear" />
                <div className="salary-info">
                  <div className="col-md-12 benefits csm-benfit">
                    <div className="container">
                      <h3>Benefits</h3>
                      <p>
                        SAP ERP is an enterprise resource planning platform, it
                        helps businesses run their business processes, including
                        accounti
                      </p>
                      <div className="benefit-rows">
                        <ul>
                          <li>Designation</li>
                          <li>Annual Salary</li>
                          <li>Hiring Companies</li>
                        </ul>
                      </div>
                      <div className="graphic">
                        <div className="tab" id="BenifitTab">
                          <ul>
                            <li
                              id="BenifitTab0"
                              className="active tab-0 TabSection three">
                              <div
                                data-tabclass="tab"
                                className="active __sl-tab-button"
                                data-index={0}
                                data-toggle-class="active"
                                data-placeholder="BenifitTab"
                                data-tabplaceholder="TabSection"
                                data-isaccordion={0}
                                data-name="Cloud Solution Architect">
                                <a className="" href="#" aria-label="">
                                  Cloud Solution Architect
                                </a>
                              </div>
                              <div className="tabcontent">
                                <div className="salarys">
                                  <span className="heading">Annual Salary</span>
                                  <div className="salary-line">
                                    <div id="job_salary_0" className="active">
                                      <div className="dual-bar">
                                        <span className="price">₹6.6L</span>
                                        <span className="price percent">
                                          Min
                                        </span>
                                      </div>
                                      <div className="dual-bar">
                                        <span className="price" />
                                        <span className="price percent" />
                                      </div>
                                      <div className="dual-bar">
                                        <span className="price">₹16L</span>
                                        <span className="price percent">
                                          Average
                                        </span>
                                      </div>
                                      <div className="dual-bar">
                                        <span className="price" />
                                        <span className="price percent" />
                                      </div>
                                      <div className="dual-bar">
                                        <span className="price">₹33L</span>
                                        <span className="price percent">
                                          Max
                                        </span>
                                      </div>
                                    </div>
                                    <span className="salary-sourse">
                                      Source:
                                      {/* */}Glassdoor
                                    </span>
                                  </div>
                                </div>
                                <div className="hire">
                                  <span className="heading">
                                    Hiring Companies
                                  </span>
                                  <div className="hire-companys">
                                    <div className="hire-logos-wrapper">
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/TaTa-motors.png"
                                          alt="TaTa motors"
                                          title="TaTa motors"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/Accenture.png"
                                          alt="Accenture"
                                          title="Accenture"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/IBM.png"
                                          alt="IBM"
                                          title="IBM"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/Dell.png"
                                          alt="Dell"
                                          title="Dell"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/Fujitsu.png"
                                          alt="Fujitsu"
                                          title="Fujitsu"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/Cognizant-Technology-Solutions.png"
                                          alt="Cognizant Technology Solutions"
                                          title="Cognizant Technology Solutions"
                                        />
                                      </div>
                                    </div>
                                    <span className="hire-source">
                                      Source:
                                      {/* */}Indeed
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              id="BenifitTab1"
                              className="tab-1 TabSection three">
                              <div
                                data-tabclass="tab"
                                className="__sl-tab-button"
                                data-index={1}
                                data-toggle-class="active"
                                data-placeholder="BenifitTab"
                                data-tabplaceholder="TabSection"
                                data-isaccordion={0}
                                data-name="Cloud Administrator">
                                <a className="" href="#" aria-label="">
                                  Cloud Administrator
                                </a>
                              </div>
                              <div className="tabcontent">
                                <div className="salarys">
                                  <span className="heading">Annual Salary</span>
                                  <div className="salary-line">
                                    <div id="job_salary_1" className="active">
                                      <div className="dual-bar">
                                        <span className="price">₹4.1L</span>
                                        <span className="price percent">
                                          Min
                                        </span>
                                      </div>
                                      <div className="dual-bar">
                                        <span className="price" />
                                        <span className="price percent" />
                                      </div>
                                      <div className="dual-bar">
                                        <span className="price">₹11L</span>
                                        <span className="price percent">
                                          Average
                                        </span>
                                      </div>
                                      <div className="dual-bar">
                                        <span className="price" />
                                        <span className="price percent" />
                                      </div>
                                      <div className="dual-bar">
                                        <span className="price">₹16L</span>
                                        <span className="price percent">
                                          Max
                                        </span>
                                      </div>
                                    </div>
                                    <span className="salary-sourse">
                                      Source:
                                      {/* */}Glassdoor
                                    </span>
                                  </div>
                                </div>
                                <div className="hire">
                                  <span className="heading">
                                    Hiring Companies
                                  </span>
                                  <div className="hire-companys">
                                    <div className="hire-logos-wrapper">
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/IBM.png"
                                          alt="IBM"
                                          title="IBM"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/Accenture.png"
                                          alt="Accenture"
                                          title="Accenture"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/General-Electric.png"
                                          alt="GE"
                                          title="GE"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/Amazon.png"
                                          alt="Amazon"
                                          title="Amazon"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/Intuit.png"
                                          alt="Intuit"
                                          title="Intuit"
                                        />
                                      </div>
                                    </div>
                                    <span className="hire-source">
                                      Source:
                                      {/* */}Indeed
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li
                              id="BenifitTab2"
                              className="tab-2 TabSection three">
                              <div
                                data-tabclass="tab"
                                className="__sl-tab-button"
                                data-index={2}
                                data-toggle-class="active"
                                data-placeholder="BenifitTab"
                                data-tabplaceholder="TabSection"
                                data-isaccordion={0}
                                data-name="Cloud Developer">
                                <a className="" href="#" aria-label="">
                                  Cloud Developer
                                </a>
                              </div>
                              <div className="tabcontent">
                                <div className="salarys">
                                  <span className="heading">Annual Salary</span>
                                  <div className="salary-line">
                                    <div id="job_salary_2" className="active">
                                      <div className="dual-bar">
                                        <span className="price">₹3.4L</span>
                                        <span className="price percent">
                                          Min
                                        </span>
                                      </div>
                                      <div className="dual-bar">
                                        <span className="price" />
                                        <span className="price percent" />
                                      </div>
                                      <div className="dual-bar">
                                        <span className="price">₹8.4L</span>
                                        <span className="price percent">
                                          Average
                                        </span>
                                      </div>
                                      <div className="dual-bar">
                                        <span className="price" />
                                        <span className="price percent" />
                                      </div>
                                      <div className="dual-bar">
                                        <span className="price">₹12L</span>
                                        <span className="price percent">
                                          Max
                                        </span>
                                      </div>
                                    </div>
                                    <span className="salary-sourse">
                                      Source:
                                      {/* */}Glassdoor
                                    </span>
                                  </div>
                                </div>
                                <div className="hire">
                                  <span className="heading">
                                    Hiring Companies
                                  </span>
                                  <div className="hire-companys">
                                    <div className="hire-logos-wrapper">
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/Vodafone.png"
                                          alt="Vodafone"
                                          title="Vodafone"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/Amazon.png"
                                          alt="Amazon"
                                          title="Amazon"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/TCS.png"
                                          alt="Tata Consultancy Services"
                                          title="Tata Consultancy Services"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/Accenture.png"
                                          alt="Accenture"
                                          title="Accenture"
                                        />
                                      </div>
                                      <div>
                                        <img
                                          className="hire-company gm-lazy blend-mode"
                                          width={16}
                                          height={9}
                                          data-gumlet="false"
                                          src="https://www.simplilearn.com/ice9/logos/IBM.png"
                                          alt="IBM"
                                          title="IBM"
                                        />
                                      </div>
                                    </div>
                                    <span className="hire-source">
                                      Source:
                                      {/* */}Indeed
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="container-fluid">
              <FiveColumnWithInputForm />
            </div>
          </div>
        </div>
      </AnimationRevealPage>
    </>
  );
};

export default Certification;
