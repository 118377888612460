import React, { useState, useEffect } from 'react';

function Justry() {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://admin.procareers.in/job/jobs_details/');
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="App">
      <h1>Courses</h1>
      <div className="card-container">
        {courses.map(course => (
          <div key={course.id} className="card">
            <img src={course.image} alt={course.coursename} />
            <h2>{course.coursename}</h2>
            <p>{course.category}</p>
            <p>{course.description}</p>
            {/* Render additional fields as needed */}
            <p>Train & Certification Price: {course.trainandcertficateprice}</p>
            <p>Placement Price: {course.placementprice}</p>
            {/* Add more fields as needed */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Justry;
