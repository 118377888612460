import React from "react";
import imgProc from "../../src/images/demo/imgProc.gif";
import workDesk from "../../src/images/demo/workDesk.gif";
// import { GrEdit } from "react-icons/gr";
import { MdOutlineImageSearch } from "react-icons/md";
import { FaRegHandshake, FaBook, FaBookOpen, FaUserEdit } from "react-icons/fa";
import { IoRocketOutline } from "react-icons/io5";
import { isMobile } from "react-device-detect";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";

import { AiOutlineArrowRight } from "react-icons/ai";

const FindMillion = () => {
  const iconmob = {
    fontSize: "28px",
    fontWeight: "bold",
    marginLeft: "5px",
  };
  const deskmob = {
    fontSize: "28px",
    fontWeight: "bold",
    marginLeft: "10px",
  };
  return (
    <div className="mt-5">
      <h2
        style={{
          fontWeight: "900",
          lineHeight: "1.25",
          fontSize: "3rem",
          textAlign: "center",
        }}
      >
        Make a Difference With Your{" "}
        <span style={{ color: "blue", fontWeight: "900" }}>
          Online Presence
        </span>
      </h2>
      <div className="row p-4">
        <div className="col-lg-1"></div>
        <div className="col-lg-7">
          <p
            style={{
              fontSize: "28px",
              fontWeight: "900",
              lineHeight: "1.625",
              textAlign: "center",
            }}
          >
            Find Hundred's Of Jobs And
            <span
              style={{
                color: "blue",
                fontSize: "28px",
                fontWeight: "900",
                lineHeight: "1.625",
              }}
            >
              {" "}
              Achieve Success{" "}
            </span>
          </p>
          <p
            style={{
              fontWeight: "500",
              lineHeight: "1.625",
              textAlign: "center",
            }}
          >
            Procareers is one of the fastest growing HR Firms with focus on
            Premium Placement Service Our Endeavour is to provide simple,
            strong, fast, dynamic and responsive experience to our valued
            customers in their quest for searching the best man-power available.
            Our well-trained Staff combines speed and experience to find the
            best possible talent, in the most demanding skill areas too.
          </p>
        </div>
        <div className="col-lg-4">
          <img src={imgProc} style={{ width: "300px", height: "200px" }} />
        </div>
      </div>

      <h2
        style={{
          fontWeight: "900",
          lineHeight: "1.25",
          fontSize: "3rem",
          textAlign: "center",
        }}
      >
        Get Hired In{" "}
        <span style={{ color: "blue", fontWeight: "900" }}>4 Easy Steps</span>
      </h2>
      <div className="row p-4 ">
        <div className="col-lg-4">
          <img src={workDesk} style={{ height: "300px" }} />
        </div>
        <div className="col-lg-7">
          <h2
            style={{
              fontWeight: "900",
              lineHeight: "1.25",
              fontSize: "34px",
              textAlign: "center",
            }}
          >
            Sign Up Now And Find Your
            <span
              style={{
                color: "blue",

                fontWeight: "900",
                lineHeight: "1.625",
              }}
            >
              {" "}
              Dream Job{" "}
            </span>
          </h2>
          <div className="row border rounded p-2 ">
            <div className="col-lg-6 d-flex justify-content-between mt-3">
              <div>
                <div
                  className="p-2 w-50 border rounded "
                  style={{ backgroundColor: "#00dcff2e" }}
                >
                  <FaUserEdit style={isMobile ? iconmob : deskmob} />
                </div>
                <p
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Create Account
                </p>
                <p
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    marginTop: "-20px",
                  }}
                >
                  Register Yourself to join our services
                </p>
              </div>
              <AiOutlineArrowRight
                className="mt-4"
                style={{ width: "100px" }}
              />
              <div>
                <div
                  className="p-2 w-50 border rounded "
                  style={{ backgroundColor: "#ffb6c1", width: "1cm" }}
                >
                  {/* <FaBook style={isMobile ? iconmob : deskmob} /> */}
                  <ModelTrainingIcon style={isMobile ? iconmob : deskmob} />
                </div>
                <p
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Choose a Training
                </p>
                <p
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    marginTop: "-20px",
                  }}
                >
                  Choose Your Course To Start Certification
                </p>
              </div>
              <AiOutlineArrowRight
                className="mt-4"
                style={{ width: "100px" }}
              />
            </div>

            <div className="col-lg-6 d-flex justify-content-between mt-3">
              <div>
                <div
                  className="p-2 w-50 border rounded "
                  style={{ backgroundColor: "#d8bfdf" }}
                >
                  <MilitaryTechIcon style={isMobile ? iconmob : deskmob} />
                </div>

                <p
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Get Certification
                </p>
                <p
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    marginTop: "-20px",
                  }}
                >
                  Get Training To Find Your Dream Job{" "}
                </p>
              </div>
              <AiOutlineArrowRight
                className="mt-4"
                style={{ width: "100px" }}
              />
              <div>
                <div
                  className="p-2 w-50 border rounded "
                  style={{ backgroundColor: "#faad1447" }}
                >
                  <FaRegHandshake style={isMobile ? iconmob : deskmob} />
                </div>

                <p
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Apply Job
                </p>
                <p
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    marginTop: "-20px",
                  }}
                >
                  Apply a Job That Suits You For Work
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindMillion;
