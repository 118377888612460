import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { localUrl } from "pages/env";
import { profileData } from "store/slices/userProfile";
import { useDispatch } from "react-redux";

const MyProfile = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [id, setId] = useState();

  const checkvalue = useSelector((state) => state.userProfile);
  console.log(checkvalue, "checkvalue");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileData());
    console.log(localStorage, "local");
    if (localStorage.getItem("access_token") != null) {
      var myHeaders = new Headers();
      myHeaders.append(
        "authorization",
        "Bearer " + localStorage.getItem("access_token")
      );

      var formdata = new FormData();

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(localUrl + "user/profile/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result, "re");
          setName(result.name);
          setEmail(result.email);
          setId(result.id);
        })
        .catch((error) => console.log("error", error));
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="col-lg-12 border d-flex justify-content-between">
          <b className=" mt-3">Name</b>
          <p className=" mt-3">{name}</p>
        </div>
        <div className="col-lg-12 border d-flex justify-content-between">
          <b className=" mt-3">Email</b>
          <p className=" mt-3">{email}</p>
        </div>
        <div className="col-lg-12 border d-flex justify-content-between">
          <b className=" mt-3">Your Id</b>
          <p className=" mt-3">{id}</p>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
