import React from "react";
import "./SignupHeadrer.css";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { Link } from "react-router-dom";
import { useState } from "react";
import { message } from "antd";
import validator from "validator";

const SignupHeader = () => {
  const [error, setError] = useState(null);
  const [checkbox, setCheckbox] = useState(false);

  const [Data, SetData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    phoneNumber: "",
  });
  const ChangeHandler = (e) => {
    SetData({ ...Data, [e.target.name]: e.target.value });
  };
  const [messageApi, contextHolder] = message.useMessage();

  const SignupHandler = (e) => {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("name", Data.name);
    formdata.append("email", Data.email);
    formdata.append("password", Data.password);
    formdata.append("password2", Data.password2);
    formdata.append("phoneNumber", Data.phoneNumber);
    messageApi.open({
      type: "loading",
      content: "Registration  is progress..",
      duration: 2.5,
    });

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch("http://192.168.1.11:8000/api/user/register/", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (
          !validator.isEmpty(Data.name) &&
          Data.email !== "" &&
          Data.password !== "" &&
          Data.password2 !== "" &&
          Data.phoneNumber !== "" &&
          checkbox
        ) {
          if (!validator.isEmail(Data.email)) {
            setError("email is need to validate");
          } else if (!validator.isByteLength(Data.name, 3, 20)) {
            setError("name should not be more then 8 character");
          } else if (!validator.isStrongPassword(Data.password)) {
            setError("this is not the strong password");
          } else if (Data.password !== Data.password2) {
            setError("Password should be match");
          }
        } else {
          if (Data.name === "") {
            setError("Name can not be empty");
          } else if (Data.email === "") {
            setError("Email can not be empty");
          } else if (Data.password === "") {
            setError("Password can not be empty");
          } else if (Data.password2 === "") {
            setError("Confirm Password can not be empty");
          } else if (!checkbox) {
            setError("Please click on checkbox");
          }
          // setError("Please fillup all field");
        }
      });
  };
  function validateForm(form) {
    const checked = form.target.checked;
    if (checked) {
      setCheckbox(true);
      console.log("checkined");
    } else {
      setCheckbox(false);
      console.log("//unchecked");
    }
  }

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  return (
    <AnimationRevealPage>
      <Header />
      <div>
        <div className="signup-form">
          <form
            action="/examples/actions/confirmation.php"
            method="post"
            className="form-horizontal"
          >
            <div className="row">
              <div className="col-8 offset-4">
                <h2>Sign Up</h2>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-4">Username</label>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={Data.name}
                  required="required"
                  placeholder="Enter username"
                  onChange={ChangeHandler}
                />
              </div>
              <div className="form-group row">
                <label className="col-form-label col-4">Phone Number</label>
                <div className="col-8">
                  <input
                    type="phoneNumber"
                    className="form-control"
                    name="phone Number"
                    value={Data.phoneNumber}
                    required="required"
                    placeholder="Enter your mobno"
                    onChange={ChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-4">Email Address</label>
              <div className="col-8">
                
                <input
                  // type="email"
                  // className="form-control"
                  // name="email"
                  // value={Data.email}
                  // required="required"
                  // placeholder="Enter Email"
                  // onChange={ChangeHandler}
                
                  name="Email"
                  label="Email"
                  onChange={ChangeHandler}
                  rules={[
                    { required: true, message: "Please input your email!" },
                    {
                      pattern:
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]{1,1}$/,
                      message: "Please enter a valid email!",
                    },
                  ]}
                
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-4">Password</label>
              <div className="col-8">
                <input
                  type={passwordShown ? "text" : "password"}
                  className="form-control"
                  name="password"
                  value={Data.password}
                  required="required"
                  placeholder="Enter Password"
                  onChange={ChangeHandler}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-4">Confirm Password</label>
              <div className="col-8">
                <input
                  type={passwordShown ? "text" : "password"}
                  className="form-control"
                  name="password2"
                  value={Data.password2}
                  required="required"
                  placeholder="Enter ConfirmPassword"
                  onChange={ChangeHandler}
                  style={{ marginBottom: "10px" }}
                  
                />
              </div>

              <div className="col-lg-8 col-sm-12">
                <button
                  className="btn-primary my-1  w-50 rounded "
                  onClick={togglePassword}
                  style={{ padding: "5px" }}
                >
                  Show Password
                </button>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-8 offset-4">
                <p>
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      required="required"
                      onChange={validateForm}
                    />{" "}
                    I accept the <Link to="./#">Terms of Use</Link> &amp;{" "}
                    <Link to="./#">Privacy Policy</Link>.
                  </label>
                </p>
                {contextHolder}
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  onClick={SignupHandler}
                  style={{ padding: "5px" }}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </form>
          <div className="text-center">
            Already have an account? <Link to="/LoginHeader">Login here</Link>
          </div>
        </div>
      </div>
      <Footer />
    </AnimationRevealPage>
  );
};

export default SignupHeader;
