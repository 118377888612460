import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";
import "./PrivacyPolicy.css";
import FiveColumnWithInputForm from "components/footers/FiveColumnWithInputForm";
import Light from "components/headers/light";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const PrivacyPolicy = () => {
  return (
    <div>
      <AnimationRevealPage>
        <Container>
          <ContentWithPaddingXl>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="about_text_wrapper">
                    <div className="section_heading section_2_heading">
                      <h2>
                        Privacy<span>Policy</span>
                      </h2>
                    </div>
                    <p style={{ marginBottom: 1 }}>
                      Your privacy is very important to us. We have developed
                      this Policy in order for you to understand how we collect,
                      use, communicate and disclose and make use of personal
                      information. The following outlines our privacy policy.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="about_text_wrapper">
                    <div className="section_heading section_2_heading">
                      <h2 style={{ marginTop: 20 }}>Website Visitors</h2>
                    </div>
                    <p>
                      Like most website operators, Procareers collects
                      non-personally-identifying information of the sort that
                      web browsers and servers typically make available, such as
                      the browser type, language preference, referring site, and
                      the date and time of each visitor request. Procareers
                      purpose in collecting non-personally identifying
                      information is to better understand how Procareers
                      visitors use its website. From time to time, Procareers
                      may release non-personally-identifying information taken
                      together, e.g., by publishing a report on trends in the
                      usage of its website.
                    </p>
                    <p>
                      Procareers also collects potentially
                      personally-identifying information like Internet Protocol
                      (IP) addresses for logged in users and for users leaving
                      comments on Procareers blogs or websites. Procareers only
                      discloses logged in user and commenter IP addresses under
                      the same circumstances that it uses and discloses
                      personally identifying information as described below,
                      except that commenter IP addresses and email addresses are
                      visible and disclosed to the administrators of the blog
                      and the website where the comment was left.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="about_text_wrapper">
                    <div className="section_heading section_2_heading">
                      <h2 style={{ marginTop: 20 }}>
                        {" "}
                        Gathering of Personally-Identifying Information
                      </h2>
                    </div>
                    <p>
                      Certain visitors to Procareers websites choose to interact
                      with Procareers in ways that require Procareers to gather
                      personally identifying information. The amount and type of
                      information that Procareers gathers depend on the nature
                      of the interaction. For example, we ask visitors who sign
                      up at procareers.in to provide a username and email
                      address. Those who engage in transactions with Procareers
                      are asked to provide additional information, including as
                      necessary the personal and financial information required
                      to process those transactions. In each case, Procareers
                      collects such information only insofar as is necessary or
                      appropriate to fulfill the purpose of the visitor’s
                      interaction with Procareers. Procareers does not disclose
                      personally identifying information other than as described
                      below. And visitors can always refuse to supply personally
                      identifying information, with the caveat that it may
                      prevent them from engaging in certain website-related
                      activities.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="about_text_wrapper">
                    <div className="section_heading section_2_heading">
                      <h2 style={{ marginTop: 20 }}> Aggregated Statistics</h2>
                    </div>
                    <p>
                      Procareers may collect statistics about the behavior of
                      visitors to its websites. Procareers may display this
                      information publicly or provide it to others. However,
                      Procareers does not disclose personally identifying
                      information other than as described below.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="about_text_wrapper">
                    <div className="section_heading section_2_heading">
                      <h2 style={{ marginTop: 20 }}>
                        Protection of Certain Personally Identifying Information
                      </h2>
                    </div>
                    <p>
                      Procareers discloses potentially personally-identifying
                      and personally-identifying information only to those of
                      its employees, contractors, and affiliated organizations
                      that (i) need to know that information in order to process
                      it on Procareers behalf or to provide services available
                      at Procareers website, and (ii) that have agreed not to
                      disclose it to others. Some of those employees,
                      contractors, and affiliated organizations may be located
                      outside of your home country; by using Procareers
                      websites, you consent to the transfer of such information
                      to them. Procareers will not rent or sell potentially
                      personally identifying and personally identifying
                      information to anyone. Other than to its employees,
                      contractors, and affiliated organizations, as described
                      above, Procareers discloses potentially personally
                      identifying and personally identifying information only in
                      response to a subpoena, court order, or another
                      governmental request, or when Procareers believes in good
                      faith that disclosure is reasonably necessary to protect
                      the property or rights of Procareers, third parties or the
                      public at large. If you are a registered user of a
                      Procareers website and have supplied your email address,
                      Procareers may occasionally send you an email to tell you
                      about new features, solicit your feedback, or just keep
                      you up to date with what’s going on with Procareers and
                      our products. If you send us a request (for example via
                      email or via one of our feedback mechanisms), we reserve
                      the right to publish it in order to help us clarify or
                      respond to your request or to help us support other users.
                      Procareers takes all measures reasonably necessary to
                      protect against the unauthorized access, use, alteration,
                      or destruction of potentially personally-identifying and
                      personally-identifying information.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="about_text_wrapper">
                    <div className="section_heading section_2_heading">
                      <h2 style={{ marginTop: 20 }}>Cookies</h2>
                    </div>
                    <p>
                      A cookie is a string of information that a website stores
                      on a visitor’s computer, and that the visitor’s browser
                      provides to the website each time the visitor returns.
                      Procareers uses cookies to help Procareers identify and
                      track visitors, their usage of the Procareers website, and
                      their website access preferences. Procareers visitors who
                      do not wish to have cookies placed on their computers
                      should set their browsers to refuse cookies before using
                      Procareers websites, with the drawback that certain
                      features of Procareers websites may not function properly
                      without the aid of cookies.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="about_text_wrapper">
                    <div className="section_heading section_2_heading">
                      <h2 style={{ marginTop: 20 }}>Business Transfers</h2>
                    </div>
                    <p>Business Transfers</p>
                    <p>
                      If WP Buffs, or substantially all of its assets, were
                      acquired, or in the unlikely event that Procareers goes
                      out of business or enters bankruptcy, user information
                      would be one of the assets that are transferred or
                      acquired by a third party. You acknowledge that such
                      transfers may occur and that any acquirer of Procareers
                      may continue to use your personal information as set forth
                      in this policy.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="about_text_wrapper">
                    <div className="section_heading section_2_heading">
                      <h2 style={{ marginTop: 20 }}> Ads</h2>
                    </div>
                    <p>
                      Advertising partners, who may set cookies, may deliver ads
                      appearing on any of our websites to users. These cookies
                      allow the ad server to recognize your computer each time
                      they send you an online advertisement to compile
                      information about you or others who use your computer.
                      This information allows ad networks to, among other
                      things, deliver targeted advertisements that they believe
                      will be of most interest to you. This Privacy Policy
                      covers the use of cookies by Procareers and does not cover
                      the use of cookies by any advertisers.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="about_text_wrapper">
                    <div className="section_heading section_2_heading">
                      <h2 style={{ marginTop: 20 }}>GDPR</h2>
                    </div>
                    <p>
                      If you fill out one of our contact forms, any information
                      that you put into the contact form is collected to allow
                      us to contact you in response to your contact form entry.
                    </p>
                    <p>
                      If you have an account and you log in to this site, we
                      will set a temporary cookie to determine if your browser
                      accepts cookies. This cookie contains no personal data and
                      is discarded when you close your browser.
                    </p>
                    <p>
                      When you log in, we will also set up several cookies to
                      save your login information and your screen display
                      choices. Login cookies last for two days, and screen
                      options cookies last for a year. If you select "Remember
                      Me", your login will persist for two weeks. If you log out
                      of your account, the login cookies will be removed.
                    </p>
                    <p>
                      If you edit or publish an article, an additional cookie
                      will be saved in your browser. This cookie includes no
                      personal data and simply indicates the post ID of the
                      article you just edited. It expires after 1 day.
                    </p>
                    <p>
                      We do not share your data with any third-party services or
                      individuals directly. We only share data for the purposes
                      of our own access and we do not authorize any third-party
                      services to access your information except with our
                      express permission for the purposes of assisting us in
                      accessing your information.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="about_text_wrapper">
                    <div className="section_heading section_2_heading">
                      <h2 style={{ marginTop: 20 }}>
                        {" "}
                        Protection of Your Data
                      </h2>
                    </div>
                    <p>
                      We use two-factor authentications in systems where we
                      store any sensitive data such as passwords. This helps us
                      to protect your information from unauthorized access by
                      requiring more than just a username and password to access
                      that information.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                  <div className="about_text_wrapper">
                    <div className="section_heading section_2_heading">
                      <h2 style={{ marginTop: 20 }}> Privacy Policy Changes</h2>
                    </div>
                    <p>
                      Although most changes are likely to be minor, Procareers
                      may change its Privacy Policy from time to time, and at
                      Procareers sole discretion. Procareers encourages visitors
                      to frequently check this page for any changes to its
                      Privacy Policy. If you are subscribed to our newsletter,
                      you might also receive an alert informing you of these
                      changes. Your continued use of this site after any change
                      in this Privacy Policy will constitute your acceptance of
                      such change.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ContentWithPaddingXl>
        </Container>
        <FiveColumnWithInputForm />
      </AnimationRevealPage>
    </div>
  );
};

export default PrivacyPolicy;
