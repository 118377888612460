import React, { Component } from "react";
import Slider from "react-slick";
import { SlGraduation } from "react-icons/sl";
import { BiUserPin } from "react-icons/bi";

const EducationSlider = () => {
  const array = [
    {
      id: "01",
      icon: <SlGraduation />,
      text: "Career Advice",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    },
    {
      id: "02",
      icon: <BiUserPin />,
      text: "Enrolment",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    },
    {
      id: "01",
      icon: <SlGraduation />,
      text: "Career Advice",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    },
    {
      id: "02",
      icon: <BiUserPin />,
      text: "Enrolment",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    },
    {
      id: "01",
      icon: <SlGraduation />,
      text: "Career Advice",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    },
    // {
    //   id: "02",
    //   icon: <BiUserPin />,
    //   text: "Enrolment",
    //   para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    // },
  ];

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="row ">
        {" "}
        <Slider {...settings}>
          {array.map((val) => {
            return (
              <div className="col-lg-2 border rounded  m-3">
                <div className="d-flex justify-content-between">
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <span
                      className="position-absolute top-100  translate-middle badge rounded-circle "
                      style={{
                        fontWeight: "900",
                        fontSize: "28px",
                        marginLeft: "35px",
                        color: "#0000ffa8",
                        backgroundColor: "#0000ff14",
                      }}
                    >
                      {val.icon}
                    </span>
                  </div>

                  <div
                    style={{ fontWeight: "900", fontSize: "28px" }}
                    className="mt-2"
                  >
                    {val.id}
                  </div>
                </div>
                <p
                  className="mt-5"
                  style={{ fontWeight: "900", fontSize: "16px" }}
                >
                  {val.text}
                </p>
                <p>{val.para}</p>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default EducationSlider;
