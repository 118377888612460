import React, { useState, useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { EmailJSResponseStatus } from "@emailjs/browser";
import emailjs from "@emailjs/browser";
import { Margin } from "@mui/icons-material";
import GoogleMapReact from "google-map-react";
import ContactUs from "pages/ContactUs";
import { localUrl, localUrlImage } from "pages/env";
import validator from "validator";
import { isMobile } from "react-device-detect";
import { crmApi } from "store/slices/CrmSlice";
import { useDispatch } from "react-redux";
import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Modal,
  Avatar,
} from "antd";

const AnyReactComponent = ({ text }) => <div>{text}</div>;
// isMobile = window.innerWidth <= 767;
// const flexDirection = window.innerWidth <= 767 ? "column" : "row";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-2 md:py-2`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-3/4   `,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left `;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

// const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0 m-0`;
// const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

// const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8 `;

const Address = tw.span`leading-relaxed `;
const AddressLine = tw.span`block text-blue-500`;
const Email = tw.span`text-sm mt-6 block text-black font-bold`;
const Phone = tw.span`text-sm mt-0 block text-black font-bold`;

const mobform = { margin: "20px" };
const deskform = {};

const mobileStyle = {
  width: "100%",
  // padding: "5px",
  justifyContent: "center",
};

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-500 ">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "Got questions? Want to connect with us? We are here to help. Choose the method that works best for you. We look forward to hearing from you.",
  // submitButtonText = "Send",
  // formAction = "#",
  // formMethod = "get",
  textOnLeft = true,
}) => {
  const mob1 = {
    marginLeft: "12px",
    marginRight: "12px!important",
    marginTop: "12px",
    border: "rounded",
  };
  const desk1 = {};

  const mob2 = {
    marginTop: "0!important",
  };
  const desk2 = {
    fontSize: "20px",
    marginTop: "125px",
  };

  const desktopStyle = {};
  const mobileview = { width: "auto", margin: "auto", paddingLeft: "30px" };

  // const marginTop = isMobile ? 180 : 200;
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const dispatch = useDispatch();
  const [Data, SetData] = useState({
    Name: "",
    Email: "",
    PhoneNumber: "",
    Message: "",
    created_at: "",
  });
  const [error, setError] = useState(null);

  const [emptyName, setemptyName] = useState(null);
  const [emptyEmail, setemptyEmail] = useState(null);
  const [emptyPhone, setemptyPhone] = useState(null);
  const [emptyMessage, setemptyMessage] = useState(null);
  const  ChangeHandler = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    if (name === "Name" && value === "") {
      setemptyName(value);
    } else if (name === "Email" && value === "") {
      setemptyEmail(value);
    } else if (name === "Data.PhoneNumber" && value === "") {
      setemptyPhone(value);
    } else if (name === "Message" && value === "") {
      setemptyMessage(value);
    } else {
      setemptyName(value);
      setemptyEmail(value);
      setemptyPhone(value);
      setemptyMessage(value);
    }

    // setform({ ...signupform, [name]: value });
    SetData({ ...Data, [e.target.name]: e.target.value });
  };

  const success = () => {
    Modal.success({
      content: "Your message is Recorded We will get back to you soon..!",
      okType: "success",
    });
  };
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log(values, "values");

    var formdata = new FormData();
    formdata.append("Name", values.Name);
    formdata.append("Email", values.Email);
    formdata.append("PhoneNumber", values.PhoneNumber);
    formdata.append("Message", values.Message);
    formdata.append("created_at", values.created_at);
    // if (
    //   Data.Name == "" &&
    //   Data.PhoneNumber == "" &&
    //   Data.Message == "" &&
    //   Data.Email == ""
    // ) {
    //   setError("please fill all the fields");
    // } else if (Data.Name.trim() == "") {
    //   setError("Username cannot be empty");
    // } else if (Data.Email == "") {
    //   setError("email can not be empty");
    // } else if (!validator.isEmail(Data.Email)) {
    //   setError("email is need to validate");
    // } else if (Data.Name.length <= 3) {
    //   setError("Username must be at least 5 character");
    // } else if (Data.PhoneNumber == "") {
    //   setError("phoneNumber cannot be empty ");
    // } else if (!validator.isMobilePhone(Data.PhoneNumber)) {
    //   setError("phoneNumber must be 10 digits ");
    // } else if (Data.Message == "") {
    //   setError("message cannot be empty");
    // } else {
    // setError("Registertation Failed");
    setError(false);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let crmObj = {
      user: { name: Data?.Name, mail: Data?.Email },
      phone: Data?.PhoneNumber,
      message: Data?.Message,
    };
    dispatch(crmApi(crmObj));
    fetch(localUrlImage + "/contact/contact_us/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // if ( !validator.isEmpty(Data.Name) &&
        //   Data.Email !== "" &&
        //   Data.PhoneNumber !== "" &&
        //   Data.Message !== "" )
        // {
        //   if (!validator.isEmail(Data.Email)) {
        //     setError("Email is need to validate");
        //   } else if (!validator.isByteLength(Data.Name, 3, 20)) {
        //     setError("name should not be more then 8 character");
        //   } else if (!validator.isByteLength(Data.PhoneNumber, 3,20)) {
        //     setError("this is not the strong password");
        //   }
        //   }

        //   else {
        //     if (Data.Name === "") {
        //       setError("Name can not be empty");
        //     } else if (Data.Email === "") {
        //       setError("Email can not be empty");
        //     } else if (Data.PhoneNumber === "") {
        //       setError("PhoneNumber can not be empty");
        //     } else if (Data.Message === "") {
        //       setError("Message can not be empty");
        //     }
        // else if (!checkbox) {
        //   setError("Please click on checkbox");
        // }
        // setError("Please fillup all field");
        // }

        console.log((result = "sucess"));
        if (result === "Success") {
        }
        success();
      })
      .catch((error) => console.log("error", error));
    form.resetFields();
    // }
  };

  return (
    <div>
      <Container
        style={{
          backgroundColor: "",
        }}
      >
        <TwoColumn>
          <ImageColumn
            className=" flex flex-col"
            // style={{
            //   marginTop: `${marginTop}px`,
            // }}
          >
            <Image imageSrc={EmailIllustrationSrc} />
          </ImageColumn>

          <TextColumn textOnLeft={textOnLeft} style={{ marginTop: "0px" }}>
            <TextContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
              {description && <Description>{description}</Description>}
              <Form
                onFinish={onFinish}
                form={form}
                style={isMobile ? mobform : deskform}
              >
                <Form.Item
                  label="Full Name"
                  name="Name"
                  onChange={ChangeHandler}
                  rules={[
                    {
                      required: true,
                      type: "string",
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z\s]*$/),
                      message: "field does not accept numbers",
                    },
                  ]}
                >
                  <Input style={{ width: "290px" }} />
                </Form.Item>

                <Form.Item
                  name="Email"
                  label="Email"
                  onChange={ChangeHandler}
                  rules={[
                    { required: true, message: "Please input your email!" },
                    {
                      pattern:
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]{1,1}$/,
                      message: "Please enter a valid email!",
                    },
                  ]}
                >
                  <Input style={{ width: "318px" }} />
                </Form.Item>

                <Form.Item
                  name="PhoneNumber"
                  label="Phone Number"
                  onChange={ChangeHandler}
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                    {
                      pattern: new RegExp("^[0-9]*$"),
                      message: "Please Enter Numbers only!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Phone Number"
                    maxLength={10}
                    minLength={10}
                    addonBefore="+91"
                    // addonBefore={prefixSelector}
                    style={{
                      width: "260px",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="Message"
                  label="Message"
                  rules={[
                    {
                      required: "true",
                      value: "text",
                    },
                  ]}
                >
                  <Input.TextArea style={{ width: "300px" }} />
                </Form.Item>

                <Form.Item className="form-group text-center align-items-center">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="buttons mt-3 "
                    style={{
                      marginLeft: "-550px",
                      color: "black",
                      borderRadius: "5px",
                      
                    }}
                  >
                    Send
                  </Button>
                </Form.Item>
              </Form>
            </TextContent>
          </TextColumn>
        </TwoColumn>
        <TwoColumn>
          <div>
            <center>
              <h3 style={{ fontSize: "25px", paddingLeft: "30px" }}>
                Location &amp; Maps
              </h3>
              <div>
                <div style={isMobile ? mob1 : desk1}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d31108.69455671429!2d77.60439930000001!3d12.934256500000009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1676630202149!5m2!1sen!2sin"
                    title="map"
                    width="600"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              {/* <iframe
            title="map"
            src="<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.510553389861!2d77.62435561418968!3d12.939148619091938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15aa83004911%3A0xf04e6ab0127d1810!2sHOLA9%20CLASSIFIEDS%20INDIA%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sus!4v1675427711092!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>"
            width={500}
            height={450}
            // className="col-xs-20 col-sm-20 col-md-20 col-lg-12 col-xl-20"
            style={{
              border: 0,
              paddingTop: "6px",
              paddingLeft: "2px",
              paddingRight: "0.5px",
            }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          /> */}
            </center>
          </div>
          <TextColumn textOnLeft={textOnLeft}>
            <div style={isMobile ? mob2 : desk2}>
              <center>
                <span
                  style={{
                    fontFamily: "times new roman",
                    fontStyle: "normal",
                    color: "blue",
                    fontWeight: "bold",
                  }}
                >
                  Bangalore
                </span>

                <Address>
                  <AddressLine
                    style={{ color: "blue", fontFamily: "times new roman" }}
                  >
                    #12 Second Floor 3rd Cross <br />
                    Patel Narayana Reddy Layout
                    <br />
                    6th Block Koramangala
                    <br />
                    Bengaluru - 560095
                  </AddressLine>
                </Address>
                <Email
                  style={{
                    color: "blue",
                    fontFamily: "Arial",
                    fontSize: "20px",
                    fontWeight: "normal",
                  }}
                >
                  hrd@procareers.in
                </Email>
                
              </center>
            </div>
          </TextColumn>
        </TwoColumn>
      </Container>
    </div>
  );
};
