import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import { localUrl } from "pages/env";
import Option from "rc-mentions/lib/Option";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { crmApi } from "store/slices/CrmSlice";
import moment from "moment";

const ModalForm = () => {
  const dispatch = useDispatch();
  //form popup
  const mob = {
    width: "auto",
  };
  const desk = {
    width: "auto",
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const [date1, setDate] = useState();
  const [filePath, setFilePath] = useState(null);
  const [fileObj, setFileObj] = useState(null);
  const onChangeDate = (date, dateString) => {
    console.log(date, dateString);
    setDate(dateString);
  };
  const success = () => {
    Modal.success({
      content: "successfully registered..",
    });
  };
  const failure = () => {
    Modal.error({
      content: "registration failed",
    });
  };
  const [form] = Form.useForm();
  const onFinish = (values) => {
    var formdata = new FormData();
    formdata.append("name", values.user.name);
    formdata.append("email", values.email);
    formdata.append("phone", values.phone);
    // formdata.append("Date_of_Birth", date1);
    formdata.append("Gender", values.gender);
    formdata.append("Introduction", values.user.Introduction);
    // formdata.append("filename", fileObj);

    dispatch(crmApi(values));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(localUrl + "jobs/jobDetails", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result, "Success ");
        // if (result === "Success") {
        //   setFileObj("");
        // }
        success();
      })
      .catch((error) => {
        console.log("error", error);
        failure();
      });
    form.resetFields();
    // setFileObj(null);
  };
  const layout = {
    labelCol: {
      span: 12,
    },
    // wrapperCol: {
    //   span: 20,
    // },
  };
  function disabledDate(current) {
    // Disable dates after today's date
    return current && current > moment().endOf("day");
  }

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <b>+91</b>
      {/* <Select style={{ width: 70 }}>
          <Option value="91">+91</Option>
          {/* <Option value="87">+87</Option> */}
      {/* </Select> */}
    </Form.Item>
  );
  return (
    <div className="row bg-white" style={isMobile ? mob : desk}>
      <div className="col-xl-8" style={{ margin: "10px" }}>
        <div className=" ">
          <Form
            form={form}
            {...layout}
            // name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
            // className="screen"
          >
            <b className="mx-1">Name</b>
            <Form.Item
              style={{ width: "100%" }}
              name={["user", "name"]}
              rules={[
                {
                  required: true,
                  type: "string",
                },
                {
                  pattern: new RegExp(/^[A-Za-z\s]*$/),
                  message: "field does not accept numbers",
                },
              ]}
              className="mb-3"
            >
              <Input className="form-control " placeholder="Full Name" />
            </Form.Item>
            <b className="mx-1">Email</b>
            {/* <Form.Item
              name={["user", "email"]}
              rules={[
                {
                  type: "email",
                  required: "true",
                },
              ]}
              className="mb-3"
            >
              <Input className="form-control" placeholder="Email" />
            </Form.Item> */}
            <Form.Item
              name="email"
              // label="Email"

              rules={[
                { required: true, message: "Please input your email!" },
                {
                  pattern:
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]{1,1}$/,
                  message: "Please enter a valid email!",
                },
              ]}
            >
              <Input className="form-control " placeholder="Email" />
            </Form.Item>
            <b className="mx-1">Phone Number</b>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your 10 digit phone number!",
                },
                {
                  pattern: new RegExp("^[0-9]*$"),
                  message: "File Does not accept string type only numbers!",
                },
              ]}
            >
              <Input
                placeholder="99XXXXXX21"
                minLength={10}
                maxLength={10}
                addonBefore={prefixSelector}
              />
            </Form.Item>
            {/* <b className="mx-1 ">DOB </b>
            <Form.Item name="DOB" className="mb-3">
              <DatePicker onChange={onChangeDate}
              disabledDate={disabledDate} className="form-control" />
            </Form.Item> */}
            <b className="mx-1">Gender</b>
            <Form.Item
              name="gender"
              // label="Gender"
              rules={[{ required: true }]}
              className=" mb-3"
            >
              <Select
                className="h-30"
                placeholder="Select an option "
                allowClear
              >
                {/* <option>Select a option and change input text above</option> */}
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.gender !== currentValues.gender
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("gender") === "other" ? (
                  <Form.Item
                    name="customizeGender"
                    label="Customize Gender"
                    rules={[{ required: true }]}
                  >
                    <Input
                      style={{
                        height: "100%",
                        padding: "0.375rem 0.75rem",
                        lineHeight: "2px",
                        borderRadius: "0.25rem",
                        border: "1px solid #ced4da",
                      }}
                    />
                  </Form.Item>
                ) : null
              }
            </Form.Item>

            <b className="mx-1">Introduction</b>
            <Form.Item
              name={["user", "Introduction"]}
              // label="Introduction"
              className="mb-3"
            >
              <Input.TextArea className="form-control" />
            </Form.Item>

            {/* <input
              required
              className="form-control mb-3"
              type="file"
              key={fileObj || ""}
              accept=".pdf"
              onChange={(e) => {
                setFileObj(e.target?.files[0]);
              }}
            /> */}
            <Form.Item className="form-group text-center align-items-center">
              <Button
                type="primary"
                htmlType="submit"
                className="buttons mt-3 "
                style={{
                  // marginLeft: "185px",
                  backgroundColor: "darkblue",
                  borderRadius: "5px",
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ModalForm;
