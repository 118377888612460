import React from "react";
import timebatch from "./timebatch.css";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import AnimationRevealPag from "helpers/AnimationRevealPage";
import { isMobile } from "react-device-detect";
import "./Batch.css";
const Batch = () => {
  const mob1 = {
    height: "auto",
    margin: "auto",
  };
  const desk1 = {
    Width: "auto",
    padding: "0px",
  };
  return (
    <div>
      <AnimationRevealPag>
        <div className="container" style={isMobile ? mob1 : desk1}>
          <div className="max-w-fit mx-auto my-auto grid lg:grid-cols-3 md:grid-cols-2 gap-3 px-[10px] mt-0">
            <section className="mx-auto my-1" style={{ maxWidth: "23rem" }}>
              <div className="card booking-card v-1 mt-1 mb-1 rounded-bottom">
                <div
                  className="bg-image hover-overlay ripple ripple-surface ripple-surface-light"
                  data-mdb-ripple-color="light"
                >
                  <img
                    src="https://freelancermap.s3.eu-west-1.amazonaws.com/channel_incl2/what-does-a-front-end-developer-do----career-insights-4940.jpg"
                    className="img-fluid"
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                    />
                  </a>
                </div>
                <div className="card-body">
                  <h4 className="card-title font-weight-bold">
                    <h1 style={{color:"black"}}>
                      <b>✯ FrontEnd Developer:</b>
                    </h1>
                  </h4>
                  {/* <ul className="list-unstyled list-inline mb-2">
                  <li className="list-inline-item me-0">
                    <i className="fa fa-star fa-xs"> </i>
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="fa fa-star fa-xs" />
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="fa fa-star fa-xs" />
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="fa fa-star fa-xs" />
                  </li>
                  <li className="list-inline-item">
                    <i className="fa fa-star-half-alt fa-xs" />
                  </li>
                </ul> */}
                  <p className="card-text">
                    Front-end web development, also known as client-side
                    development is the practice of producing HTML, CSS and
                    JavaScript for a website or Web Application so that a user
                    can see and interact with them directly. The challenge
                    associated with front end development is that the tools and
                    techniques used to create the front end of a website change
                    constantly and so the developer needs to constantly be aware
                    of how the field is developing
                  </p>
                  <hr className="my-4" />
                  <p className="h5 font-weight-bold mb-4 " style={{color:"red"}}>❉ Opening hours:</p>
                  <ul className="list-unstyled d-flex justify-content-start align-items-center mb-0">
                    <li style={{color:"blue"}}>✯ Monday - Friday :</li>
                    <li>
                      <div className="chip ms-3" style={{color:"black"}} >10:00 AM   TO  6:00 PM.</div>
                    </li>
                    {/* <li>
                      <div className="chip ms-0 me-0"  style={{color:"black"}} > 6:00 PM.</div>
                    </li> */}
                  </ul>
                  {/* <ul className="list-unstyled d-flex justify-content-start align-items-center mb-0">
                  <li>Saturday - Sunday</li>
                  <li>
                    <div className="chip ms-3">9:00AM</div>
                  </li>
                  <li>
                    <div className="chip ms-0 me-0">7:00PM</div>
                  </li>
                </ul> */}
                  <ul className="list-unstyled d-flex justify-content-start align-items-center mb-0">
                    <li style={{color:"blue"}}>✯ Saturday - Sunday:</li>
                    <li>
                      <div className="chip ms-3"  style={{color:"black"}} >CLOSED.</div>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section className="mx-auto my-1" style={{ maxWidth: "23rem" }}>
              <div className="card booking-card v-1 mt-1 mb-1 rounded-bottom">
                <div
                  className="bg-image hover-overlay ripple ripple-surface ripple-surface-light"
                  data-mdb-ripple-color="light"
                >
                  <img
                    src="https://ddi-dev.com/uploads/backend-is.png"
                    className="img-fluid"
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                    />
                  </a>
                </div>
                <div className="card-body">
                  <h4 className="card-title font-weight-bold">
                    <h1 style={{color:"black"}}>
                      <b>✯ BackEnd Developer:</b>
                    </h1>
                  </h4>
                  {/* <ul className="list-unstyled list-inline mb-2">
                  <li className="list-inline-item me-0">
                    <i className="fa fa-star fa-xs"> </i>
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="fa fa-star fa-xs" />
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="fa fa-star fa-xs" />
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="fa fa-star fa-xs" />
                  </li>
                  <li className="list-inline-item">
                    <i className="fa fa-star-half-alt fa-xs" />
                  </li>
                </ul> */}
                  <p className="card-text">
                    Back-end developers are the experts who build and maintain
                    the mechanisms that process data and perform actions on
                    websites. Unlike front-end developers, who control
                    everything you can see on a website, back-end developers are
                    involved in data storage, security, and other server-side
                    functions that you cannot see.
                  </p>
                  <hr className="my-4" />
                  <p className="h5 font-weight-bold mb-4"style={{color:"red"}}>❉ Opening hours:</p>
                  <ul className="list-unstyled d-flex justify-content-start align-items-center mb-0">
                    <li style={{color:"blue"}}>✯ Monday - Friday:</li>
                    <li>
                      <div className="chip ms-3"  style={{color:"black"}} >10:00 AM TO 6:00 PM .</div>
                    </li>
                    {/* <li>
                      <div className="chip ms-0 me-0" style={{color:"black"}} > 6:00 PM .</div>
                    </li> */}
                  </ul>
                  {/* <ul className="list-unstyled d-flex justify-content-start align-items-center mb-0">
                  <li>Saturday - Sunday</li>
                  <li>
                    <div className="chip ms-3">9:00AM</div>
                  </li>
                  <li>
                    <div className="chip ms-0 me-0">7:00PM</div>
                  </li>
                </ul> */}
                  <ul className="list-unstyled d-flex justify-content-start align-items-center mb-0">
                    <li style={{color:"blue"}}>✯ Saturday - Sunday:</li>
                    <li>
                      <div className="chip ms-3" style={{color:"black"}} >CLOSED.</div>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section className="mx-auto my-1" style={{ maxWidth: "23rem" }}>
              <div className="card booking-card v-1 mt-1 mb-1 rounded-bottom">
                <div
                  className="bg-image hover-overlay ripple ripple-surface ripple-surface-light"
                  data-mdb-ripple-color="light"
                >
                  <img
                    src="https://blog.eduonix.com/wp-content/uploads/2018/09/Full-Stack-Developer.jpg"
                    className="img-fluid"
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
                    />
                  </a>
                </div>
                <div className="card-body">
                  <h4 className="card-title font-weight-bold">
                    <h1 style={{color:"black"}}>
                      <b >✯ FullStack Developer:</b>
                    </h1>
                  </h4>
                  {/* <ul className="list-unstyled list-inline mb-2">
                  <li className="list-inline-item me-0">
                    <i className="fa fa-star fa-xs"> </i>
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="fa fa-star fa-xs" />
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="fa fa-star fa-xs" />
                  </li>
                  <li className="list-inline-item me-0">
                    <i className="fa fa-star fa-xs" />
                  </li>
                  <li className="list-inline-item">
                    <i className="fa fa-star-half-alt fa-xs" />
                  </li>
                </ul> */}
                  <p className="card-text">
                    A full stack web developer is a person who can develop both
                    client and server software. <br />
                    In addition to mastering HTML and CSS, he/she also knows how
                    to: <br />
                    <br />
                    <ul>
                      <li>
                      <b> ❂  </b> Program a <b>browser</b> (like using JavaScript, jQuery,
                        Angular, or Vue)
                      </li>
                      <li>
                      <b> ❂  </b> Program a <b>server</b> (like using PHP, ASP, Python, or Node)
                      </li>
                      <li>
                        {" "}
                        <b> ❂  </b> Program a <b>database</b> (like using SQL, SQLite, or MongoDB)
                      </li>
                    </ul>
                  </p>
                  <hr className="my-4" />
                  <p className="h5 font-weight-bold mb-4"style={{color:"red"}}>❉ Opening hours:</p>
                  <ul className="list-unstyled d-flex justify-content-start align-items-center mb-0">
                 
                    <li style={{color:"blue"}}>✯ Monday - Friday :</li>

                    <li>
                      <div className="chip ms-3 "  style={{color:"black"}} > 10:00 AM TO  6:00 PM. </div>
                    </li><br/>
                    {/* <li>
                      <div className="chip ms-0 me-0"  style={{color:"black"}} >  6:00 PM. </div>
                    </li> */}
                 
                  </ul>
                  <ul className="list-unstyled d-flex justify-content-start align-items-center mb-0">
                    <li style={{color:"blue"}}>✯ Saturday - Sunday :</li>
                    <li>
                    <div className="chip ms-3" style={{color:"black"}} >CLOSED.</div>
                    </li>
                    {/* <li>
                      <div className="chip ms-0 me-0">7:00PM</div>
                    </li> */}
                  </ul>
                  {/* <ul className="list-unstyled d-flex justify-content-start align-items-center mb-0">
                    <li>Monday</li>
                    <li>
                      <div className="chip ms-3">CLOSED</div>
                    </li>
                  </ul> */}
                </div>
              </div>
            </section>
          </div>
        </div>
        <br></br>
        <Footer />
      </AnimationRevealPag>
    </div>
  );
};

export default Batch;
