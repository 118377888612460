import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { isMobile } from "react-device-detect";
import Header from "../headers/light.js";
import emailjs from "@emailjs/browser";
import { localUrl } from "pages/env.js";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
import PorcareersFont from "../../images/PorcareersFont.png";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row justify-center lg:items-center max-w-screen-xl mx-auto py-5 md:py-5`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-700 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const homemob = { margin: "10px" };
const homedesk = {};
const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default ({ roundedHeaderButton }) => {
  const form = useRef();
  const [inputVal, setInputVal] = useState("");
  const [course, setCourse] = useState([]);

  const [location, setLocation] = useState("Location");

  const getTrainingCourse = async () => {
    try {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      const res = await fetch(
        localUrl + "taining/traininglist",
        requestOptions
      );
      const data = await res?.json();
      setCourse(data.students);
    } catch (error) {
      console.log("api is not working");
    }
  };
  useEffect(() => {
    getTrainingCourse();
  }, []);
  const onChangeHandler = (e) => {
    setInputVal(e.target.value);
  };
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const toggleNavbar = useAnimatedNavToggler();
  const submit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_zsiqs78",
        "template_dgo1q7f",
        form.current,
        "WRwF45m5-MVCb0AMJ"
      )
      .then((res) => console.log(res))
      .then((json) => console.log("submit success"))
      .catch((err) => alert("error"));
    alert(inputVal + " Thanks for subscribing.");
    setInputVal("");
  };
  return (
    <>
      <Container>
        {/* <center> */}
        {/* <div style={{ marginBottom: "2px" }}>
            <div className="card text-center">
              <div className="card-header">
                <Heading style={{ fontSize: "35px" }}>
                  Welcome to{" "}
                  <img src={PorcareersFont} style={{ margin: "auto" }} />
                </Heading>
                <br />

                <p style={{ fontSize: "35px" }}>
                  Company <span tw="text-primary-500">Profile.</span>
                </p>
                <Paragraph>
                  Procareers is one of the fastest growing HR Firms with focus
                  on Premium Placement Service Our Endeavour is to provide
                  simple, strong, fast, dynamic and responsive experience to our
                  valued customers in their quest for searching the best
                  man-power available. Our well trained Staff combines speed and
                  experience to find the best possible talent, in the most
                  demanding skill areas too
                </Paragraph>
              </div>
            </div>
          </div> */}
        {/* </center> */}

        <TwoColumn style={isMobile ? homemob : homedesk}>
          <LeftColumn>
            <Heading>
              The Easiest Way to Get Your{" "}
              <span tw="text-primary-500">New Job.</span>
            </Heading>
            {/* <Paragraph style={{ margin: "10px" }}>
              Each month, more than 3 million job seekers turn to website in
              their search for work, making over 140,000 applications every
              single day.
            </Paragraph> */}
            {/* <Actions> */}

            <div class="py-1 flex ">
              <div class="group lg:inline-block relative md:inline-block sm:inline-block">
                <button
                  class="font-semibold text-[#2b5876] py-2 px-2 inline-flex items-center border border-slate-500 w-[220px]
                "
                >
                  <WorkOutlineIcon className=" mr-4 text-[#6415FF]" />
                  <svg
                    class="fill-current h-4 w-4 text-[#6415FF]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                  <span class="mx-2">Looking For Job</span>
                </button>
                <ul className="absolute lg:h-44 md:h-10 sm:h-10 overflow-y-auto hidden  bg-white pt-1 group-hover:block z-10">
                  <div className="">
                    {course.map((c) => (
                      <li className="">
                        <Link
                          onClick={toggleNavbar}
                          className="bg-white hover:bg-blue-400 px-4 h-[20px]"
                          to={`/training-certificate/${c?.id}`}
                        >
                          {c.title}
                        </Link>
                      </li>
                    ))}
                  </div>
                </ul>
              </div>

              <div class="group inline-block relative ">
                <button class="font-semibold text-[#2b5876] py-2 px-2 inline-flex items-center border border-slate-500 w-auto">
                  <LocationOnIcon className=" mr-4 text-[#6415FF]" />
                  <svg
                    class="fill-current h-4 w-4 text-[#6415FF]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                  <span class="mx-2">{location}</span>
                </button>
                <ul className="absolute h-30 overflow-y-[40px] hidden  bg-white bg-opacity-20 pt-1 group-hover:block">
                  <div className="">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d31108.69455671429!2d77.60439930000001!3d12.934256500000009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1676630202149!5m2!1sen!2sin"
                      title="map"
                      width="350"
                      height="200"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </ul>
              </div>
            </div>

            {/* <CustomersLogoStrip style={{ margin: "10px" }}>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip> */}
          </LeftColumn>

          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl h-[300px] w-[600px] mt-[-30px]"
                src="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/6f2b74113995595.603361b234cc4.gif "
                alt="Design Illustration"
                className="md:mt-32"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
