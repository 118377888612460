import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import { Link } from "react-router-dom";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-5 md:py-5 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`bg-primary-500 text-gray-100 shadow-lg focus:border-primary-500 hocus:text-gray-200 hover:bg-primary-900`,
  props.buttonRounded && tw`rounded-full`,
]);

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      <span tw="text-primary-500 p-0"> Placement</span>
    </>
  ),
  description = "Placement refers to the process of connecting the selected person and the employer in order to establish an ongoing employment relationship. In this step the employee is given the activities he/she needs to perform and is told about his/her duties. Placement is usually followed by the orientation process.",
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true,
  buttonRounded = true,
  primaryButtonText = "Learn More",
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <div style={{ padding: "10px" }}>
      <Container>
        <TwoColumn style={{ padding: "5px" }}>
          <ImageColumn>
            <Image
              css={imageCss}
              src={imageSrc}
              imageBorder={imageBorder}
              imageShadow={imageShadow}
              imageRounded={imageRounded}
            />
            {imageDecoratorBlob && (
              <DecoratorBlob css={imageDecoratorBlobCss} />
            )}
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              <Subheading>{subheading}</Subheading>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              {/* <Link to="">
              {" "}
              <PrimaryButton
                style={{ color: "white", backgroundColor: "" }}
                buttonRounded={buttonRounded}
                as="a">
                {primaryButtonText}
              </PrimaryButton>
            </Link> */}
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    </div>
  );
};
