import React from "react";
import { isMobile } from "react-device-detect";
import CardSlider from "./CardSlider";
import { Link } from "react-router-dom";
import TrustSlider from "./TrustSlider";

const TrustedBy = () => {
  const paddingMob = {
    padding: "20px",
  };
  const paddingDesk = {};
  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  return (
    <div style={isMobile ? paddingMob : paddingDesk}>
      <h2
        style={{
          fontWeight: "900",
          lineHeight: "1.25",
          fontSize: "3rem",
          textAlign: "center",
        }}
      >
        We are Trusted By Popular{" "}
        <span style={{ color: "blue", fontWeight: "900" }}>100+ Company</span>
      </h2>
      <p
        style={{
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "1.625",
          color: " rgb(124 139 161",
          marginTop: "10px",
          padding: "10px",
        }}
      >
        We help employers find not only the best quality candidates, but more of
        them. To streamline the process so you can save time, money and to help
        you make smarter decisions to improve your ROI. Basically, we want to
        give you the ability to hire like no one else can.
      </p>
      <div className="row">
        <h2
          style={{
            fontWeight: "bold",
            fontSize: "24px",
            textAlign: "center",
          }}
        >
          clients saying{" "}
          <span
            style={{
              fontWeight: "bold",
              color: "blue",
            }}
          >
            About Us
          </span>
        </h2>

        <CardSlider />
      </div>
      <h2
        style={{
          fontWeight: "900",
          lineHeight: "1.25",
          fontSize: "3rem",
          textAlign: "center",
        }}
      >
        Learn From The{" "}
        <span style={{ color: "blue", fontWeight: "900" }}>Top 1%</span>
      </h2>
      <p
        style={{
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "1.625",
          color: " rgb(124 139 161",
          marginTop: "5px",

          textAlign: "center",
        }}
      >
        Education programs to help you start,transition or level-up your career
      </p>
      <h2
        style={{
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        Search Courses By Category{" "}
      </h2>
      <p
        style={{
          fontSize: "12px",
          fontWeight: "500",
          lineHeight: "1.625",
          color: " rgb(124 139 161",
          marginTop: "5px",

          textAlign: "center",
        }}
      >
        Browse Top Courses By Browsing Our Category Which Will Be More Easy To
        You
      </p>
      <div className="text-center">
        {" "}
        <Link to="/Training">
          <button
            className="bg-primary p-3 border rounded text-white fs-16"
            style={{ width: "215px", textAlign: "center" }}
          >
            Explore Training Courses
          </button>
        </Link>
      </div>

      <TrustSlider />
    </div>
  );
};

export default TrustedBy;
