import React, { useState, useEffect } from "react";
import Light from "components/headers/light";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import FiveColumnWithInputForm from "components/footers/FiveColumnWithInputForm";
import "./Training1.css";
import { Link } from "react-router-dom";
import { Row } from "antd";
import { Field } from "rc-field-form";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import ClipLoader from "react-spinners/ClipLoader";
import { localUrl, localUrlImage, url } from "pages/env";
import { LogoLink } from "components/headers/light";
import { useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { isMobile } from "react-device-detect";
import Justry from "Justtry";
const Training = () => {
  const [pro, setPro] = useState([]);

  const navigate = useNavigate();
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 24;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = pro.slice(firstIndex, lastIndex);
  const npage = Math.ceil(pro.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  const [isMobile, setIsMobile] = useState(false);
  const mobstyle = { width: "auto", padding: "20px", margin: "auto" };
  const deskstyle = { margin: "auto", padding: "20px" };

  useEffect(() => {
    if (window.innerWidth <= 500) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const prevPage = () => {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPage = () => {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
  };
  const changePage = (id) => {
    setCurrentPage(id);
  };
  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(localUrlImage + "/job/jobs_details/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPro(result);
        console.log(result, "res");
      })
      .catch((error) => console.log("error", error));
  }, []);

  console.log(pro, "pr");
  const [isShown, setIsShown] = useState(false);
  const [run, setRun] = useState(true);
  useEffect(() => {
    // setRun(true);
    const timer = setTimeout(() => {
      setRun(false);
    }, 1000);
    return () => clearTimeout(timer);
  });
  const mobabt = { maxWidth: "375px" };
  const deskabt = { maxWidth: "520px" };
  return (
    <>
   <Justry/>
      {run ? (
        <center style={{ marginTop: "15%" }}>
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>

          <div style={{ width: "100px", margin: "auto", display: "block" }}>
            <h4 style={{ marginLeft: "14px" }}>Welcome to</h4>
            <br />
            <LogoLink style={{ color: "navy" }}>Allcourses</LogoLink>
          </div>
        </center>
      ) : (
        <div>
          <AnimationRevealPage>
            <div className="">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 px-2 gap-2 ">
                {pro?.map((p,index) => {
                  return (
                    <div>
                      <div className="course-wrapper-2 mb-10">
                        <div style={isMobile ? mobabt : deskabt}>
                          <Card key={index}
                            sx={{
                              maxWidth: 500,
                              background: "rgba(197, 235, 235, 1)",
                              height: "300px",
                            }}
                            onClick={
                              isMobile
                                ? () =>
                                    navigate(`/trainingCertificates/${p?.id}`)
                                : undefined
                            }
                            onMouseEnter={() => setIsShown(true)}
                            onMouseLeave={() => setIsShown(false)}
                          >
                            {isShown && (
                              <div
                                className="course-cart"
                                style={{
                                  backgroundColor: "rgba(237,224,255,1)",
                                }}
                              >
                                <div className="course-info-wrapper">
                                  <div className="cart-info-body">
                                    <span className="category-color category-color-1">
                                      <span>{p.coursename}</span>
                                    </span>
                                    <Link to="/TrainingPricing">
                                      <h3></h3>
                                    </Link>
                                    <div className="cart-lavel">
                                      <p>{p?.desc?.slice(0, 240)}...</p>
                                    </div>
                                    <div className="info-cart-text"></div>
                                    <div className="course-action">
                                      <Link
                                        to={`/trainingCertificates/${p?.id}`}
                                        className="view-details-btn"
                                        style={{
                                          color: "white",
                                          textAlign: "center",
                                        }}
                                      >
{p.coursename}                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* <CardActionArea>
                            <CardMedia
                              component="img"
                              height="140"
                              image={p?.image}
                              alt="green iguana"
                              style={{
                                maxWidth: "100%",
                                height: "200px",
                                objectFit: "fill",
                              }}
                            />
                            <CardContent>
                              <div className="row">
                                <div className="col-md-12">
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                    style={{ fontSize: "17px" }}
                                  ></Typography>
                                  <div
                                    className="portfolio-price"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      fontSize: "17px",
                                    }}
                                  >
                                    <span>{p.title}</span>
                                    <AddRoadIcon
                                      style={{ marginLeft: "200px" }}
                                    />
                                    <span>Latest</span>
                                  </div>
                                </div>
                              </div>
                            </CardContent>
                          </CardActionArea> */}

                            <div className={`bg-[#A1FAFF] w-auto  rounded-xl `}>
                              <img
                                src={p?.image}
                                alt=""
                                className="h-[250px] w-full  "
                              />
                              <div className="flex justify-between mt-[20px] mx-4">
                                <p className="font-bold text-blue-600">
                                  {p.title}
                                </p>
                                {/* <div className="flex">
                                <OpenInNewIcon />
                                <p className="font-bold text-blue-600 ml-2 text-l">
                                  Latest
                                </p>
                              </div> */}
                              </div>
                            </div>
                          </Card>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div style={{ marginLeft: "650px" }}>
                  <div className="col-lg-5">
                    <nav>
                      <ul className="pagination">
                        {/* <li className="page-item">
                      <button className="page-link" onClick={prevPage}>
                        Prev
                      </button>
                    </li> */}
                        {/* {number.map((n, i) => (
                            <li
                              className={`page-link ${
                                currentPage === n ? "active" : ""
                              }`}
                              key={i}
                            >
                              <button
                                className=""
                                onClick={() => changePage(n)}
                              >
                                {n}
                              </button>
                            </li>
                          ))} */}
                        {/* <li className="page-item">
                      <button className="page-link" onClick={nextPage}>
                        Next
                      </button>
                    </li> */}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>{" "}
            </div>
            <FiveColumnWithInputForm />
          </AnimationRevealPage>
        </div>
      )}
    </>
  );
};
export default Training;
