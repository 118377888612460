import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { Link } from "react-router-dom";
import "./ForgotPassward.css";
import { localUrl } from "pages/env";
import validator from "validator";
import "./ForgotPassward.css";
import Procareerlogo from "../../images/Procareerlogo.png";
import { Button, Checkbox, Form, Input, Modal } from "antd";
import { SignalCellularNull } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import { RxValue } from "react-icons/rx";
import {  useNavigate, useParams } from "react-router-dom";


const ForgotPassward = () => {
  //   const [Data, SetData] = useState({
  //     email: "",
  //   });

  //   const ChangeHandler = (e) =>
  //     SetData({ ...Data, [e.target.name]: e.target.value });

  //   const ResetPasswordHandler = (e) => {
  //     e.preventDefault();
  //     var formdata = new FormData();
  //     formdata.append("email", Data.email);

  //     var requestOptions = {
  //       method: "POST",
  //       body: formdata,
  //       redirect: "follow",
  //     };

  //     fetch(localUrl + "user/send-reset-password-email/", requestOptions)
  //       .then((response) => response.json())
  //       .then((result) => console.log(result, "re"))
  //       .catch((error) => console.log("error", error));
  //   };
  const mobform = { margin: "20px" };
  const deskform = {};

  const mobileStyle = {
    width: "100%",
    // padding: "5px",
    justifyContent: "center",
  };

  const [form] = Form.useForm();
  const [Data, SetData] = useState(null);
  const [error, setError] = useState(null);

  const success = () => {
    Modal.success({
      content: "Reset password link has been sent to your registered Email. ",
      okType: "success",
    });
  };
  const fail = () => {
    Modal.success({
      content: "Invailed Email please Enter a vailed Email Address",
      okType: "success",
    });
  };

  const mob = {
    marginTop: "100px",
    width: "auto",
    marginBottom: "-100px",
  };
  const desk = {
    marginTop: "-100px",
  };
  

  var idChecking=useParams()
  const navigate =useNavigate()
  const [afterSuccess,setafterSuccess] =useState(false)
  const [resetpassword, setresetpassword] = useState({
    password:'',  password2:''
  });

  const handleChangeRest=(e)=>{
    var name=e.target.name
    var value=e.target.value
    setresetpassword({...resetpassword,[name]:value})
    console.log("@@checking contactform valuereset",resetpassword)
  }

  const handleSubmitReset=(e)=>{
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("password", resetpassword.password);
    formdata.append("password2", resetpassword.password2);
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    // fetch(localUrl+"api/user/reset-password/"+idChecking?.uid+"/"+idChecking?.id+"/", requestOptions)
    fetch("https://procareers.live/api/user/reset-password/"+idChecking?.uid+"/"+idChecking?.id+"/", requestOptions)
      .then(response => response.json())
      .then(result => {
        
        if(result?.errors?.non_field_errors){
          setError(result?.errors?.non_field_errors)
        }
        else{
          setafterSuccess(true)
          setTimeout(() => {
            navigate("/LoginHeader")
          }, 2000);
        }
      })
      .catch(error => console.log('error', error));
  }
  const ResetPasswordHandler = (e) => {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("email", Data);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(localUrl + "user/send-reset-password-email/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        if (result?.errors?.email[0]) {
          setError(result?.errors?.email[0]);
        }
        if (result === "Success") {
        }
        success();
      })
      .catch((error) => {
        if (error === "invalied email") {
        }
        fail();
        console.log("error", error);
      });
    form.resetFields();
    //   .catch((error) => console.log("result", result));
    //    form.resetFields();
  };
  // const mob1 = {
  //   marginLeft: "12px",
  //   marginRight: "12px!important",
  //   marginTop: "12px",
  //   border: "rounded",
  // };
  const desk1 = {};

  const mob2 = {
    marginTop: "0!important",
  };
  const desk2 = {
    fontSize: "20px",
    marginTop: "-50px",
  };

  const desktopStyle = {};
  const mobileview = {
    width: "100%",
    textAlign: "center",
    marginBottom: "5px",
    justifyContent: "center",
  };
  const mob1 = { justifyContent: "center", width: "auto" };

  return (
    <div style={{ overflowX: "hidden" }}>
      <link
        href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css"
        rel="stylesheet"
        id="bootstrap-css"
      />
      {/*---- Include the above in your HEAD tag --------*/}
      <Form
        form={form}
        className="page-wrap d-flex flex-row align-items-center"
        style={isMobile ? mobileview : desktopStyle}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <img
                src={Procareerlogo}
                className="img-fluid rounded-circle shadow-sm"
                alt=""
              />
            </div>
            <div className="col-md-6">
              <h2 className="font-weight-light" style={{ color: "blueviolet" }}>
                {" "}
                <b>Forgot your password? </b>
              </h2>
              <p
                style={{
                  color: "navy",
                  fontVariant: "inherit",
                  fontSize: "20px",
                }}
              >
                Not to worry. Just enter your email address below and we'll send
                you an instruction email for recovery.
              </p>
              {/* <input
                className="mt-3 form-control form-control-lg"
                type="email"
                placeholder="Your email address"
                onChange={(e) => SetData(e.target.value)}
                // value={Data}
                name="email"
                required
              
              /> */}
              !afterSuccess?
                 !idChecking?.id?
                  !SuccessLinksend?
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  {
                    pattern:
                      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]{1,1}$/,
                    message: "Please enter a valid email!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Your Register Email Address"
                  style={{ width: "300px" }}
                  onChange={(e) => SetData(e.target.value)}
                />
              </Form.Item>
              {error && <div>{error}</div>}
              <div className="text-right my-3" style={isMobile ? mob : desk2}>
                <button
                  className="btn btn-lg btn-success"
                  onClick={ResetPasswordHandler}
                  style={{ backgroundColor: "blueviolet" }}
                >
                  Reset Password
                </button>
                {success ? (
                      <div className="text-success">{success}</div>
                    ) : null}
                    {error ? <div className="text-danger">{error}</div> : null}
                  
              </div>
            </div>
          </div>
        </div>
      </Form>
      <Footer />
    </div>
    // <div>
    //   <AnimationRevealPage>
    //     <center>
    //       <div className="colorofBody" style={{ margin: "20px" }}>
    //         <div className="card text-center" style={{ width: "400px" }}>
    //           <div
    //             className="card-header h5 text-white "
    //             style={{
    //               backgroundColor: "blue",
    //               color: "white",
    //               margin: "10px",
    //             }}
    //           >
    //             Password Reset
    //           </div>
    //           <div className="card-body px-5">
    //             <p className="card-text py-2" style={{ fontWeight: "bold" }}>
    //               Enter your email address and we'll send you an email with
    //               instructions to reset your password.
    //             </p>
    //             <div className="form-outline">
    //               <input
    //                 type="email"
    //                 id="typeEmail"
    //                 value={Data.email}
    //                 name="email"
    //                 className="form-control my-3"
    //                 placeholder="Enter your email"
    //                 onChange={ChangeHandler}
    //               />
    //               <label
    //                 className="form-label"
    //                 htmlFor="typeEmail"
    //                 style={{ margin: "0px", padding: "0px" }}
    //               >
    //                 Email input
    //               </label>
    //             </div>
    //             <button
    //               type="email"
    //               className="btn btn-primary w-80"
    //               onClick={ResetPasswordHandler}
    //               style={{
    //                 color: "white",
    //                 backgroundColor: "bg-primary",
    //                 width: "auto",
    //               }}
    //             >
    //               Reset password
    //             </button>
    //             <div className="d-flex justify-content-between mt-4">
    //               <Link to="/LoginHeader">Login</Link>
    //               <Link to="/SignupHeader2">Register</Link>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </center>
    //     <Footer />
    //   </AnimationRevealPage>
    // </div>
  );
};

export default ForgotPassward;
