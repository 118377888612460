import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import "./TermsAndCond.css";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = " Tearms And Condition" }) => {
  return (
    <AnimationRevealPage>
      <ContentWithPaddingXl>
        <div className="aboutus_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <div className="about_text_wrapper">
                  <div className="section_heading section_2_heading">
                    <h2>Terms &amp; Conditions</h2>
                  </div>
                  <ul style={{ marginTop: 30 }}>
                    <li>
                      <i className="fa fa-check-square" aria-hidden="true" />
                      <p>
                        The following website Terms and Conditions govern all
                        use of the Procareers website and all content, services,
                        and products available at or through the website. The
                        Website is owned and operated by Procareers.in
                        (Procareers Infotech Solutions). The Website is offered
                        subject to your acceptance without modification of all
                        of the Terms and Conditions contained herein and all
                        other operating rules, policies and procedures that may
                        be published from time to time on this Site by
                        Procareers (collectively, the “Agreement”)
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-check-square" aria-hidden="true" />
                      <p>
                        Please read this website terms agreement carefully
                        before accessing or using the Website. By accessing or
                        using any part of the web site, you agree to become
                        bound by the Terms and Conditions (T&C) of this
                        agreement. If you do not agree to all the Terms and
                        Conditions of this website maintenance agreement, then
                        you may not access the Website or use any services. If
                        these Terms and Conditions are considered an offer by
                        Procareers, acceptance is expressly limited to these
                        terms. The materials contained in this web site are
                        protected by applicable copyright and trademark law.
                      </p>
                    </li>
                  </ul>
                  <div className="section_heading section_2_heading">
                    <h2>Description of Service</h2>
                  </div>
                  <p>
                    Procareers provides users with access to a collection of
                    resources, including various tools, training materials, and
                    educational content through its network of properties, which
                    may be accessed through any medium or device now known, or
                    hereafter developed (the “Service”). You also understand and
                    agree that the Service may include advertisements and that
                    these advertisements are necessary for Procareers to provide
                    the Service. You also understand and agree that the Service
                    may include certain communications from Procareers, such as
                    service announcements, administrative messages and
                    newsletter(s) and that these communications are considered
                    part of your membership. Unless explicitly stated otherwise,
                    any new features that augment or enhance the current
                    Service, including the release of new Procareers properties,
                    shall be subject to the TOS. You understand and agree that
                    the Service is provided “AS-IS” and that Procareers assumes
                    no responsibility for the timeliness, deletion,
                    mis-delivery, or failure to store any user communications or
                    personalization settings. You are responsible for obtaining
                    access to the Service, and that access may involve
                    third-party fees (such as Internet service provider or
                    airtime charges). You are responsible for those fees,
                    including those fees associated with the display or delivery
                    of advertisements. In addition, you must provide and are
                    responsible for all equipment necessary to access the
                    Service.
                  </p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <div className="about_text_wrapper abt_2_para">
                  <div className="section_heading section_2_heading">
                    <h2>Your Registration Obligations</h2>
                  </div>
                  <p>
                    In consideration of your use of the Service, you represent
                    that you are of legal age to form a binding contract and are
                    not a person barred from receiving services under the laws
                    of the India or other applicable jurisdiction. You also
                    agree to: <br />
                    <br />
                    (a)Provide true, accurate, current and complete information
                    about yourself as prompted by the Service registration form
                    (the “Registration Data”)
                    <br />
                    <br /> (b)Also maintain and promptly update the Registration
                    Data to keep it true, accurate, current and complete. If you
                    provide any information that is untrue, inaccurate, not
                    current or incomplete, or Procareers has reasonable grounds
                    to suspect that such information is untrue, inaccurate, not
                    current or incomplete, Procareers as the right to suspend or
                    terminate your account and refuse any and all current or
                    future use of the Service.
                  </p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <div className="about_text_wrapper abt_2_para">
                  <div className="section_heading section_2_heading">
                    <h2>Member Account, Password and Security </h2>
                  </div>
                  <p>
                    You will create a password and account designation upon
                    completing the Service registration process. You are
                    responsible for maintaining the confidentiality of the
                    password, account and you are fully responsible for all
                    activities that occur under your password or account. You
                    agree to <br />
                    <br /> (a)Immediately notify Procareers of any unauthorized
                    use of your password or account or any other breach of
                    security.
                    <br />
                    <br /> (b) Ensure that you exit from your account at the end
                    of each session. Procareers cannot or will not be liable for
                    any loss or damage arising from your failure to comply with
                    this Section 4.
                  </p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <div className="about_text_wrapper abt_2_para">
                  <div className="section_heading section_2_heading">
                    <h2> Payment and Renewal</h2>
                  </div><br/><br/>
                  <div className="section_heading section_2_heading">
                    <h2> General Terms</h2>
                  </div>
                  <p>
                    By selecting a product or service, you agree to pay
                    Procareers the one-time and/or monthly or annual
                    subscription fees indicated (additional payment terms may be
                    included in other communications). Subscription payments
                    will be charged on a pre-pay basis on the day you sign up
                    for an Upgrade and will cover the use of that service for a
                    monthly or annual subscription period as indicated. Payments
                    are not refundable.
                  </p>

                  <div className="section_heading section_2_heading">
                    <h2>Automatic Renewal</h2>
                  </div>
                  <p>
                    Unless you notify Procareers before the end of the
                    applicable subscription period that you want to cancel a
                    subscription, your subscription will automatically renew and
                    you authorize us to collect then-applicable annual or
                    monthly subscription fee for such subscription (as well as
                    any taxes) using any credit card or other payment mechanism
                    we have on record for you. Subscriptions can be cancelled at
                    any time by reaching out to our support team.
                  </p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <div className="about_text_wrapper abt_2_para">
                  <div className="section_heading section_2_heading">
                    <h2>Services</h2>
                  </div><br/><br/>
                  <div className="section_heading section_2_heading">
                    <h2>Fees; Payment</h2>
                  </div>
                  <p>
                    By signing up for a membership account, you agree to pay
                    Procareers the applicable setup fees and recurring fees.
                    Applicable fees will be invoiced starting from the day your
                    services are established and in advance of using such
                    services. Procareers reserves the right to change the
                    payment terms and fees upon thirty (30) days prior written
                    notice to you. You can cancel services at any time by
                    clicking the link on your Profile page or submitting written
                    notice to Procareers.
                  </p>
                  <div className="section_heading section_2_heading">
                    <h2>Support</h2>
                  </div>
                  <p>
                    All subscriptions include access to support via the
                    members-only Q&A Forum. “Support” means the ability to make
                    requests for technical support assistance via the forum at
                    any time (with reasonable efforts by Procareers to respond
                    within 1-2 business days) concerning the use of the
                    Procareers Services. All support will be provided in
                    accordance with Procareers standard services practices,
                    procedures, and policies.
                  </p>
                  <p>• Modify or copy the materials</p>
                  <p>
                    • Use the materials for any commercial purpose, or for any
                    public display (commercial or non-commercial)
                  </p>
                  <p>
                    • Attempt to decompile or reverse engineer any software
                    contained on Procareers web site
                  </p>
                  <p>
                    • Remove any copyright or other proprietary notations from
                    the materials
                  </p>
                  <p>
                    • Transfer the materials to another person or “mirror” the
                    materials on any other server
                  </p>
                  <p>
                    This license shall automatically terminate if you violate
                    any of these restrictions and may be terminated by
                    Procareers at any time. Upon terminating your viewing of
                    these materials or upon the termination of this license, you
                    must destroy any downloaded materials in your possession
                    whether in electronic or printed format.
                  </p>

                  <div className="section_heading section_2_heading">
                    <h2>Disclaimer</h2>
                  </div>
                  <p>
                    The materials on Procareers web site are provided “as is”.
                    Procareers makes no warranties, expressed or implied, and
                    hereby disclaims and negates all other warranties, including
                    without limitation, implied warranties or conditions of
                    merchantability, fitness for a particular purpose, or
                    non-infringement of intellectual property or other violation
                    of rights. Further, Procareers does not warrant or make any
                    representations concerning the accuracy, likely results or
                    reliability of the use of the materials on its Internet web
                    site or otherwise relating to such materials or on any sites
                    linked to this site.
                  </p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <div className="about_text_wrapper abt_2_para">
                  <div className="section_heading section_2_heading">
                    <h2> Links</h2>
                  </div>
                  <p>
                    Procareers has not reviewed all of the sites linked to its
                    Internet web site and is not responsible for the contents of
                    any such linked site. The inclusion of any link does not
                    imply endorsement by Procareers of the site. Use of any such
                    linked website is at the users own risk.
                  </p>

                  <div className="section_heading section_2_heading">
                    <h2>Site Terms of Use Modifications</h2>
                  </div>
                  <p>
                    Procareers may revise these terms of use for its web site at
                    any time without notice. By using this website, you are
                    agreeing to be bound by the then current version of these
                    Terms and Conditions of Use.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="aboutus_page_2_section">
          <div className="container" style={{ marginTop: 0 }}></div>
        </div>
      </ContentWithPaddingXl>

      <Footer />
    </AnimationRevealPage>
  );
};
