import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import ContactUsPage from "../images/demo/ContactUsPage.jpeg";
// D:\templateReact\ReactNewApp1.0\src\images\demo\ContactUsPage.jpeg
import { isMobile } from "react-device-detect";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import PorcareersFont from "images/PorcareersFont.png";
import { Modal, Space, Tooltip } from "antd";
import ModalForm from "../components/headers/ModalForm";

import { useLocation, useNavigate } from "react-router-dom";

const Subheading = tw.span`uppercase tracking-wider text-xl font-medium `;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const mobabt = { padding: "20px" };
const deskabt = {};
const mobwel = { marginTop: "10px" };
const deskwel = {};
export default () => {
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    console.log(closeTime);
    if (closeTime < 2) {
      setTimeout(() => {
        setModalOpen(true);
      }, 5000);
    }
  }, [location]);
  const [closeTime, setCloseTime] = useState(1);
  const modalClose = () => {
    setCloseTime(closeTime + 1);
    setModalOpen(false);
  };
  return (
    <div>
     <Modal
        className="bg-white"
        title="Get in Touch with us"
        open={modalOpen}
        onOk={modalClose}
        okType="success" 
        onCancel={modalClose}
        style={{ margin: "auto", width:"300px",height:"200px" }}
      >
        <ModalForm />
      </Modal> 
    <AnimationRevealPage>
      {/* <Header /> */}
      <center>
        <div style={{ marginBottom: "2px" }}>
          <div className="card text-center">
            <div className="card-header" style={isMobile ? mobwel : deskwel}>
              <Heading style={{ fontSize: "35px" }}>
                Welcome to{" "}
                <img src={PorcareersFont} style={{ margin: "auto" }} />
              </Heading>
              <br />

              <p style={{ fontSize: "35px" }}>
                Company <span tw="text-primary-500">Profile.</span>
              </p>
              <Paragraph>
                Procareers is one of the fastest growing HR Firms with focus on
                Premium Placement Service Our Endeavour is to provide simple,
                strong, fast, dynamic and responsive experience to our valued
                customers in their quest for searching the best man-power
                available. Our well trained Staff combines speed and experience
                to find the best possible talent, in the most demanding skill
                areas too
              </Paragraph>
            </div>
          </div>
        </div>
      </center>
      <div style={isMobile ? mobabt : deskabt}>
        <MainFeature2
          description="Procareers is one of the fastest growing HR Firms with focus on Premium Placement Service Our Endeavour is to provide simple, strong, fast, dynamic and responsive experience to our valued customers in their quest for searching the best man-power available. Our well trained Staff combines speed and experience to find the best possible talent, in the most demanding skill areas too."
          subheading={<Subheading>About Procareers</Subheading>}
          to="/about"
          heading="We are a modern design agency."
          buttonRounded={false}
          primaryButtonText="See Portfolio"
          imageSrc="https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZGVzaWduJTIwYWdlbmN5fGVufDB8fDB8fA%3D%3D&w=1000&q=80"
        />
      </div>

      <MainFeature1
        description={`At Procareer, we work to bring humanity and opportunity to the job market, to enhance lives, businesses and communities around the world. Procareers will be the leading global talent platform connecting jobs and people`}
        to="/contact"
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the design space."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      {/* <Features
        style={{ padding: "12px" }}
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="We help employers find not only the best quality candidates, but more of them. To streamline the process so you can save time and money. And to help you make smarter decisions to improve your ROI. Basically, we want to give you the ability to hire like no one else can."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description:
              "We offer dependable, on-demand support options including 24/7 technical and remote support and 4-hour and next business day global on-site support in over 100 countries worldwide",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description:
              "A strong team is one in which employees work closely together and contribute to the overall quality and success of a business.",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description:
              "Providing goods and services isn’t all your business does; your real mission is to satisfy and delight your customers.",
          },
        ]}
        linkText=""
      /> */}
      {/* <TeamCardGrid subheading={<Subheading>Our Team</Subheading>} /> */}
      <br></br>
      <br></br>
      <Footer />
    </AnimationRevealPage>
    </div>
  );
};
