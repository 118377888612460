import React from 'react'

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";
import "./RefundPolicy.css"
import FiveColumnWithInputForm from 'components/footers/FiveColumnWithInputForm';
import Light from 'components/headers/light';



const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;


const RefundPolicy = () => {
  return (
    <div>
      <div>
        <AnimationRevealPage>
          <Light />
          <Container>
            <ContentWithPaddingXl>

            <div className="container">
  <div className="row">
    <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
      <div className="about_text_wrapper">
        <div className="section_heading section_2_heading">
          <h2 style={{ marginTop: 20 }}> Returns</h2>
        </div>
        <p>
          Our policy lasts 30 days. If 30 days have gone by since your purchase,
          unfortunately we can’t offer you a refund or exchange.
        </p>
        <p>
          To be eligible for a return, your item must be unused and in the same
          condition that you received it. It must also be in the original
          packaging.
        </p>
        <p>
          Several types of goods are exempt from being returned. Perishable
          goods such as food, flowers, newspapers or magazines cannot be
          returned. We also do not accept products that are intimate or sanitary
          goods, hazardous materials, or flammable liquids or gases.
        </p>
        <h5>Additional non-returnable items:</h5>
        <ul>
          <li>Gift cards</li>
          <li>Downloadable software products</li>
          <li>Some health and personal care items</li>
        </ul>
        <p>
          To complete your return, we require a receipt or proof of purchase.
        </p>
        <p>Please do not send your purchase back to the manufacturer.</p>
        <p>
          There are certain situations where only partial refunds are granted:
          Book with obvious signs of use
        </p>
        <p>
          CD, DVD, VHS tape, software, video game, cassette tape, or vinyl
          record that has been opened.
        </p>
        <p>
          Any item not in its original condition, is damaged or missing parts
          for reasons not due to our error.
        </p>
        <p>Any item that is returned more than 30 days after delivery</p>
      </div>
    </div>
    <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
      <div className="about_text_wrapper">
        <div className="section_heading section_2_heading">
          <h2 style={{ marginTop: 20 }}> Refunds</h2>
        </div>
        <p>
          Once your return is received and inspected, we will send you an email
          to notify you that we have received your returned item. We will also
          notify you of the approval or rejection of your refund.
        </p>
        <p>
          If you are approved, then your refund will be processed, and a credit
          will automatically be applied to your credit card or original method
          of payment, within a certain amount of days.
        </p>
      </div>
    </div>
    <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
      <div className="about_text_wrapper">
        <div className="section_heading section_2_heading">
          <h2 style={{ marginTop: 20 }}> Late or missing refunds</h2>
        </div>
        <p>
          If you haven’t received a refund yet, first check your bank account
          again. Then contact your credit card company, it may take some time
          before your refund is officially posted.
        </p>
        <p>
          Next contact your bank. There is often some processing time before a
          refund is posted.
        </p>
        <p>
          If you’ve done all of this and you still have not received your refund
          yet, please contact us at hrd@Procareers.in
        </p>
      </div>
    </div>
  </div>
</div>


              
            </ContentWithPaddingXl>
          </Container>
          <FiveColumnWithInputForm />
        </AnimationRevealPage>

      </div>

    </div>
  )
}

export default RefundPolicy
