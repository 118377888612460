import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import "./TrainSlick.css";
import { localUrl, localUrlImage } from "pages/env";

const CenterMode = ({ courseDetails }) => {
  console.log("##", courseDetails);

  return (
    <div>
      <div className="row">
        <div className=" image1 col-md-4">
          <img
            src={localUrlImage + `${courseDetails?.students?.review1Image}`}
            srcSet=""
            alt=""
          />

          <div className="caption-container">
            <p className="quote caption-text font-medium">
              {courseDetails?.students?.review1Decs}
            </p>
            <div className="font-bold">
              {courseDetails?.students?.review1Name}
            </div>
          </div>
        </div>

        <div className="image2 col-md-4">
          <img
            src={localUrlImage + `${courseDetails?.students?.review2Image}`}
            srcSet=""
            alt=""
          />

          <div className="caption-container">
            <p className="quote caption-text font-medium">
              {courseDetails?.students?.review2Decs}
            </p>
            <div className="font-bold">
              {courseDetails?.students?.review2Name}
            </div>
          </div>
        </div>

        <div className=" image3 col-md-4">
          <img
            src={localUrlImage + `${courseDetails?.students?.review3Image}`}
            srcSet=""
            alt=""
          />

          <div className="caption-container">
            <p className="quote caption-text font-medium">
              {courseDetails?.students?.review3Decs}
            </p>
            <div className="font-bold">
              {courseDetails?.students?.review3Name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenterMode;
