import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Procareerlogo from "../images/Procareerlogo.png";
import loaders from "./spinr.css";
import "./spinr.css";

import { LogoLink } from "components/headers/light";

function SpinnerCenter() {


 
  return (
    <center style={{ marginTop: "15%" }}>
     
     {/* <div className="loader"></div> */}
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>

      <div style={{ width: "100px", margin: "auto", display: "block" }}>
        <h4 style={{ marginLeft: "11px" }}>Welcome to</h4>
        <br />
        <LogoLink style={{color:"darkblue"}}>Procareers</LogoLink>
     
      </div>
    </center>
  );
}

export default SpinnerCenter;
