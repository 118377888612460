import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "antd";
import { BsCheck2, BsCheckLg } from "react-icons/bs";
import { localUrl, url } from "pages/env";

const MyCourse = (props) => {
  const [courseuser, setCourseUser] = useState();
  const [userid, setUserId] = useState();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    console.log("props", props);
    var formdata = new FormData();
    formdata.append("user", props.props);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let userData = [];
    console.log(userData, "useradta");
    setLoader(true);
    fetch(localUrl + "jobs/applieduser", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCourseUser(JSON.parse(result.allcourse));
      })
      .catch((error) => console.log("error", error));
  }, []);
  console.log(courseuser?.length, "kk");
  return (
    <>
      <div>
        <p
          className="text-center font-bold text-decoration-underline"
          style={{ fontSize: "22px" }}
        >
          MY COURSES
        </p>
        {courseuser?.length === undefined ? (
          <div className="row text-center">
            <div className="col-lg-2"></div>
            <div className="col-lg-7 border rounded p-3">
              <p style={{ fontSize: "20px" }} className="mt-3">
                No Courses please choose courses
              </p>
            </div>
          </div>
        ) : (
          "data"
        )}
        {courseuser?.map((user) => (
          <div className="option-list no-bar">
            {/* <div
            className="col-md-4 selfpaced pb-[35px]"
            style={{ paddingBottom: "35px" }}
          > */}
            <div className="col-lg-4 border rounded p-2 m-2">
              <b
                className="cp-type"
                data-test="selfpaced-cp-type"
                style={{ fontSize: "22px" }}
              >
                {user.fields.title}
              </b>

              <div
                className="list-info"
                id="self-placed"
                data-test="self-placed-list-info"
              >
                <ul className="mt-2">
                  <li className="d-flex">
                    <BsCheck2 style={{ fontSize: "20px" }} />
                    &nbsp;{user.fields.why1}
                  </li>
                  <li className="d-flex">
                    {" "}
                    <BsCheck2 style={{ fontSize: "20px" }} />
                    &nbsp;
                    {user.fields.why2}
                  </li>
                  <li className="d-flex">
                    {" "}
                    <BsCheck2 style={{ fontSize: "20px" }} />
                    &nbsp;{user.fields.why3}
                  </li>
                  <li className="d-flex">
                    {" "}
                    <BsCheck2 style={{ fontSize: "20px" }} />
                    &nbsp;{user.fields.why4}
                  </li>
                  <li className="d-flex">
                    {" "}
                    <BsCheck2 style={{ fontSize: "20px" }} />
                    &nbsp;{user.fields.why5}
                  </li>
                </ul>
              </div>
              <div id className style={{ textAlign: "center!important" }}>
                <span>
                  <Link to={`/Certification/${user.fields?.id}`} type="primary">
                    <Button type="link" danger>
                      {" "}
                      Read More...
                    </Button>
                  </Link>
                </span>
              </div>

              <div
                className="mEnrollNow wingchak"
                data-product_id={670}
                data-trainingtypeid={2}
                data-access_days={180}
                data-form_id="oslEnrollBtn"
              >
                <div id="ContactUsForm">
                  <center>
                    <Link
                      to={`/TrainingPricing/${id}`}
                      // className="btn btn-primary btn-sm"
                      // style={{ paddingBottom: "6px", color: "white", borderRadius:"5px" }}
                    >
                      <Button type="primary" className="text-dark">
                        Enroll now
                      </Button>
                    </Link>
                  </center>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MyCourse;
