import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import Link from "@mui/icons-material/Link";
import "./Line.css";
import MainFeature1 from "components/features/TwoColWithButton.js";
import { Subheading } from 'components/misc/Headings';


const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "Anatomy of ProCareers Infotech Solutions",
  // posts = [
  //   {
  //     imageSrc: "https://blog.rpoassociation.org/hubfs/RPOAImg217.jpg",
  //     category: " Tips",
  //     // date: "April 21, 2020",
  //     //title: "avoid the top six most common job interview mistakes",
  //     description:
  //       "First impressions are key: research has found most interviews are decided in the first two to three minutes. Make sure you practise your strong and professional handshake accompanied by a gracious smile and confident body language. But don’t let the confidence end there. From the moment you press the buzzer, you should come across as professional and dynamic."

  //     url: "https://timerse.com",
  //     featured: true,
  //   },
  //   // (headingText = "Anatomy of ProCareers Infotech Solutions:"),
  //   {
  //     imageSrc:
  //       "https://blog.colonialstock.com/wp-content/uploads/2019/09/adult-black-boardroom-1345085.jpg",
  //     category: " Anatomy of ProCareers Infotech Solutions",
  //     // date: "April 21, 2020",
  //     //title: "avoid the top six most common job interview mistakes",
  //     description:
  //       "1.	We can provide you with wide spectrum of candidates because of our network of local agents are spread all over the local market, from skilled to unskilled, and from  Manufacturing to Service sector, we are specialized in the searching any type of candidates throughout our network.",
  //     2: "2. Profoundly relevant industry and vertical recruitment experience. ",
  //     3: "3.	We are affiliated with Training Institute and we can provide refreshment training as well as full training to the applicant whenever required.  ",
  //     4: "4. 	Seeing and understanding Business Drivers and accordingly spotting talent to run that business. ",
  //     5: "5. 	Quality assurance to our clients. ",
  //     6: "6. 	We maintain the quality of our services and make extra efforts for sending candidate who are renowned for their hard work, honesty, high sense of responsibility and discipline",
  //     7: "7. 	We understand the needs of the client both specified and unspecified, so we really value the time of our clients. Hence, our team is committed to produce the result on time. ",
  //     8: "8. 	Redefined Listening skills: aids in understanding needs of companies as well as potential applicants ",
  //     9: "9. 	We are responsive as well as communicative, so we do not let any gaps in between the client and us. We are transparent and make the recruitment process as simple as possible. ",
  //     10: "10. 	Ability to take heavy lifting projects. ",
  //     11: "11. 	We are very cost effective without compromising with the quality. In today's world money, minded people are found in each and every steps of our life. Such people always think about self-benefit only and do not  care about the problem of others. Even once, to get in hand of such people can be great loss for anyone. To avoid such types of loss one has to be very careful in selecting the partner. The partner must not be conscious about his/her benefit only, but also about the transactions of partner.  ",

  //     url: "https://timerse.com",
  //     featured: true,
  //   },

  //   {
  //     imageSrc:
  //       "https://factorialhr.com/wp-content/uploads/2019/12/12132801/recruitment-process1.jpeg",
  //     category: "RECRUITMENT PROCEDURE  ",
  //     // date: "April 21, 2020",
  //     //title: "avoid the top six most common job interview mistakes",
  //     description: "Information: ",
  //     12: "We have to receive the following information from employer concerning to the requirement of candidates. ",
  //     13: "•	Exact Job Description",
  //     14: "•	Required No of Candidates",
  //     15: "•	Basic Salary",
  //     16: "•	Allowance if any",
  //     17: "•	Job Starting Date",
  //     18: "•	Other Terms and conditions if any",
  //     19: "Pre-Selection Interview: ",
  //     20: "",
  //     21: "",
  //     22: "",
  //     23: "",
  //     24: "",
  //     25: "",
  //     26: "",
  //     27: "",
  //     28: "",
  //     29: "",
  //     30: "",
  //     url: "https://timerse.com",
  //     featured: true,
  //   },
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost()2
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost(),
  //   // getPlaceholderPost(),
  // ],
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible((v) => v + 6);
  };
  return (
    <AnimationRevealPage>
      <Container>
      <MainFeature1
        description="First impressions are key: research has found most interviews are decided in the first two to three minutes. Make sure you practise your strong and professional handshake accompanied by a gracious smile and confident body language. But don’t let the confidence end there. From the moment you press the buzzer, you should come across as professional and dynamic."
        subheading={<Subheading>Tips</Subheading>}
        to="#"
        heading=""
       
        imageSrc="https://blog.rpoassociation.org/hubfs/RPOAImg217.jpg"
      />

      <MainFeature1
        description={
          <ul>
            <li style={{fontSize:"17px"}}>1. We can provide you with wide spectrum of candidates because of our network of local agents are spread all over the local market, from skilled to unskilled, and from  Manufacturing to Service sector, we are specialized in the searching any type of candidates throughout our network.</li>
            <li style={{fontSize:"17px"}}>2. Profoundly relevant industry and vertical recruitment experience.</li>
            <li style={{fontSize:"17px"}}>3.	We are affiliated with Training Institute and we can provide refreshment training as well as full training to the applicant whenever required.</li>
            <li style={{fontSize:"17px"}}>4. 	Seeing and understanding Business Drivers and accordingly spotting talent to run that business.</li>
            <li style={{fontSize:"17px"}}>5. 	Quality assurance to our clients.</li>
            <li style={{fontSize:"17px"}}>6. 	We maintain the quality of our services and make extra efforts for sending candidate who are renowned for their hard work, honesty, high sense of responsibility and discipline</li>
            <li style={{fontSize:"17px"}}>7. 	We understand the needs of the client both specified and unspecified, so we really value the time of our clients. Hence, our team is committed to produce the result on time. </li>
            <li style={{fontSize:"17px"}}>8. 	Redefined Listening skills: aids in understanding needs of companies as well as potential applicants</li>
            <li style={{fontSize:"17px"}}>9. 	We are responsive as well as communicative, so we do not let any gaps in between the client and us. We are transparent and make the recruitment process as simple as possible.</li>
            <li style={{fontSize:"17px"}}>10. 	Ability to take heavy lifting projects.</li>
            <li style={{fontSize:"17px"}}>11. 	We are very cost effective without compromising with the quality. In today's world money, minded people are found in each and every steps of our life. Such people always think about self-benefit only and do not  care about the problem of others. Even once, to get in hand of such people can be great loss for anyone. To avoid such types of loss one has to be very careful in selecting the partner. The partner must not be conscious about his/her benefit only, but also about the transactions of partner.</li>
            
          </ul>
        }
        to="#"
        subheading={<Subheading>Anatomy of ProCareers Infotech Solutions:</Subheading>}
        heading="Anatomy of ProCareers Infotech Solutions:."
        imageSrc="https://blog.colonialstock.com/wp-content/uploads/2019/09/adult-black-boardroom-1345085.jpg"
        textOnLeft={false}
      />
        <MainFeature1
        description={
          <ul>
            <li style={{fontSize:"17px"}}>Information:</li>
            <li style={{fontSize:"17px"}}>We have to receive the following information from employer concerning to the requirement of candidates.</li>
            <li style={{fontSize:"17px"}}>•	Exact Job Description</li>
            <li style={{fontSize:"17px"}}>•	Required No of Candidates</li>
            <li style={{fontSize:"17px"}}>•	Basic Salary ?</li>
            <li style={{fontSize:"17px"}}>•	Allowance if any</li>
            <li style={{fontSize:"17px"}}>•	Job Starting Date</li>
            <li style={{fontSize:"17px"}}>•	Other Terms and conditions if any</li>
            <li style={{fontSize:"17px"}}>Pre-Selection Interview:</li>
          </ul>
        }
        subheading={<Subheading>RECRUITMENT PROCEDURE</Subheading>}
        to="#"
        heading=""
        imageSrc="https://factorialhr.com/wp-content/uploads/2019/12/12132801/recruitment-process1.jpeg"
      />


        <ContentWithPaddingXl>
          <HeadingRow>
            
          </HeadingRow>
{/* 
          <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className="page-item"><a className="page-link" href="#">Previous</a></li>
          <li className="page-item"><a className="page-link" href="#">1</a></li>
          <li className="page-item"><a className="page-link" href="#">2</a></li>
          <li className="page-item"><a className="page-link" href="#">3</a></li>
          <li className="page-item"><a className="page-link" href="#">Next</a></li>
        </ul>
      </nav> */}
  
          {/* <center>
            <div className="row">
              <div className="edu-pagination mt-30 mb-20">
                <ul>
                  <li>
                    <i className="fal fa-angle-left" />
                  </li>
                  <li className="active">
                    <span>01</span>
                  </li>
                  <li>
                    <span>02</span>
                  </li>
                  <li>
                    <span>03</span>
                  </li>
                  <li>
                    <span>04</span>
                  </li>

                  <li>
                    <i className="fal fa-angle-right" />
                  </li>
                </ul>
              </div>
            </div>
          </center> */}
          {/* {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>
                Load More
              </LoadMoreButton>
            </ButtonContainer>
          )} */}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

const getPlaceholderPost = () => ({
  imageSrc:
    "https://images.unsplash.com/photo-1418854982207-12f710b74003?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  category: " Guide",
  date: "April 19, 2020",
  title: "Visit the beautiful Alps in Switzerland",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "https://reddit.com",
});
