const { createSlice } = require("@reduxjs/toolkit");

const CrmSlice = createSlice({
  name: "CrmBiziverse",
  initialState: {
    data: [],
    status: false,
  },
  reducers: {
    postData: (state, { payload }) => {
      state.data = payload;
    },
    postStatus: (state, { payload }) => {
      state.status = payload;
    },
  },
});

export const { postData, postStatus } = CrmSlice.actions;
export default CrmSlice.reducer;

export const crmApi = (userInfo) => {
  return async function getData(dispatch) {
    dispatch(postStatus(true));

    console.log("userInfo", userInfo);
    try {
      dispatch(postStatus(true));
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append(
        "companyName",
        userInfo?.companyName ? userInfo?.companyName : "null"
      );
      urlencoded.append("title", userInfo?.title ? userInfo?.title : "null");
      urlencoded.append(
        "firstName",
        userInfo?.firstName ? userInfo?.firstName : "null"
      );
      urlencoded.append("lastName", userInfo?.user?.name);
      urlencoded.append("email", userInfo?.user?.email);
      urlencoded.append("mobile", userInfo?.phone);
      urlencoded.append(
        "designation",
        userInfo.designation ? userInfo?.designation : "null"
      );
      urlencoded.append("city", userInfo?.city ? userInfo?.city : "null");
      urlencoded.append("state", userInfo?.state ? userInfo.state : "null");
      urlencoded.append("needs", userInfo?.needs ? userInfo?.needs : "null");
      urlencoded.append("source", userInfo?.source ? userInfo?.source : "null");
      urlencoded.append(
        "apiKey",
        "0010-8B706EE6-F25D-46C9-A5D6-185F1C14F88B-6679"
      );
      urlencoded.append(
        "apiParams",
        `[{"moduleID":25,"actionType":"setLead","data":"[{\\"companyName\\":\\"${userInfo?.companyName}\\",\\"title\\":\\"${userInfo?.title}\\",\\"firstName\\":\\"${userInfo?.firstName}\\",\\"lastName\\":\\"${userInfo?.user?.name}\\",\\"email\\":\\"${userInfo?.user?.email}\\",\\"mobile\\":\\"${userInfo?.phone}\\",\\"designation\\":\\"${userInfo.designation}\\",\\"city\\":\\"${userInfo?.city}\\",\\"state\\":\\"${userInfo?.state}\\",\\"needs\\":\\"${userInfo?.needs}\\",\\"source\\":\\"${userInfo?.source}\\"}]"}]`
      );
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
      const res = await fetch(
        "https://biziverse.com/PremiumAPI.asmx/setAPI",
        requestOptions
      );
      const data = await res.json();
      console.log("Biziverse", data);
      dispatch(postStatus(false));
    } catch (error) {
      dispatch(postStatus(false));
      console.log("catch error", error);
    }
    dispatch(postStatus(false));
  };
};
