import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import userProfileSlice from "./slices/userProfile";
import CrmSlice from "./slices/CrmSlice";
export const store = configureStore({
  reducer: {
    userSlice: userSlice,
    userProfile: userProfileSlice,
    CrmData: CrmSlice,
  },
});
