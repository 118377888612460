import React, { useState, useEffect } from "react";
import Header from "components/headers/light";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { useParams } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import { localUrl, url } from "./env";
import Axios from "axios";

const Form1 = () => {
  const param = useParams();
  const [price, setPrice] = useState("");
  const [plan, setPlan] = useState("");
  var id = param.id;
  ////razorpay payment gateway

  const [name, setName] = useState("");
  const [amount, setAmount] = useState(null);
  const [order, setOrder] = useState(null);
  const [finalObj, setFinalObj] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (id == "Personal") {
      setAmount("1");
      setOrder("PERSONAL");
    } else if (id == "Business") {
      setAmount("1");
      setOrder("BUSINESS");
    } else {
      setAmount("1");
      setOrder("ENTERPRICE");
    }
  }, []);
  // final object
  var finalObjOnce;
  const finalSuccess = (v1) => {
    console.log(v1);
    // console.log(finalObj)
    let finalObj = v1;
    console.log(finalObjOnce);
    finalObj["order"] = finalObjOnce;
    setFinalObj(finalObj);
    console.log("final call happen");
    console.log(order);
    setSuccess(true);
    finalDetailsPayment(v1, finalObjOnce);
  };

  const finalDetailsPayment = (finalData, finalOrder) => {
    let objFinalDetails = {};
    objFinalDetails["UserID"] = finalData["user"];
    objFinalDetails["plan"] = finalData["plan"];
    objFinalDetails["orderid"] = finalOrder["id"];
    // debugger;
    var formdata = new FormData();
    formdata.append(objFinalDetails);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(localUrl + "user/paymentDetails", requestOptions)
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    console.log(objFinalDetails);
  };

  const handlePaymentSuccess = async (response, tempFinalObj) => {
    try {
      let bodyData = new FormData();

      // we will send the response we've got from razorpay to the backend to validate the payment
      bodyData.append("response", JSON.stringify(response));

      await Axios({
        url: localUrl + "razorpay/payment/success/",
        method: "POST",
        data: bodyData,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          finalSuccess(tempFinalObj);
          console.log("Everything is OK!");
          setName("");
          setAmount("");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(console.error());
    }
  };
  // this will load a script tag which will open up Razorpay payment card to make transactions
  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    document.body.appendChild(script);
  };

  const showRazorpay = async (tempFinalObj) => {
    const res = await loadScript();
    // let amount=v1
    // let name=v2
    console.log(amount, order);
    let bodyData = new FormData();

    // we will pass the (amount) and product name to the backend using form data
    bodyData.append("amount", amount);
    bodyData.append("name", Number(4));
    // bodyData.append('userID','5')
    // bodyData.append('PlanID','12')
    const data = await Axios({
      // url: `${server}/razorpay/pay/`,
      url: localUrl + "payment/razorpay/pay/",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: bodyData,
    }).then((res) => {
      finalObjOnce = res.data.order;
      console.log(res.data.order);
      setOrder(res?.data?.order);
      return res;
    });
    // console.log(data)

    // in data we will receive an object from the backend with the information about the payment
    //that has been made by the user

    var options = {
      key_id: `** your razorpay public key id **`,
      key_secret: `** your razorpay secret key id **`,
      amount: data.data.payment.amount,
      currency: "INR",
      name: "procareer",
      description: "Test teansaction",
      image: "", // add image url
      order_id: data.data.payment.id,
      handler: function (response) {
        // we will handle success by calling handlePayment method and
        // will pass the response that we've got from razorpay
        handlePaymentSuccess(response, tempFinalObj);
      },
      prefill: {
        name: "User's name",
        email: "User's email",
        contact: "User's phone",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handleSubmit = (props) => {
    // console.log('Success:', values);

    // console.log("props",props)
    // let idValue = user1.userid ? user1.userid : navigate("/login");
    var formdata = new FormData();
    // let priceValue=formdata["price"]

    // console.log("pricing plan",props)
    // setName(idValue)
    // setAmount(priceValue)
    // let tempFinalObj={
    // }
    // tempFinalObj["plan"]=formdata
    // tempFinalObj["price"]=priceValue
    // tempFinalObj["user"]=idValue
    // setFinalObj(tempFinalObj)
    let outputValue = showRazorpay(props);

    console.log("outputValue", outputValue);
    // var myHeaders = new Headers();
    // myHeaders.append(
    //   "Cookie",
    //   "csrftoken=EmLuPRUNkf6K6gJITHLqCb44GCBa3XdZbwQ9z0697rglSv3GfLbtztOqBKdfAxaB"
    // );
    // myHeaders.append("Content-Type", "application/json");

    // console.log("type of the id", id, user1, typeof user1.userid);
    // // let idValue = user1.userid ? user1.userid : navigate("/login");

    // formdata["user"] = idValue.toString();

    // console.log("formdata", formdata);

    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: JSON.stringify(formdata),
    //   redirect: "follow",
    // };
    // console.log("from data", formdata);
    // fetch(localUrl + "adsapi/pricing", requestOptions)
    //   .then((response) => response.json())
    //   .then((result) => {

    //     console.log(result)
    //   })
    //   .catch((error) => console.log("error", error));
    // user1.setPricing(false);

    //  navigate("/dashboard/")
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <AnimationRevealPage>
        <main style={{ margin: "25px auto" }}>
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={() => handleSubmit(id)}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Full Name"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input
                required
                style={{
                  width: "50%",
                }}
              />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input
                required
                style={{
                  width: "50%",
                }}
              />
            </Form.Item>
            <Form.Item
              label="Plans"
              name="order"
              rules={[
                {
                  // required: true,
                  // message: '',
                },
              ]}
            >
              <Input
                style={{
                  width: "50%",
                }}
                name="order"
                value={order}
                placeholder={order}
                disabled
              />
            </Form.Item>
            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  // required: true,
                  // message: '',
                },
              ]}
            >
              <Input
                style={{
                  width: "50%",
                }}
                name="amount"
                value={amount}
                placeholder={amount}
                disabled
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="submit" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          {success === true ? (
            <span style={{ color: "green" }}>payment has done</span>
          ) : (
            <></>
          )}
        </main>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default Form1;
