import { localUrl } from "pages/env";

const { createSlice } = require("@reduxjs/toolkit");

const UserProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    data: [],
    status: true,
  },
  reducers: {
    profile: (state, { payload }) => {
      state.data = payload;
    },
    status: (state, { payload }) => {
      state.status = payload;
    },
  },
});
export const { profile, status } = UserProfileSlice.actions;
export default UserProfileSlice.reducer;

export const profileData = () => {
  // debugger;
  return async function getData(dispatch) {
    dispatch(status(true));
    if (localStorage.getItem("access_token") != null) {
      dispatch(status(true));
      var myHeaders = new Headers();
      myHeaders.append(
        "authorization",
        "Bearer " + localStorage.getItem("access_token")
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(localUrl + "user/profile/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          dispatch(profile(result));
          console.log(result, "re");
          dispatch(status(false));
        })
        .catch((error) => {
          dispatch(status(false));
          console.log("error", error);
        });
    }
  };
};
