import React from "react";
import tw from "twin.macro";
import { Button, Col, Popover, Row } from "antd";
import "antd/dist/antd.min.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import ScrollToTop from "ScrollToTop";

// import LogoImage from "images/logo.svg";
import { ReactComponent as FacebookIcon } from "images/facebook.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin.svg";
import { ReactComponent as InstagramIcon } from "images/instagram.svg";
// import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
// import Pro1 from '../../images/demo/Pro1.png';
import Footerlogo from "../FooterAddress/Footerlogo.js";
import { blueGrey } from "@mui/material/colors";
import "./FooterContainer.css";
import { textAlign } from "@mui/system";
import { isMobile } from "react-device-detect";
import Procareerlogo from "../../images/Procareerlogo.png";

const footmob = { margin: "auto", marginLeft: "40px" };
const footdesk = { margin: "auto" };
const content = (
  <div
    style={{
      width: 200,
      height: 50,
    }}
  >
    <p>In correct email </p>
  </div>
);
const PrimaryBackgroundContainer = tw.div`py-20 lg:py-10  bg-primary-500 rounded-lg relative -mb-8 -mx-12 px-10 py-20 lg:py-3`;
const Container = tw.div`relative  text-gray-900 -mb-1 -mx-2 px-1 py-4 lg:py-1  `;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FourColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-6 sm:px-0 sm:w-1/6 md:w-auto mt-12 `;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
// const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

// const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
// const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
// const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-800`;
// const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
// const Input = tw.input`bg-gray-600 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-red-600 hover:border-primary-600 focus:outline-none transition duration-300 w-full`;
// const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;

const Divider = tw.div`my-12 border-b-2 border-gray-300 w-full`;

const TwoColRow = tw.div`flex flex-col md:flex-row items-center justify-between `;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800 justify-start`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-800 justify-center`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-300 hover:bg-gray-600 transition duration-300 mr-3 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const marginTop = isMobile ? 0 : 0;
const mob = {
  fontSize: "14px",
};
const desk = {};

const mobstyle = { marginLeft: "-500px" };
const deskstyle = { width: "auto" };

export default () => {
  return (
    <div>
      <PrimaryBackgroundContainer>
        <Container>
          <Content>
            <FourColumns>
              <Column>
                <ColumnHeading style={{ color: "white" }}>Main</ColumnHeading>
                <LinkList>
                  {/* <LinkListItem>
                    <Link
                      title="FAQ"
                      to="/FaqFooterLink"
                      style={{ color: "white" }}
                    >
                      FAQs
                    </Link>
                  </LinkListItem> */}
                  {/* <LinkListItem>
                <Link to="#">Support</Link>
              </LinkListItem> */}
                  <LinkListItem>
                    <Link to="/about" style={{ color: "white" }}>
                      About Us
                    </Link>
                  </LinkListItem>
                  <LinkListItem>
                    <Link to="/TermsAndCond" style={{ color: "white" }}>
                      Terms & Conditions
                    </Link>
                  </LinkListItem>
                  <LinkListItem>
                    <Link to="/PrivacyPolicy" style={{ color: "white" }}>
                      Privacy Policy
                    </Link>
                  </LinkListItem>
                </LinkList>
              </Column>

              <Column>
                <ColumnHeading style={{ color: "white" }}>
                  Courses
                </ColumnHeading>
                <LinkList>
                  <LinkListItem>
                    <Link to="/Training" style={{ color: "white" }}>
                      {/* Training/Certifications */}
                      All Courses
                    </Link>
                  </LinkListItem>
                  <LinkListItem>
                    <Link
                      to="/trainingCertificates/7"
                      style={{ color: "white" }}
                    >
                      AWS devops
                    </Link>
                  </LinkListItem>
                  <LinkListItem>
                    <Link
                      to="/trainingCertificates/16"
                      style={{ color: "white" }}
                    >
                      SAP basis
                    </Link>
                  </LinkListItem>
                  <LinkListItem>
                    <Link
                      to="/trainingCertificates/1"
                      style={{ color: "white" }}
                    >
                      Frontend developer
                    </Link>
                  </LinkListItem>
                </LinkList>
              </Column>

              <Column>
                <ColumnHeading style={{ color: "white" }}>
                  Contact Us
                </ColumnHeading>
                <LinkList>
                  <LinkListItem>
                    <a
                      href="mailto: hrd@procareers.in"
                      style={{ color: "white" }}
                      target="_blank"
                    rel="noreferrer"
                    >
                      {/* <a href = "mailto: hrd@procareers.in">Send Email</a> */}
                      Email ID: hrd@procareers.in
                    </a>
                  </LinkListItem>
                  <br />
                  <div style={{ textAlign: "center" }}>
                    <SocialLinksContainer style={isMobile ? footmob : footdesk}>
                      <SocialLink href="https://www.facebook.com/people/Procareers/100087744926174/">
                        <FacebookIcon />
                      </SocialLink>
                      <SocialLink href="https://www.linkedin.com/in/procareers-infotech-solutions-54567124b/">
                        <LinkedInIcon />
                      </SocialLink>
                      <SocialLink href="https://www.instagram.com/procareers_infotech_solutions/">
                        <InstagramIcon />
                      </SocialLink>
                    </SocialLinksContainer>
                  </div>
                </LinkList>
              </Column>

              <Column>
                <ColumnHeading
                  className="text-white text-center"
                  style={{ color: "white", marginLeft: "5px" }}
                >
                  Procareers Infotech Solutions
                </ColumnHeading>
                <div className="row mt-3">
                  <div className="col-6">
                    {" "}
                    <a title="Logo" href="/">
                      <img
                      alt="img"
                        style={{
                          borderRadius: "50%",
                          height: "110px",
                          width: "150",
                          marginLeft: "5px",
                        }}
                        src={Procareerlogo}
                      />
                    </a>
                  </div>
                  <div className="col-6">
                    {" "}
                    <p
                      className="footer__top__info__desc"
                      style={{
                        color: "white",
                        // marginLeft: "5px",
                        fontSize:"70%"
                      }}
                    >
                      <ins>Address Information</ins>
                      <br/>
                      #12 Second Floor 3rd Cross <br />
              Patel Narayana Reddy Layout
              <br />
              6th Block Koramangala
              <br />
              Bengaluru - 560095
              <br></br>
                    </p>
                  </div>
                </div>
                {/* <ColumnHeading style={{ color: "white" }}>
                  Address
                </ColumnHeading> */}
                {/* <LinkList>
                  <LinkListItem>
                    <Row>
                      <Col>
                        <a title="Logo" href="#">
                          <img
                            style={{
                              borderRadius: "50%",
                              height: "110px",
                              width: "150",
                              // marginRight: "10px",
                            }}
                            src={Procareerlogo}
                          />
                        </a>
                      </Col>
                      <Col>
                        {" "}
                        <p
                          className="footer__top__info__desc"
                          style={{
                            color: "white",
                            // marginLeft: "5px",
                          }}
                        >
                          #12 Second Floor 3rd Cross <br />
                          Patel Narayana Reddy Layout
                          <br />
                          6th Block Koramangala
                          <br />
                          Bengaluru -560095
                        </p>
                      </Col>
                    </Row>
                  </LinkListItem>
                </LinkList> */}
              </Column>
            </FourColumns>
            <hr className="text-white" />
            <div className="row" style={{ textAlign: "center" }}>
              <div onClick={"ScrollToTop"}></div>
              <div style={{ color: "white" }}>
                &copy; 2022 Procareers Infotech Solutions, All Rights Reserved.
              </div>
            </div>
          </Content>
        </Container>
      </PrimaryBackgroundContainer>
    </div>
  );
};

//how to make pagination code?
