import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Alert, Space, DatePicker, Button, Form, Input, Select } from "antd";
import CircleIcon from "@mui/icons-material/Circle";
import Toast from "react-bootstrap/Toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { localUrl } from "pages/env";
import { useDispatch } from "react-redux";
import { profileData } from "store/slices/userProfile";
import moment from "moment";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const CareerForm = ({ jobs, onSucess }) => {
  const mobileStyle = {
    width: "100%",
    padding: "10px",
    justifyContent: "center",
    backgroundColor: "white",
  };
  const desktopStyle = {};

  const mobileStyle1 = { backgroundColor: "white" };
  const desktopStyle1 = {
    borderLeft: "1px solid green",
    height: "450px",
  };
  const mob = {
    width: "100%",
  };
  const desk = {
    marginLeft: "90px",
  };
  const mob1 = {
    height: "auto",
  };
  const desk1 = {};
  const mobdes = {
    height: "80px",
    overflowX: "hidden",
    overflowY: "auto",
  };
  const deskdes = {
    height: "150px",
    overflowX: "hidden",
    overflowY: "auto",
  };
  console.log(jobs, "oo");
  const [filePath, setFilePath] = useState(null);
  const [fileObj, setFileObj] = useState(null);
  // const [userid, setUserId] = useState();

  useEffect(() => {}, [setFilePath]);

  // const dispatch = useDispatch();
  // dispatch(profileData());

  const count = useSelector((state) => state.userProfile);
  console.log(count, "count1");
  const [form] = Form.useForm();
  const onFinish = (values) => {
    // dispatch(profileData());
    var dddd = JSON.stringify(new Date(values.DOB._d)).slice(1, 11);
    var formdata = new FormData();
    formdata.append("name", values.name);
    formdata.append("phone", values.phone);
    formdata.append("email", values.email);
    formdata.append("gender", values.gender);
    formdata.append("Date_of_Birth", dddd);
    formdata.append("title", jobs.title);
    formdata.append("Introduction", values.Introduction);
    formdata.append("filename", fileObj);
    formdata.append("jobId", jobs?.id);
    formdata.append("user", count?.data?.id);
    formdata.append("Qualification", values.Qualification);
    formdata.append("fresher", values.fresher);
    formdata.append("LookingFor", values.LookingFor);
    formdata.append("skills", values.skills);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    // var Email = document.getElementById("Message").value;
    // var mailformat =
    //   /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    // if (!Email.match(mailformat)) {
    //   alert("please provide a valid Email address");
    //   document.getElementById("Message").focus();
    //   return false;
    // }

    // var a = document.Email.value;
    // if (a.indexOf("@") <= 0) {
    //   document.getElementById("Message").innerHTML = "**invalid Email";
    //   return false;
    // }
    // if (a.charAt(a.length - 4) != "." && a.charAt(a.length - 3) != ".") {
    //   document.getElementById("Message").innerHTML = "**invalid Email";
    //   return false;
    // }
    // fetch(
    //   "https://procareers.pythonanywhere.com/api/user/jobDetails",
    //   requestOptions
    // )
    fetch(localUrl + "jobs/jobDetails", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // setUserId(result.id);
        console.log("RESULT", result);
        console.log("jdklshfdhf");
        if (result === "Sucess") {
          toast("Applied Successfully!..");
          setTimeout(() => onSucess(false), 3000);
          setFileObj("");
        } else {
          toast("Invailed Email please choose current Email");
        }
      })
      .catch((error) => console.log("error", error));

    form.resetFields();
    setFileObj(null);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function disabledDate(current) {
    // Disable dates after today's date
    return current && current > moment().endOf("day");
  }

  return (
    <div className="row" style={isMobile ? mob1 : desk1}>
      <div className="col-lg-5">
        <h3>{jobs.Job_Type}</h3>
        <p>
          No. of Openings:
          <b>{jobs.id}</b>
        </p>
        <p className="fs-18 font-weight-bold ">
          Starting CTC: As per industry standard's
        </p>
        <p className="fs-14">
          <CircleIcon style={{ fontSize: "10px" }} /> <b>Job Responsibility </b>
          : {/* {jobs.Education} */}
          <br />
          <CircleIcon style={{ fontSize: "10px" }} /> <b>Technical Skills </b>:{" "}
          {/* {jobs.fields.technical_skills} */}
          <br />
          <CircleIcon style={{ fontSize: "10px" }} /> <b>Qualification </b>:{" "}
          {jobs.Education}
        </p>
        <b> Description</b>
        <p className="fs-14">{jobs.Job_Description}</p>
      </div>
      <div className="col-lg-7" style={isMobile ? mobileStyle1 : desktopStyle1}>
        {" "}
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          scrollToFirstError
          style={isMobile ? mobileStyle : desktopStyle}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ remember: true }}
        >
          {" "}
          <Form.Item
            name="job id"
            label="Job Id"
            rules={[
              {
                type: "text",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input disabled placeholder={jobs?.id} />
          </Form.Item>
          <Form.Item
            name="name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
                whitespace: true,
              },
              {
                pattern: new RegExp(/^[A-Za-z\s]*$/),
                message: "field does not accept numbers",
              },
            ]}
          >
            {/* <Input /> */}
            <Input placeholder="Enter your Name" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
              },
              {
                pattern: new RegExp("^[0-9]*$"),
                message: "Wrong format!",
              },
            ]}
          >
            <Input
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              minLength={10}
              maxLength={10}
              addonBefore="+91"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          {/* <Form.Item
            name="Email"
            label="Email"
            value=""
            id="Message"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input placeholder="Enter Your Email" />
          </Form.Item> */}
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please input your email!" },
              {
                pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]{1,1}$/,
                message: "Please enter a valid email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Qualification"
            label="Qualification"
            className="mb-3"
            rules={[
              {
                required: true,
                message: "Please input your Qualification",
              },
            ]}
          >
            <Select className="h-30" placeholder="Select an option " allowClear>
              <Option value="10th">10th</Option>
              <Option value="12th/intermediate">12th/intermediate</Option>
              <Option value="Degree">Degree</Option>
              <Option value="BE/Btech">BE/Btech</Option>
              <Option value="ME/Mtech">ME/Mtech</Option>
              <Option value="MBA">MBA</Option>
              <Option value="MCA">MCA</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="DOB"
            label="DOB"
            rules={[{ required: true, message: "Please select DOB" }]}
          >
            <DatePicker disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item
            name="fresher"
            label="Fresher / Experienced"
            rules={[{ required: true }]}
            className=" mb-3"
          >
            <Select className="h-30" placeholder="Select an option " allowClear>
              <Option value="Fresher">Fresher</Option>
              <Option value="Experience">Experienced</Option>
            </Select>
          </Form.Item>
          <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
            <Select
              placeholder="Select a option and change input text above"
              allowClear
            >
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.gender !== currentValues.gender
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("gender") === "other" ? (
                <Form.Item
                  name="customizeGender"
                  label="Customize Gender"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              ) : null
            }
          </Form.Item> */}
          <Form.Item
            name="skills"
            label="Your Skills"
            rules={[{ required: true }]}
            className="mb-3"
          >
            <Input.TextArea className="form-control" />
          </Form.Item>
          <Form.Item
            name="LookingFor"
            label="Looking For"
            rules={[{ required: true }]}
            className=" mb-3"
          >
            <Select className="h-30" placeholder="Select an option " allowClear>
              <Option value="job">Job</Option>
              <Option value="internship">Internship</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="Introduction"
            label="Tell Us Something"
            rules={[
              {
                required: true,
                message: "Enter Message",
              },
            ]}
          >
            <Input.TextArea
              className="form-control"
              maxLength={100}
              placeholder="Enter Message Here"
            />
          </Form.Item>
          <input
            required
            style={isMobile ? mob : desk}
            className="mb-3"
            type="file"
            // key={fileObj || ""}
            accept=".pdf"
            onChange={(e) => {
              setFileObj(e.target?.files[0]);
              setFilePath(e.target.value);
              console.log("change", e.target?.files[0].name, e.target.value);
            }}
          />
          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                color: "white",
                backgroundColor: "rgba(79, 0, 135, 1)",
                marginTop: "10px",
                borderRadius: "8px",
              }}
            >
              Submit
            </Button>{" "}
          </Form.Item>
        </Form>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          style={{ marginTop: "70px" }}
        />
      </div>
    </div>
  );
};
export default CareerForm;
