import Light from "components/headers/light";
import React from "react";
import Footer from "components/footers/FiveColumnWithInputForm";
import { useEffect, useState } from "react";
import "./memberPricing.css";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import { useParams } from "react-router-dom";




const MemberPricing = () => {
  const[pro,setpro]=useState({

  })
  const {id}= useParams()
  useEffect(() => {
  var formdata = new FormData();

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("https://procareers.pythonanywhere.com/api/jobs/trainingCourseForm", requestOptions)
  .then(response => response.json())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
}, []);
  return (
    <>
      <div>
        <AnimationRevealPage>
          <div className="my-4"></div>
          <div className="my-2">
            <form method="POST" action="/en/memberplan/">
              <input
                type="hidden"
                name="csrfmiddlewaretoken"
                defaultValue="gNVpc2XI60yajuYmsLecEZHEJVmzQN7cD8gqM46fhUX9RP2JPXx8Pi9OUJ1eNAPU"
              />
              <div
                className="col-lg-6 col-md-6 col-sm-12"
                style={{
                  border: "1px solid rgba(128, 128, 128, 0.322)",
                  boxShadow: "10px 10px 10px #88888860",
                  borderRadius: "10px",
                  height: "auto",
                }}
              >
                <h4 style={{ marginLeft: "30px" }}>Billing Details</h4>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div
                    className="col-lg-6 col-md-6 col-sm-12"
                    style={{ marginTop: "20px" }}
                  >
                    <label htmlFor="fname"> First Name</label>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      style={{ width: "100%", padding: "5px" }}
                    />
                  </div>
                  <div
                    className="col-lg-6 col-md-6 col-sm-12"
                    style={{ marginTop: "20px" }}
                  >
                    <label htmlFor="lname" style={{}}>
                      {" "}
                      last Name
                    </label>
                    <input
                      type="text"
                      id="lname"
                      name="lname"
                      style={{ width: "100%", padding: "5px" }}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="cname" style={{ marginTop: "20px" }}>
                      {" "}
                      Company Name
                    </label>
                    <input
                      type="text"
                      id="cname"
                      name="cname"
                      style={{ width: "100%", padding: "5px" }}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="cars" style={{ marginTop: "20px" }}>
                      Country/Reigon:
                    </label>
                    <br />
                    <select
                      name="cars"
                      id="cars"
                      style={{ width: "100%", padding: "5px" }}
                    >
                      <option value="volvo">Volvo</option>
                      <option value="saab">Saab</option>
                      <option value="opel">Opel</option>
                      <option value="audi">Audi</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-12 col-md-6 col-sm-12">
                    <label htmlFor="street" style={{ marginTop: "20px" }}>
                      {" "}
                      Street address*
                    </label>
                    <input
                      type="text"
                      id="streed"
                      name="streed"
                      placeholder="House number and street name"
                      style={{ width: "100%", padding: "5px" }}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label htmlFor="city" style={{ marginTop: "20px" }}>
                      <i className="fa fa-institution" /> Town/City
                    </label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      style={{ width: "100%", padding: "5px" }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label htmlFor="cars" style={{ marginTop: "20px" }}>
                      State:
                    </label>
                    <br />
                    <select
                      name="state"
                      id="cars"
                      style={{ width: "100%", padding: "5px" }}
                    >
                      <option value="volvo">Volvo</option>
                      <option value="saab">Saab</option>
                      <option value="opel">Opel</option>
                      <option value="audi">Audi</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label htmlFor="zip" style={{ marginTop: "20px" }}>
                      Pin
                    </label>
                    <br />
                    <input
                      type="text"
                      id="zip"
                      name="pin"
                      placeholder={10001}
                      style={{ width: "100%", padding: "5px" }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label htmlFor="phone" style={{ marginTop: "20px" }}>
                      Phone
                    </label>
                    <br />
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      style={{ width: "100%", padding: "5px" }}
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label htmlFor="email" style={{ marginTop: "20px" }}>
                      {" "}
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="john@example.com"
                      style={{
                        width: "100%",
                        padding: "5px",
                        marginBottom: "20px",
                      }}
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <h3 style={{ marginTop: "20px" }}>
                      Additional Information
                    </h3>
                    <br />
                    <h5 style={{ marginBottom: "10px" }}>
                      Order notes (optional)
                    </h5>
                    <textarea
                      id="w3review"
                      name="w3review"
                      rows={6}
                      cols={50}
                      style={{
                        width: "100%",
                        borderColor: "#248ae4",
                        borderRadius: "10px",
                      }}
                      defaultValue={"      \n      "}
                    />
                    <input type="checkbox" />
                    <label>
                      Yes, i'm ok with you sending me additional newsletter and
                      email content (optional){" "}
                    </label>
                    <br />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-4 col-sm-12"
                style={{ height: "auto" }}
              >
                <div
                  className=" col-lg-12 col-md-12 col-sm-12 "
                  style={{
                    boxShadow: "10px 10px 10px #88888860",
                    border: "1px solid rgba(128, 128, 128, 0)",
                    marginBottom: "60px",
                    margin: "auto",
                    height: "200px",
                    backgroundColor: "rgba(128, 128, 128, 0.274)",
                  }}
                >
                  <div
                    className="text-center"
                    style={{ color: "#248ae4", fontSize: "20px" }}
                  >
                    Your Order
                    <hr />
                    <ul className="list-group list-group-flush">
                      <li
                        className="list-group-item"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 style={{ color: "black" }}>Product</h4>
                        <h5> Subtotal </h5>
                      </li>
                      <li
                        className="list-group-item"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 style={{ color: "black" }}>Subtotal </h4>
                        <h5> 500</h5>
                      </li>
                      <li
                        className="list-group-item"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4 style={{ color: "black" }}>Total </h4>
                        <input
                          type="number"
                          defaultValue={500}
                          name="amount"
                          hidden
                        />
                        <h5> 500</h5>
                      </li>
                      {/* <li class="list-group-item">
              <button style="border:1px solid #248ae4; background-color:#248ae4;
              color:white; width:100%; border-radius:10px;">Proceed</button>
          </li> */}
                    </ul>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 col-sm-12"
                  style={{
                    border: "1px solid rgba(128, 128, 128, 0.315)",
                    marginTop: "50px",
                    padding: "5px",
                    borderRadius: "10px",
                    boxShadow: "10px 10px 10px #88888860",
                    backgroundColor: "#248ae413",
                  }}
                >
                  <h4>Pay with UPI QR code</h4>
                  <ul
                    style={{
                      display: "flex",
                      listStyleType: "none",
                      justifyContent: "space-between",
                      marginTop: "30px",
                      marginBottom: "20px",
                    }}
                  >
                    <li> bhim</li>
                    <li> phonepay</li>
                    <li> googlepay</li>
                    <li> paytm</li>
                  </ul>
                  <p style={{ padding: "20px" }}>
                    It uses UPI apps like BHIM, Paytm, Google Pay, PhonePe or
                    any Banking UPI app to make payment.
                  </p>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label htmlFor="upi" style={{ marginTop: "20px" }}>
                        UPI address*
                      </label>
                      <br />
                      <input
                        type="text"
                        id="upi"
                        name="upi"
                        style={{
                          width: "100%",
                          padding: "3px",
                          borderRadius: "10px",
                          borderColor: "#248ae4",
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label htmlFor="cars" style={{ marginTop: "20px" }}>
                        UPI Handle
                      </label>
                      <br />
                      <select
                        name="cars"
                        id="cars"
                        style={{
                          width: "100%",
                          padding: "5px",
                          borderRadius: "10px",
                          borderColor: "#248ae4",
                        }}
                      >
                        <option value="volvo" />
                        <option value="saab">@airtel</option>
                        <option value="opel">Opel</option>
                        <option value="audi">Audi</option>
                      </select>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    style={{ marginTop: "30px", padding: "20px" }}
                  />
                  <label>
                    I would like to receive exclusive emails with discounts and
                    product information (optional){" "}
                  </label>
                  <p
                    style={{
                      marginTop: "30px",
                      padding: "20px",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Your personal data will be used to process your order,
                    support your experience throughout this website, and for
                    other purposes described in our{" "}
                    <span style={{ color: "#248ae4" }}>privacy policy.</span>
                  </p>
                  <button
                    tybe="submit"
                    style={{
                      border: "1px solid #248ae4",
                      backgroundColor: "#248ae4",
                      color: "white",
                      fontSize: "20px",
                      padding: "5px",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  >
                    Proceed to payment
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div></div>
          {/*      
      
      <Footer /> */}
        </AnimationRevealPage>
      </div>
    </>
  );
};
export default MemberPricing;
