import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import FactoryIcon from "@mui/icons-material/Factory";
import SchoolIcon from "@mui/icons-material/School";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Modal } from "antd";
import CareerForm from "components/CareerPage/CareerForm";
import { localUrl, localUrlImage } from "pages/env";
import { Spin } from "antd";

const AppliedJobs = (props) => {
  const [jobsuser, setJobseUser] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [id, setId] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    var formdata = new FormData();
    formdata.append("user", props.props);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let userData = [];
    console.log(userData, "useradta");
    setLoader(true);
    fetch(localUrl + "jobs/applieduser", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoader(true);
        console.log(result, "result");
        console.log(JSON.parse(result), "job resguster");

        JSON.parse(result).jobregister?.map((result) => {
          console.log("inside", result, JSON.parse(result.jobData));
          userData.push(JSON.parse(result?.jobData));
          console.log(userData, "useradta");
          setJobseUser(userData.flat(Infinity));

          console.log(JSON.parse(result.jobData), "lll");
        });
        setLoader(false);
        // setJobseUser(JSON.parse(result.jobregister.jobData));
        // setJobseUser(JSON.parse(result).jobregister);
      })
      .catch((error) => console.log("error", error));

    console.log(userData, "useradta");
  }, []);
  console.log(jobsuser, "gg");
  const onSucess = (p) => {
    setOpen(p);
  };
  return (
    <>
      <div className="row">
        {/* {jobsuser?.map((user) => ( */}
        {/* <div> */}{" "}
        <p
          className="text-center font-bold text-decoration-underline"
          style={{ fontSize: "22px" }}
        >
          MY APPLIEDS
        </p>
        {loader ? (
          <div style={{ textAlign: "center", marginTop: "100px" }}>
            {" "}
            <Spin />
          </div>
        ) : !jobsuser?.length ? (
          <div className="row text-center">
            <div className="col-lg-2"></div>
            <div className="col-lg-7 border rounded p-3">
              <p style={{ fontSize: "20px" }} className="mt-3">
                No Jobs Applied
              </p>
            </div>
          </div>
        ) : (
          <div>
            {jobsuser?.map((user) => (
              <div className="col-md-6 col-sm-6 mb-3 mt-4">
                <div className="course-wrapper-2 ">
                  <Card
                    sx={{
                      width: "100%",
                      height: "400px",
                      margin: "-10px",
                      backgroundColor: "rgba(197, 235, 235, 1)",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ height: 200 }}
                      image={
                        localUrlImage + "/adsapi/media/" + user?.fields?.image
                      }
                      title="green iguana"
                      style={{
                        maxWidth: "100%",
                        height: "200px",
                        objectFit: "fill",
                      }}
                    />

                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography gutterBottom variant="h5" component="div">
                          {user?.fields?.title.slice(0, 30)}
                        </Typography>
                      </div>

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            // maxWidth: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginRight: "10px",
                            }}
                          >
                            <SchoolIcon style={{ fontSize: "15px" }} />
                            <p
                              variant="body2"
                              color="text.secondary"
                              style={{
                                fontSize: "15px ",
                                marginLeft: "4px",
                              }}
                            >
                              {user?.fields?.Education}
                            </p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginRight: "10px",
                            }}
                          >
                            <PrecisionManufacturingIcon
                              style={{
                                fontSize: "15px",
                              }}
                            />
                            <p
                              variant="body2"
                              color="text.secondary"
                              style={{
                                fontSize: "15px ",
                                marginLeft: "4px",
                              }}
                            >
                              {user?.fields?.Experience}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // maxWidth: "100%",
                        }}
                      >
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            marginRight: "10px",
                          }}
                        >
                          <LocationOnIcon style={{ fontSize: "15px" }} />
                          <p
                            variant="body2"
                            color="text.secondary"
                            style={{
                              fontSize: "15px ",
                              marginLeft: "4px",
                            }}
                          >
                            {user?.fields?.Job_Location}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginRight: "10px",
                          }}
                        >
                          <FactoryIcon style={{ fontSize: "15px" }} />
                          <p
                            variant="body2"
                            color="text.secondary"
                            style={{
                              fontSize: "15px ",
                              marginLeft: "4px",
                            }}
                          >
                            {user?.fields?.Industry}
                          </p>
                        </div>
                      </div>
                    </CardContent>  

                    <CardActions>
                      <button
                        type="submit"
                        className="mb-2"
                        style={{
                          width: "100%",
                          marginTop: "-30px",
                          color: "white",
                          backgroundColor: "rgba(144, 107, 255, 1)",
                          borderRadius: "4px",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setOpen(true);
                          setId(jobsuser);
                        }}
                      >
                        Apply Now
                      </button>
                      <div className="mt-5">
                        <Modal
                          title="Apply For The Position"
                          style={{ top: 70 }}
                          centered
                          open={open}
                          onOk={() => setOpen(false)}
                          onCancel={() => setOpen(false)}
                          width={1020}
                          footer={false}
                          bodyStyle={{ height: 840 }}
                        >
                          <CareerForm jobs={id} onSucess={onSucess} />
                        </Modal>
                      </div>
                    </CardActions>
                  </Card>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default AppliedJobs;
