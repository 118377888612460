import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import MainFeature3 from "components/features/Catagery";
import MainFeature4 from "components/features/certification";
import MainFeature5 from "components/features/placement";
import MainFeature6 from "components/features/Colomntraining";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Pricing from "components/pricing/Pricing2_Three_Plans";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/Faq.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithInputForm";
import { useNavigate } from "react-router-dom";
// import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
// import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
// import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import SpinnerCenter from "./SpinnerCenter";
import { useState } from "react";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Star } from "../components/hero/Star";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PorcareersFont from "../images/PorcareersFont.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";
import { CardActionArea, Typography } from "@mui/material";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import { localUrl } from "pages/env";
import "./Saas.css";
import Wegrow from "../images/Wegrow.png";
import Certificateimg from "../images/Certificateimg.jpg";

import TrustedBy from "./TrustedBy";
import EducationTrust from "./EducationTrust";
import FindMillion from "./FindMillion";
import WhyWeAreTheBest from "./WhyWeAreTheBest";
const marginTop = isMobile ? 70 : 0;
const Address = tw.span`leading-relaxed `;
const AddressLine = tw.span`block text-blue-500`;
const Email = tw.span`text-sm mt-6 block text-black font-bold`;
const Phone = tw.span`text-sm mt-0 block text-black font-bold`;

export default () => {
  const [active, setActive] = useState("all-jobs");
  const [toggle, setToggle] = useState(false);
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-600`;
  const HighlightedText = tw.span`text-primary-500`;
  const [run, setRun] = useState(true);
  // const marginLeft = isMobile? 0:2;
  const mobcrd = {};
  const deskcrd = { marginTop: "20px" };
  const mobtx = { marginBottom: "10px", marginLeft: "-20px" };
  const desktx = { marginBottom: "20px" };
  const padmob = { margin: "10px" };
  const paddesk = {};
  const mob = {
    fontSize: "14px",
  };
  const desk = {};
  const plcmobcrd = {};
  const plcdeskcrd = { marginBottom: "15px" };
  const cardmob = { padding: "20px" };
  const carddesk = {};
  const mobstyle = { marginLeft: "20px" };
  const deskstyle = { margin: "20px" };
  const mobstyle1 = { marginTop: "10px" };
  const deskstyle1 = {
    marginLeft: "30px",
    marginTop: "10px",
    marginBottom: "10px",
  };
  const mobpro = {
    display: "flex",
    fontSize: "16px",
    marginBottom: "20px",
    justifyContent: "center",
  };
  const deskpro = {};
  const textmob = {
    textAlign: "center",
  };
  const textdesk = {};
  useEffect(() => {
    // setRun(true);
    const timer = setTimeout(() => {
      setRun(false);
    }, 1000);
    return () => clearTimeout(timer);
    // setTimeout(() => {
    //   console.log("inside timeout");
    //   setRun(false);
    // }, 1000);
  });
  const [pro, setPro] = useState([{}]);

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(localUrl + "jobs", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPro(result);

        console.log(result, "res");
      })
      .catch((error) => console.log("error", error));
  }, []);

  console.log(pro, "pr");
  const [isShown, setIsShown] = useState(false);

  const navigate = useNavigate();

  // setTimeout(() => {
  //   console.log("inside timeout after useEffect");
  //   setRun(false);
  // }, 1000);

  return (
    <>
      {run ? (
        <SpinnerCenter />
      ) : (
        <AnimationRevealPage>
          <Hero roundedHeaderButton={true} />
          {/* <Features
            subheading={<Subheading>Features</Subheading>}
            heading={
              <>
                We have Amazing <HighlightedText>Service.</HighlightedText>
              </>
            }
          /> */}

          <WhyWeAreTheBest />

          <div
            style={{
              alignContent: "flex-start",
              marginBottom: "-25px",
            }}
          >
            <FindMillion />
            {/* <MainFeature
              subheading={<Subheading>Quality Work</Subheading>}
              imageSrc="https://cdn.dribbble.com/users/3848091/screenshots/7827479/media/5f226eb6aa71d3b639a73d214e716db6.gif"
              imageBorder={true}
              imageDecoratorBlob={true}
            /> */}
          </div>
          <div style={isMobile ? padmob : paddesk}>
            {/* <FeatureWithSteps
              subheading={<Subheading>STEPS</Subheading>}
              heading={
                <>
                  Easy to <HighlightedText>Get Started.</HighlightedText>
                </>
              }
              textOnLeft={false}
              imageSrc="https://media.tenor.com/WthWmIXI24cAAAAd/work-working.gif"
              imageDecoratorBlob={true}
              decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
            /> */}
          </div>
          <div style={isMobile ? mobtx : desktx}>
            <TrustedBy />
            {/* <MainFeature2
              subheading={<Subheading>VALUES</Subheading>}
              heading={
                <>
                  We are Trusted by Popular{" "}
                  <HighlightedText>100+ Company.</HighlightedText>
                </>
              }
              imageSrc={Wegrow}
              showDecoratorBlob={false}
              features={[
                {
                  Icon: MoneyIcon,
                  title: "Affordable",
                  description:
                    "We promise to offer you the best rate we can - at par with the industry standard.",
                  iconContainerCss: tw`bg-green-300 text-green-800`,
                },
                {
                  Icon: BriefcaseIcon,
                  title: "Professionalism",
                  description:
                    "We assure you that our templates are designed and created by professional designers.",
                  iconContainerCss: tw`bg-red-300 text-red-800`,
                },
              ]}
            /> */}

            <MainFeature3
              subheading={<Subheading></Subheading>}
              imageSrc="https://www.logicapt.com/wp-content/uploads/2022/03/Preparation.gif"
              imageBorder={true}
              imageDecoratorBlob={true}
            ></MainFeature3>

            <MainFeature6
              subheading={<Subheading></Subheading>}
              imageSrc="http://www.tamr.com/wp-content/uploads/2018/12/Internship.gif"
              imageBorder={true}
              imageDecoratorBlob={true}
            />
            <MainFeature5
              subheading={<Subheading></Subheading>}
              imageSrc="https://cdn.dribbble.com/users/1998175/screenshots/6239769/dribbble.gif"
              imageBorder={true}
              imageDecoratorBlob={true}
            />
          </div>
          {/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable &<HighlightedText> Flexible Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "₹399.99",
            duration: "Monthly",
            mainFeature: "One Job at a time",
            features: ["Active Job Postings 1", "Resume Views 50", "Performance Boost", "Basic Assistance"]
          },
          {
            name: "Business",
            price: "₹599.99",
            duration: "Monthly",
            mainFeature: "For small expanding teams",
            features: ["Active Job Postings 3", "Resume Views 150", "Performance Boost (Training)", "Priority Assistance"],
            featured: true
          },
          {
            name: "Enterprise",
            price: "₹799.99",
            duration: "Monthly",
            mainFeature: "For rapidly growing teams",
            features: ["Active Job Postings 5", "Resume Views 250", "Performance Boost (Personal training)", "Personal Assistance"]
          }
        ]}
      /> */}
          {/* <Testimonial
            subheading={<Subheading>Testimonials</Subheading>}
            heading={
              <>
                Talk to <HighlightedText> Us.</HighlightedText>
              </>
            }
            testimonials={[
              {
                stars: 5,
                profileImageSrc:
                  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
                heading: "Amazing User Experience",
                quote:
                  "It was definitely very very helpful and really great to have this site that makes it easier to hire candidates. Plus support given to the postings of job offers is really kind. Anytime WorkIndia is much better than any other app. Great to have you guys.",
                customerName: "Ruhi Singh",
                customerTitle: "Director, Delos Inc.",
              },
              {
                stars: 5,
                profileImageSrc:
                  "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
                heading:
                  "Love the Developer Experience and Design Principles !",
                quote:
                  "Procarrers has been a great help in fulfilling the recruitment needs of my organization. The WorkIndia app is a perfect example of latest technology in action, designed simplistically, to attract candidates catering to my organizations needs right from age / location / education to even other skills like Driving, Computer knowledge, etc.",
                customerName: "Rahul Thakur",
                customerTitle: "Founder, EventsNYC",
              },
            ]}
          /> */}
          <EducationTrust />
          {/* <div>
            <Star />
          </div> */}

          {/* <FAQ
            subheading={<Subheading>FAQS</Subheading>}
            heading={
              <>
                You have <HighlightedText>Questions ?</HighlightedText>
              </>
            }
            faqs={[
              {
                question: "What are procarrer career services?",
                answer:
                  "Career Services are paid services offered by procareer specially crafted for job seekers. It helps candidates with their Resume writing, highlighting profiles in our database and making them job-ready through Mock Interviews!",
              },
              {
                question:
                  "What is the Surety that I'll get interviews or a job with these services?",
                answer:
                  "We highlight you profile on procareer to our recruiters and ensure you get maximum visibility. We request you to understand that interview calls also depends on how active you are on procareer and the key skills in your profile matching with the recruiter job requirements.",
              },
              {
                question:
                  "After availing the services also, what if I don't get an interview hob? Will you refund any money?",
                answer:
                  "We provide you all our premium benefits to get maximum exposure and early access to relevant opportunities, and then we request you to apply to them on a timely basis and follow it up with the recruiter for maximum conversion.",
              },
              {
                question:
                  "Why should I purchase the paid services from procareer.com only, why not from other job portals",
                answer:
                  "Sir, though most job portals are providing paid assistance, but procareer has a wider range of services to offer. Secondly, procareer.com is India's leading job portal with 75% traffic share and as per our recent survey procareer.com have become APAC & Gulf s Most Trusted Brand from the past 2 decades.",
              },
              {
                question:
                  "When will I start getting calls/opportunities after availing these services?",
                answer:
                  "We highlight your profile to recruiters as soon as the services got activated. Then it depends on the recruiters when they contact you. It can be possible that you start getting opportunities immediately.",
              },
              {
                question:
                  "Competition is offering the same set of services at a lesser amount. Why should I take the services from procareer.com?",
                answer:
                  "not all services offered by us is also offered by competition. Also, as compared to any other job portal in India, procareer.com always has a greater number of opportunities to offer giving you a bigger choice base to choose from",
              },
            ]}
          />
          <GetStarted />
          <Footer /> */}
          {/* <div style={{ marginTop: `${marginTop}px` }}>
            <ContactUsForm />
          </div> */}
          {/* <style>
  @media (max-width: 767px) {
    ContactUsForm {
      display: flex;
      flex-direction: column;
    }
  }
</style> */}
          {/* <ContactDetails
            cards={[
              
              {
                title: "Bangalore",
                description: (
                  <>
                     <img style={{height:"100px" , width:"400%"}}src={PorcareersFont} />
                    <Address>
                      <AddressLine>
                        #12 Second Floor 3rd Cross <br />
                        Patel Narayana Reddy Layout
                        <br />
                        6th Block Koramangala
                        <br />
                        Bengaluru -560095
                      </AddressLine>
                    </Address>
                    <Email>hrd@procareers.in</Email>
                    <Phone>+91 9606910681</Phone>
                  </>
                ),
              },
            ]}
          /> */}
          {/* <GetStarted /> */}
          {/* <div className="row mb-2 m-1 border rounded">
            <h2 className="text-center py-4 text-2xl">
              <span tw="text-primary-500" style={{ fontSize: "30px" }}>
                Trainings
              </span>
            </h2>
            <div className="col-lg-3 ">
              <h2 style={isMobile ? textmob : textdesk}>
                <span tw="text-primary-500" style={{ fontSize: "30px" }}>
                  Category
                </span>
              </h2>
              <div style={isMobile ? mobpro : deskpro}>
                <button
                  onClick={() => {
                    if (isMobile) {
                      setActive("all-jobs");
                      setToggle(!toggle);
                    } else {
                      setActive("all-jobs");
                    }
                  }}
                  className=" mx-1 active d-flex border-bottom"
                  style={
                    active === "all-jobs"
                      ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "" }
                  }
                >
                  <b style={isMobile ? mobstyle1 : deskstyle1}>
                    {" "}
                    All Trainings{" "}
                  </b>
                </button>
                <p className=" d-lg-none hidden-md visible-xs-block visible-sm-block"></p>
              </div>
              <div style={isMobile ? mobpro : deskpro}>
                <button
                  onClick={() => {
                    if (isMobile) {
                      setActive("full-stack-developer");
                      setToggle(!toggle);
                    } else {
                      setActive("full-stack-developer");
                    }
                  }}
                  className=" mx-1 active d-flex border-bottom"
                  style={
                    active === "full-stack-developer"
                      ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "" }
                  }
                >
                  <b style={isMobile ? mobstyle : deskstyle}>
                    {" "}
                    Full Stack Developer{" "}
                  </b>
                </button>
                <button
                  onClick={() => {
                    if (isMobile) {
                      setActive("backend-developer");
                      setToggle(!toggle);
                    } else {
                      setActive("backend-developer");
                    }
                  }}
                  className="active  d-flex border-bottom"
                  style={
                    active === "backend-developer"
                      ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "" }
                  }
                >
                  <b style={isMobile ? mobstyle : deskstyle}>
                    {" "}
                    Backend Developer{" "}
                  </b>
                </button>
              </div>
              <div style={isMobile ? mobpro : deskpro}>
                <button
                  onClick={() => {
                    if (isMobile) {
                      setActive("frontend-developer");
                      setToggle(!toggle);
                    } else {
                      setActive("frontend-developer");
                    }
                  }}
                  className="active  d-flex border-bottom"
                  style={
                    active === "frontend-developer"
                      ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "" }
                  }
                >
                  <b style={isMobile ? mobstyle : deskstyle}>
                    Frontend Developer
                  </b>
                </button>

                <button
                  onClick={() => {
                    if (isMobile) {
                      setActive("technician");
                      setToggle(!toggle);
                    } else {
                      setActive("technician");
                    }
                  }}
                  className="active  d-flex border-bottom"
                  style={
                    active === "technician"
                      ? { color: "#34495e", fontWeight: "bold" }
                      : { color: "" }
                  }
                >
                  <b style={isMobile ? mobstyle : deskstyle}>
                    {" "}
                    Hardware Technician
                  </b>
                </button>
              </div>
            </div>

            <div className="col-lg-9 border rounded">
              {" "}
              {active === "all-jobs" ? (
                <div
                  className="row max-h-96 overflow-y-auto "
                  style={isMobile ? mobcrd : deskcrd}
                >
                  {pro?.map((p) => {
                    return (
                      <div
                        className="col-md-4 col-sm-6"
                        style={isMobile ? cardmob : carddesk}
                      >
                        <div
                          className="course-wrapper-2 "
                          style={isMobile ? plcmobcrd : plcdeskcrd}
                        >
                          <Card
                            sx={{
                              maxWidth: 500,
                              background: "rgba(237, 224, 255, 1)",
                              height: "300px",
                            }}
                            onClick={
                              isMobile
                                ? () =>
                                    navigate(`/trainingCertificates/${p?.id}`)
                                : undefined
                            }
                            onMouseEnter={() => setIsShown(true)}
                            onMouseLeave={() => setIsShown(false)}
                          >
                            {isShown && (
                              <div
                                className="course-cart"
                                style={{
                                  backgroundColor: "rgba(237,224,255,1)",
                                  width: "150px",
                                }}
                              >
                                <div className="course-info-wrapper">
                                  <div className="cart-info-body">
                                    <span className="category-color category-color-1">
                                      <span>{p.title}</span>
                                    </span>
                                    <Link to="/TrainingPricing">
                                      <h3></h3>
                                    </Link>
                                    <div className="cart-lavel">
                                      <p>{p.desc}</p>
                                    </div>
                                    <div className="info-cart-text"> */}
          {/* <ul>
                                  {p.jd.map((i) => {
                                    return (
                                      <li style={{ fontSize: "15px" }}>
                                        <i className="far fa-check" />
                                        {i}
                                      </li>
                                    );
                                  })}
                                </ul> */}
          {/* </div>
                                    <div className="course-action">
                                      <Link
                                        to={`/trainingCertificates/${p?.id}`}
                                        className="view-details-btn"
                                        style={{ color: "white" }}
                                      >
                                        View Details
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            <CardActionArea>
                              <CardMedia
                                component="img"
                                height="140"
                                image={p?.image}
                                alt="green iguana"
                                style={{ height: "200px" }}
                              />
                              <CardContent>
                                <div className="row">
                                  <div className="col-md-12">
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      component="div"
                                      style={{
                                        fontSize: "17px",
                                      }}
                                    ></Typography>
                                    <div
                                      className="portfolio-price"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontSize: "17px",
                                      }}
                                    >
                                      <span>{p.title}</span>
                                      <AddRoadIcon />
                                      <span>Latest</span>
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {active === "full-stack-developer" ? (
                <div className="row max-h-96 overflow-y-auto ">
                  {pro.filter((p) => p.title === "Full Stack Development")
                    .length > 0 ? (
                    <div>
                      {pro
                        .filter((p) => p.title === "Full Stack Development")
                        .map((p) => {
                          return (
                            <div className="col-md-4 col-sm-6 mt-2">
                              <div className="course-wrapper-2 mb-10">
                                <Card
                                  sx={{
                                    maxWidth: 500,
                                    background: "rgba(237, 224, 255, 1)",
                                    height: "300px",
                                  }}
                                  onClick={
                                    isMobile
                                      ? () =>
                                          navigate(
                                            `/trainingCertificates/${p?.id}`
                                          )
                                      : undefined
                                  }
                                  onMouseEnter={() => setIsShown(true)}
                                  onMouseLeave={() => setIsShown(false)}
                                >
                                  {isShown && (
                                    <div
                                      className="course-cart"
                                      style={{
                                        backgroundColor: "rgba(237,224,255,1)",
                                        width: "150px",
                                      }}
                                    >
                                      <div className="course-info-wrapper">
                                        <div className="cart-info-body">
                                          <span className="category-color category-color-1">
                                            <span>{p.title}</span>
                                          </span>
                                          <Link to="/TrainingPricing">
                                            <h3></h3>
                                          </Link>
                                          <div className="cart-lavel">
                                            <p>{p.desc}</p>
                                          </div>
                                          <div className="info-cart-text"> */}
          {/* <ul>
                                  {p.jd.map((i) => {
                                    return (
                                      <li style={{ fontSize: "15px" }}>
                                        <i className="far fa-check" />
                                        {i}
                                      </li>
                                    );
                                  })}
                                </ul> */}
          {/* </div>
                                          <div className="course-action">
                                            <Link
                                              to={`/trainingCertificates/${p?.id}`}
                                              className="view-details-btn"
                                              style={{ color: "white" }}
                                            >
                                              View Details
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )} */}

          {/* <CardActionArea>
                                    <CardMedia
                                      component="img"
                                      height="140"
                                      image={p?.image}
                                      alt="green iguana"
                                      style={{ height: "200px" }}
                                    />
                                    <CardContent>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            style={{
                                              fontSize: "17px",
                                            }}
                                          ></Typography>
                                          <div
                                            className="portfolio-price"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              fontSize: "17px",
                                            }}
                                          >
                                            <span>{p.title}</span>
                                            <AddRoadIcon
                                              style={{ marginLeft: "200px" }}
                                            />
                                            <span>Latest</span>
                                          </div>
                                        </div>
                                      </div>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <>
                      <div className="col-lg-2"></div>

                      <div className="col-lg-6 border rounded text-center mt-2 ">
                        <h2 className="p-3 m-3">
                          {" "}
                          No Trainings found with the desired title.
                        </h2>
                      </div>
                    </>
                  )}{" "}
                </div>
              ) : null} */}
          {/* {active ==="full-stack-developer" && <h2>full-stack-developer </h2>} */}
          {/* {active === "backend-developer" && <h2>backend-developer</h2>} */}
          {/* {active === "backend-developer" ? (
                <div className="row max-h-96 overflow-y-auto ">
                  {pro.filter((p) => p.title === "Backend Developer").length >
                  0 ? (
                    <div>
                      {pro
                        .filter((p) => p.title === "Backend Developer")
                        .map((p) => {
                          return (
                            <div className="col-md-4 col-sm-6 mt-2">
                              <div className="course-wrapper-2 mb-10">
                                <Card
                                  sx={{
                                    maxWidth: 500,
                                    background: "rgba(237, 224, 255, 1)",
                                    height: "300px",
                                  }}
                                  onClick={
                                    isMobile
                                      ? () =>
                                          navigate(
                                            `/trainingCertificates/${p?.id}`
                                          )
                                      : undefined
                                  }
                                  onMouseEnter={() => setIsShown(true)}
                                  onMouseLeave={() => setIsShown(false)}
                                >
                                  {isShown && (
                                    <div
                                      className="course-cart"
                                      style={{
                                        backgroundColor: "rgba(237,224,255,1)",
                                        width: "150px",
                                      }}
                                    >
                                      <div className="course-info-wrapper">
                                        <div className="cart-info-body">
                                          <span className="category-color category-color-1">
                                            <span>{p.title}</span>
                                          </span>
                                          <Link to="/TrainingPricing">
                                            <h3></h3>
                                          </Link>
                                          <div className="cart-lavel">
                                            <p>{p.desc}</p>
                                          </div>
                                          <div className="info-cart-text"> */}
          {/* <ul>
                                  {p.jd.map((i) => {
                                    return (
                                      <li style={{ fontSize: "15px" }}>
                                        <i className="far fa-check" />
                                        {i}
                                      </li>
                                    );
                                  })}
                                </ul> */}
          {/* </div>
                                          <div className="course-action">
                                            <Link
                                              to={`/trainingCertificates/${p?.id}`}
                                              className="view-details-btn"
                                              style={{ color: "white" }}
                                            >
                                              View Details
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <CardActionArea>
                                    <CardMedia
                                      component="img"
                                      height="140"
                                      image={p?.image}
                                      alt="green iguana"
                                      style={{ height: "200px" }}
                                    />
                                    <CardContent>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            style={{
                                              fontSize: "17px",
                                            }}
                                          ></Typography>
                                          <div
                                            className="portfolio-price"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              fontSize: "17px",
                                            }}
                                          >
                                            <span>{p.title}</span>
                                            <AddRoadIcon
                                              style={{ marginLeft: "200px" }}
                                            />
                                            <span>Latest</span>
                                          </div>
                                        </div>
                                      </div>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <>
                      <div className="col-lg-2"></div>

                      <div className="col-lg-6 border rounded text-center mt-2">
                        <h2 className="p-3 m-3">
                          {" "}
                          No Trainings found with the desired title.
                        </h2>
                      </div>
                    </>
                  )}{" "}
                </div>
              ) : null} */}
          {/* {active === "frontend-developer" && <h2>frontend-developer</h2>} */}
          {/* {active === "frontend-developer" ? (
                <div className="row max-h-96 overflow-y-auto ">
                  {pro.filter((p) => p.title === "Frontend Developer").length >
                  0 ? (
                    <div>
                      {pro
                        .filter((p) => p.title === "Frontend Developer")
                        .map((p) => {
                          return (
                            <div className="col-md-4 col-sm-6 mt-2">
                              <div className="course-wrapper-2 mb-10">
                                <Card
                                  sx={{
                                    maxWidth: 500,
                                    background: "rgba(237, 224, 255, 1)",
                                    height: "300px",
                                  }}
                                  onClick={
                                    isMobile
                                      ? () =>
                                          navigate(
                                            `/trainingCertificates/${p?.id}`
                                          )
                                      : undefined
                                  }
                                  onMouseEnter={() => setIsShown(true)}
                                  onMouseLeave={() => setIsShown(false)}
                                >
                                  {isShown && (
                                    <div
                                      className="course-cart"
                                      style={{
                                        backgroundColor: "rgba(237,224,255,1)",
                                        width: "150px",
                                      }}
                                    >
                                      <div className="course-info-wrapper">
                                        <div className="cart-info-body">
                                          <span className="category-color category-color-1">
                                            <span>{p.title}</span>
                                          </span>
                                          <Link to="/TrainingPricing">
                                            <h3></h3>
                                          </Link>
                                          <div className="cart-lavel">
                                            <p>{p.desc}</p>
                                          </div>
                                          <div className="info-cart-text"> */}
          {/* <ul>
                                  {p.jd.map((i) => {
                                    return (
                                      <li style={{ fontSize: "15px" }}>
                                        <i className="far fa-check" />
                                        {i}
                                      </li>
                                    );
                                  })}
                                </ul> */}
          {/* </div>
                                          <div className="course-action">
                                            <Link
                                              to={`/trainingCertificates/${p?.id}`}
                                              className="view-details-btn"
                                              style={{ color: "white" }}
                                            >
                                              View Details
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <CardActionArea>
                                    <CardMedia
                                      component="img"
                                      height="140"
                                      image={p?.image}
                                      alt="green iguana"
                                      style={{ height: "200px" }}
                                    />
                                    <CardContent>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            style={{
                                              fontSize: "17px",
                                            }}
                                          ></Typography>
                                          <div
                                            className="portfolio-price"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              fontSize: "17px",
                                            }}
                                          >
                                            <span>{p.title}</span>
                                            <AddRoadIcon
                                              style={{ marginLeft: "200px" }}
                                            />
                                            <span>Latest</span>
                                          </div>
                                        </div>
                                      </div>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <>
                      <div className="col-lg-2"></div>

                      <div className="col-lg-6 border rounded text-center mt-2">
                        <h2 className="p-3 m-3">
                          {" "}
                          No Trainings found with the desired title.
                        </h2>
                      </div>
                    </>
                  )}{" "}
                </div>
              ) : null} */}
          {/* {active === "technician" && <h2>technician</h2>} */}
          {/* {active === "technician" ? (
                <div className="row max-h-96 overflow-y-auto ">
                  {pro.filter((p) => p.title === "Hardware technician").length >
                  0 ? (
                    <div>
                      {pro
                        .filter((p) => p.title === "Hardware technician")
                        .map((p) => {
                          return (
                            <div className="col-md-4 col-sm-6 mt-2">
                              <div className="course-wrapper-2 mb-10">
                                <Card
                                  sx={{
                                    maxWidth: 500,
                                    background: "rgba(237, 224, 255, 1)",
                                    height: "300px",
                                  }}
                                  onClick={
                                    isMobile
                                      ? () =>
                                          navigate(
                                            `/trainingCertificates/${p?.id}`
                                          )
                                      : undefined
                                  }
                                  onMouseEnter={() => setIsShown(true)}
                                  onMouseLeave={() => setIsShown(false)}
                                >
                                  {isShown && (
                                    <div
                                      className="course-cart"
                                      style={{
                                        backgroundColor: "rgba(237,224,255,1)",
                                        width: "150px",
                                      }}
                                    >
                                      <div className="course-info-wrapper">
                                        <div className="cart-info-body">
                                          <span className="category-color category-color-1">
                                            <span>{p.title}</span>
                                          </span>
                                          <Link to="/TrainingPricing">
                                            <h3></h3>
                                          </Link>
                                          <div className="cart-lavel">
                                            <p>{p.desc}</p>
                                          </div>
                                          <div className="info-cart-text"> */}
          {/* <ul>
                                  {p.jd.map((i) => {
                                    return (
                                      <li style={{ fontSize: "15px" }}>
                                        <i className="far fa-check" />
                                        {i}
                                      </li>
                                    );
                                  })}
                                </ul> */}
          {/* </div>
                                          <div className="course-action">
                                            <Link
                                              to={`/trainingCertificates/${p?.id}`}
                                              className="view-details-btn"
                                              style={{ color: "white" }}
                                            >
                                              View Details
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  <CardActionArea>
                                    <CardMedia
                                      component="img"
                                      height="140"
                                      image={p?.image}
                                      alt="green iguana"
                                      style={{ height: "200px" }}
                                    />
                                    <CardContent>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            style={{
                                              fontSize: "17px",
                                            }}
                                          ></Typography>
                                          <div
                                            className="portfolio-price"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              fontSize: "17px",
                                            }}
                                          >
                                            <span>{p.title}</span>
                                            <AddRoadIcon
                                              style={{ marginLeft: "200px" }}
                                            />
                                            <span>Latest</span>
                                          </div>
                                        </div>
                                      </div>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <>
                      <div className="col-lg-2"></div>

                      <div className="col-lg-6 border rounded text-center mt-2">
                        <h2 className="p-3 m-3">
                          {" "}
                          No Trainings found with the desired title.
                        </h2>
                      </div>
                    </>
                  )}{" "}
                </div>
              ) : null}
            </div>
          </div> */}
          {/* </div> */}
          <div style={isMobile ? mobtx : desktx}>
            <MainFeature4
              subheading={<Subheading></Subheading>}
              imageSrc={Certificateimg}
              imageBorder={true}
              imageDecoratorBlob={true}
            />
          </div>
          <Footer />
        </AnimationRevealPage>
      )}
    </>
  );
};
