import React, { useState, useEffect } from "react";
import { localUrl, url } from "pages/env";
import WorkIcon from "@mui/icons-material/Work";
import PublicIcon from "@mui/icons-material/Public";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import WhyCards from "./WhyCards";
import WhyCards2 from "./WhyCards2";
import { isMobile } from "react-device-detect";
import { localUrlImage } from "pages/env";
import { SliderContainer } from "./SliderContainer";
import Slider from "react-slick";

const contmob = {
  marginTop: "-70px",
};
const contdesk = {
  marginTop: "-30px",
};
export default function WhyWeAreTheBest() {
  const [pro, setPro] = useState([{}]);

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(localUrl + "jobs/Career_Page", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPro(result?.students);

        console.log(result, "AAAAAAAAAAAAAAA");
      })
      .catch((error) => console.log("error", error));
  }, []);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="max-w-full grid lg:grid-cols-1 m-auto p-5">
        <div>
          <p className="text-5xl font-bold text-center ">
            WHY WE ARE
            <span className="text-[#7936FFFF] font-bold"> THE BEST</span>
          </p>
          <p className="text-center text-gray-700 font-semibold text-lg">
            We have been helping workers to find their dream job for more than
            10 years, and it continues to grow.
          </p>
        </div>

        <div className="lg:flex bg-orange-100 justify-between p-4 lg:w-[65%] m-auto">
          <div className="flex">
            <WorkIcon className="scale-150" />
            <div className="ml-6">
              <p className="text-2xl font-bold">100+</p>
              <p>Job Offer</p>
            </div>
          </div>

          <div className="flex">
            <PublicIcon className="scale-150" />
            <div className="ml-6">
              <p className="text-2xl font-bold">25+</p>
              <p>Companies</p>
            </div>
          </div>

          <div className="flex">
            <PeopleAltIcon className="scale-150" />
            <div className="ml-6">
              <p className="text-2xl font-bold">500+</p>
              <p>Community Member</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <p className="text-[#7936FFFF] text-3xl text-center font-bold">
          JOB CATEGORIES
        </p>
      </div>

      <div className="row">
        <SliderContainer>
          <Slider {...settings}>
            {pro.map((p) => {
              return (
                <div className="col-lg-2 ">
                  <div className=" bg-slate-100 shadow-xl rounded-t-full">
                    <div className="position-relative ">
                      <img
                        alt="imag"
                        src={localUrlImage + p.image}
                        className="w-100 rounded-full"
                      />
                    </div>
                    <p className="mt-2 p-2 text-xl font-semibold text-blue-700 ">
                      {p.title}
                    </p>
                    <div
                      className="p-2"
                      style={{ marginTop: "-20px", height: "auto" }}
                    >
                      <p
                        style={{ color: "black" }}
                        className="overflow-y-scroll h-[150px]"
                      >
                        {p.Job_Description}
                      </p>
                      <div className="flex justify-between">
                        <div>
                          <p className="text-lg font-semibold text-blue-700">
                            Companies
                          </p>
                          <p className="font-bold text-lg">25+</p>
                        </div>

                        <div>
                          <p className="text-lg font-semibold text-blue-700">
                            Vacancy
                          </p>
                          <p className="text-lg font-semibold">{p.id}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </SliderContainer>
      </div>

      <div className="mt-8 flex flex-col items-center">
        <button className="bg-[#7936FFFF] text-white p-3 rounded-3xl font-semibold ">
          <Link to="/CareerPage" style={{ color: "white" }}>
            View All Categories
          </Link>
        </button>
      </div>
    </>
  );
}
