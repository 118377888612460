// export const localUrl = "http://192.168.1.15:8000/api/";
export const localUrl = "https://admin.procareers.in/api/";
export const localUrlImage = "https://admin.procareers.in";

// export const localUrl = "https://procareers2.pythonanywhere.com/api/";
// export const localUrlImage = "https://procareers2.pythonanywhere.com";

// export const localUrlImage = "http://192.168.1.15:8000";
// export const localUrl = "http://192.168.1.15:8000/api/";

export const url = "https://procareerbackend.pythonanywhere.com/ ";

// export const url = "http://192.168.1.15:8000";
// // export const localUrl = "http://procareers.pythonanywhere.com/api/";
// export const localUrl = "http://192.168.1.15:8000/api/";
// export const localUrlImage = "http://192.168.1.15:8000";
// //
// export const localUrl = "https://procareers2.pythonanywhere.com/api/";
// export const localUrlImage = "https://procareers2.pythonanywhere.com";
