import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Modal } from "antd";
import validator from "validator";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import "./HeaderLogin.css";
import { ReactComponent as FacebookIcon } from "images/facebook.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin.svg";
import { ReactComponent as InstagramIcon } from "images/instagram.svg";
import "antd/dist/antd.min.css";
import tw from "twin.macro";
import styled from "styled-components";
import Procareerlogo from "../../images/Procareerlogo.png";
import { Spin, Checkbox } from "antd";

// import Link from "antd/lib/typography/Link";
import { Link } from "react-router-dom";

import { message } from "antd";
import { loginInfoVar } from "store/slices/userSlice";
import { useDispatch } from "react-redux";
import { localUrl, localUrlImage } from "pages/env";
import { isMobile } from "react-device-detect";
import { AlternateEmail } from "@mui/icons-material";

// import validator from "validator";

const notsuccess = () => {
  Modal.success({
    content: "Email Or Password is invalid",
  });
};

const mob = {
  width: "100%",
  justifyContent: "center",
};
const desk = {
  width: "100%",
  justifyContent: "end",
};
const mob1 = {
  width: "100%",
  justifyContent: "center",
  color: "white",
  marginTop: "35px",
};
const desk1 = {
  width: "100%",
  justifyContent: "start",
  color: "white",
  marginTop: "5px",
};
const mobstyle = {
  marginTop: "-20px",
};
const deskstyle = {
  marginTop: "-10px",
  marginLeft: "10px",
};

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-300 hover:bg-gray-600 transition duration-300 mr-3 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};
const LoginHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Data, SetData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [checkbox, setCheckbox] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const ChangeHandler = (e) => {
    SetData({ ...Data, [e.target.name]: e.target.value });
  };
  const [messageApi, contextHolder] = message.useMessage();

  const LoginHandler = (e) => {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("email", Data.email);
    formdata.append("password", Data.password);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    if (Data.email == "" && Data.password == "") {
      setError("please fill all the fields");
    } else if (Data.email == "") {
      setError("email can not be empty");
    } else if (!checkbox) {
      setError("Please accept the Terms and Conditions");
    } else if (!validator.isEmail(Data.email)) {
      setError("email is need to validate");
    } else if (Data.password == "") {
      setError("password cannot be empty");
    }  else {
      setSubmitted(true);
      setError(false);
      setLoading(true);

      fetch(localUrlImage + "/user/login/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          dispatch(loginInfoVar({ loginFlag: true }));
          localStorage.setItem("access_token", result.token.access);
        })

        .then(() => message.success("Login Sucessfull", 2.5))
        .then(() => {
          navigate("/UserProfile");
          setLoading(false);
        })
        .catch((error) => {
          notsuccess();
          setLoading(false);
          setError("Email or Password is not valid");
          console.log("error", error);
        });
    }
  };
  function validateForm(form) {
    const checked = form.target.checked;
    if (checked) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  }
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Spin />
        </div>
      ) : (
        <AnimationRevealPage>
          <div className="colorofBody">
            <section>
              <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div
                    className="col-md-9 col-lg-6 col-xl-5"
                    style={{ padding: "20px", marginTop: "25px" }}
                  >
                    <img
                      style={{ borderRadius: "50%" }}
                      src={Procareerlogo}
                      className="img-fluid"
                      alt="Sample image"
                    />
                  </div>
                  <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                    <form>
                      <div className="d-flex flex-row  justify-content-around justify-content-lg-start">
                        <div
                          className="lead fw-normal mb-0 me-4"
                          style={isMobile ? mob1 : desk1}
                        >
                          Sign in with
                        </div>
                        <div style={isMobile ? mob : desk}>
                          <SocialLinksContainer>
                            <SocialLink href="https://www.facebook.com/people/Procareers/100087744926174/">
                              <FacebookIcon />
                            </SocialLink>
                            <SocialLink href="https://www.linkedin.com/in/procareers-infotech-solutions-54567124b/">
                              <LinkedInIcon />
                            </SocialLink>
                            <SocialLink href="https://www.instagram.com/procareers_infotech_solutions/">
                              <InstagramIcon />
                            </SocialLink>
                          </SocialLinksContainer>
                        </div>
                      </div>
                      <br />
                      <center style={{ marginTop: "-20px" }}>
                        <div className="divider d-flex align-items-center my-2">
                          {/* <p className="text-center fw-bold mx-4 mb-0">Or</p> */}
                        </div>
                      </center>
                      {/* Email input */}
                      <div className="form-outline mt-3">
                        <label
                          className="form-label"
                          htmlFor="form3Example3"
                          style={{ color: "white", marginBottom: "-10px" }}
                        >
                          Email address
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={Data.email}
                          onChange={ChangeHandler}
                          id="form3Example3"
                          className="form-control form-control-lg"
                          placeholder="Enter valid email address"
                          style={{ backgroundColor: "white" }}
                        />



                      </div>
                      <br />
                      {/* Password input */}
                      <div className="form-outline mt-3">
                        <div className="row">
                          <div class="col-6">
                            <label
                              className="form-label"
                              htmlFor="form3Example4"
                              style={{ color: "white", marginBottom: "-10px" }}
                            >
                              Password
                            </label>
                          </div>

                          <div class="col-6">
                            <p
                              className="text-decoration-underline"
                              onClick={togglePassword}
                              style={{
                                color: "white",
                                padding: "2px",
                                float: "right",
                                marginBottom: "-5px",
                                width: "auto",
                                fontSize: "15px",
                              }}
                            >
                              Show Password
                            </p>
                          </div>
                        </div>
                        <input
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          value={Data.password}
                          onChange={ChangeHandler}
                          id="form3Example4"
                          className="form-control form-control-lg"
                          placeholder="Enter password"
                        />



                        {/* <Form.Item
                        name="password"
                        value={Data.password}
                        onChange={ChangeHandler}
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          style={{
                            padding: "8px 15px",
                            borderRadius: "5px",
                          }}
                          placeholder="Enter your Password here"
                        />
                      </Form.Item> */}
                      </div>
                      <br />
                      <div
                        className="row"
                        style={isMobile ? mobstyle : deskstyle}
                      >
                        <div className="col-6">
                          <Link
                            to="/ForgotPassward"
                            data-target="#myModal"
                            data-toggle="modal"
                            className="text-decoration-underline "
                            style={{
                              color: "white",
                              padding: "2px",
                              float: "left",
                              marginBottom: "5px",
                              width: "auto",
                            }}
                          >
                            Forgot password ?
                          </Link>
                        </div>
                        <br />

                        <div className="col-6">
                          <Link
                            data-target="#myModal"
                            data-toggle="modal"
                            className=" text-decoration-underline"
                            to="/SignupHeader2"
                            style={{
                              color: "white",
                              padding: "2px",
                              float: "right",
                              marginBottom: "5px",
                              width: "auto",
                            }}
                          >
                            Create an account ?
                          </Link>
                        </div>
                        <br />
                      </div>

                      <div className="mx-3 mt-2 ">
                        <div className="form-check d-flex justify-content-center mb-1">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            // defaultValue=""
                            id="form2Example3c"
                            value="true"
                            required
                            onChange={validateForm}
                          />
                          {/* <label
                            className="form-check-label"
                            // htmlFor="form2Example3"
                            style={{ color: "white" }}
                          > */}

                          {/* <div style={{ color: "white" }}>
                            I agree all statements in{" "}
                          </div>
                          <Link to="/TermsAndCond">Terms of service</Link> */}

                          {/* </label> */}

                          <div style={{ color: "white", fontSize: "12px" }}>
                            I agree all condition in{" "}
                          </div>
                          <a
                            href="/TermsAndCond"
                            style={{ color: "white", marginLeft: "10px", fontSize: "15px" }}
                          ><u>
                              Terms & Conditions
                            </u>
                          </a>


                        </div>
                      </div>

                      <button
                        className="button"
                        type="button"
                        name="button"
                        onClick={LoginHandler}
                        // className="mt-2 mb-2"
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          border: "1px solid white",
                          fontSize: "18px",
                          borderRadius: "5px",
                          padding: "4px",
                          // color: "white",
                          textAlign: "center",
                        }}
                      >
                        Login
                      </button>
                    </form>
                    {error ? (
                      <div className="text-white fs-14 text-center bg-primary">
                        {error}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <br />
            </section>
          </div>
          <br></br>

          <Footer />
        </AnimationRevealPage>
      )}
    </>
  );
};
export default LoginHeader;
