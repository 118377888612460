import { Button, Checkbox, Form, Input, Modal } from "antd";
import { localUrl, localUrlImage } from "pages/env";
import { useDispatch } from "react-redux";
import { crmApi } from "store/slices/CrmSlice";
import { isMobile } from "react-device-detect";
import { useState } from "react";
import tw from "twin.macro";
import { Link } from "react-router-dom";
const NavLink = tw.a`  text-lg my-5 lg:text-sm lg:mx-3 lg:my-0  font-semibold tracking-wide transition duration-300  pb-1 border-b-2 border-transparent hover:border-primary-900 hocus:text-primary-500`;
const PrimaryLink = tw(
  NavLink
)`  lg:mx-0  px-2 py-1 rounded bg-primary-500 text-gray-100  hocus:bg-primary-900 hocus:text-gray-200 focus:shadow-outline  border-b-0`;

const HireForm = () => {
  const dispatch = useDispatch();
  const mob = {
    // width: "300px",
    // innerHeight: "auto",
    marginLeft: "25px",
    marginRight: "100px",
  };
  const desk = {
    // width: "auto",
    marginRight: "100px",
  };
  const [Data, SetData] = useState({
    Company_name: "",
    HR_Name: "",
    Phone_Number: "",
    Looking_For_Technology: "",
    No_Of_Candidates_Requried: "",
    Location: "",
  });

  const ChangeHandler = (e) => {
    SetData({ ...Data, [e.target.name]: e.target.value });
  };

  const success = () => {
    Modal.success({
      content: "Your Message is recoreded We will get back to you Soon..!",
      okType: "success",
    });
  };
  const [form] = Form.useForm();
  const onFinish = (values) => {
    // crm obj
    const crmObj = {
      user: { name: values?.HR_Name },
      companyName: values?.Company_name,
      phone: values?.Phone_Number,
    };
    // dispatch obj
    dispatch(crmApi(crmObj));
    var formdata = new FormData();
    formdata.append("company_name", values.Company_name);
    formdata.append("hr_name", values.HR_Name);
    formdata.append("phone", values.Phone_Number);
    formdata.append("looking_for_technology", values.Looking_For_Technology);
    formdata.append(
      "no_of_candidates_required",
      values.No_Of_Candidates_Requried
    );
    formdata.append("location", values.Location);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch(localUrlImage + "/job/hire_agent/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result === "Success") {
        }
        success();
      })
      .catch((error) => console.log("error", error));
    form.resetFields();
  };

  return (
    <div style={{}}>
      <Form
        style={isMobile ? mob : desk}
        form={form}
        onFinish={onFinish}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 12,
        }}
        initialValues={{
          remember: true,
        }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Company Name"
          name="Company_name"
          value={Data.Company_name}
          onChange={ChangeHandler}
          rules={[
            {
              required: true,
              message: "Please input your company name!",
            },
            {
              pattern: new RegExp(/^[A-Za-z\s]*$/),
              message: "field does not accept numbers",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="HR Name"
          name="HR_Name"
          value={Data.HR_Name}
          onChange={ChangeHandler}
          rules={[
            {
              required: true,
              message: "Please input your HR Name!",
            }, {
                        pattern: new RegExp(/^[A-Za-z\s]*$/),
                        message: "field does not accept numbers",
                      },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          name="Phone_Number"
          label="Phone Number"
          value={Data.Phone_Number}
          onChange={ChangeHandler}
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
            {
              pattern: new RegExp("^[A-Za-z]*$"),
              message: "Wrong format!",
            },
          ]}
        >
          <Input
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            minLength={10}
            maxLength={10}
            addonBefore="+91"
            style={{
              width: "100%",
            }}
          />
        </Form.Item> */}
        <Form.Item
          name="Phone_Number"
          label="Phone Number"
          value={Data.Phone_Number}
          onChange={ChangeHandler}
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
            {
              pattern: new RegExp("^[0-9]*$"),
              message: "Input Accepts Only Numbers!",
            },
          ]}
        >
          <Input
            placeholder="Enter Phone Number"
            maxLength={10}
            minLength={10}
            addonBefore="+91"
            // addonBefore={prefixSelector}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>

        <Form.Item
          label="Looking for Technology"
          name="Looking_For_Technology"
          value={Data.Looking_For_Technology}
          onChange={ChangeHandler}
          rules={[
            {
              required: true,
              message: "Please Fill Looking for Technology!",
            },
            {
              pattern: new RegExp(/^[A-Za-z\s]*$/),
              message: "field does not accept numbers",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="No. Of Candidates Required"
          name="No_Of_Candidates_Requried"
          value={Data.No_Of_Candidates_Requried}
          onChange={ChangeHandler}
          rules={[
            {
              required: true,
              message: "Please Fill  No. Of Candidate Required!",
            },
            {
              pattern: new RegExp("^[0-9]*$"),
              message: "Input Accepts Only Numbers!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Address"
          name="Location"
          value={Data.Location}
          onChange={ChangeHandler}
          rules={[
            {
              required: true,
              message: "Please input your Address!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
          rules={[
            {
              required: true,
              message: "Please Check!",
            },
          ]}>
          <Checkbox>Remember me</Checkbox>
        </Form.Item> */}

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
           
          <div>
            {/* <NavLink style={{ marginTop: 0 }}>
              <PrimaryLink style={{ marginTop: 0 }}>
                <Link
                  style={{
                    color: "white",
                    borderRadius: "15%",
                    backgroundColor: "dark blue",
                  }}
                  to="LoginHeader"> */}
            <button
              // style={{
              //   width: "25%",
              //   justifyContent: "center",
              //   border: "1px solid blue",
              //   fontSize: "18px",
              //   borderRadius: "5px",
              //   padding: "4px",
              //   color: "white",
              //   textAlign: "center",
              //   backgroundColor: "blue",
              // }}
              className="bg-[#6415FF] rounded-xl py-2 px-4 text-white"
              onClick={ChangeHandler}
            >
              {" "}
              Submit 
            </button>{" "}
             
            {/* </Link>
                 
              </PrimaryLink>
            </NavLink> */}
             {" "}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default HireForm;
