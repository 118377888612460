import React, { useEffect, useState } from "react";
import Light from "components/headers/light";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import FiveColumnWithInputForm from "components/footers/FiveColumnWithInputForm";
import { Form, Input, Select, DatePicker } from "antd";
import { Modal } from "antd";
import ClipLoader from "react-spinners/ClipLoader";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import FactoryIcon from "@mui/icons-material/Factory";
import SchoolIcon from "@mui/icons-material/School";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { isMobile } from "react-device-detect";
import CircleIcon from "@mui/icons-material/Circle";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import CareerForm from "./CareerForm";
import "./Careerpage.css";
import Button from "react-bootstrap/Button";
import { localUrl, localUrlImage } from "pages/env";

import { LogoLink } from "components/headers/light";

const CareerPage1 = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");

  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const [pro, setPro] = useState([{}]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState([]);
  const [run, setRun] = useState(true);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 6;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = pro.slice(firstIndex, lastIndex);
  const npage = Math.ceil(pro.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  const prevPage = () => {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPage = () => {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
  };
  const changePage = (id) => {
    setCurrentPage(id);
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const handleClose = () => setShow(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setRun(false);
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(localUrl + "jobs/Career_Page", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPro(result?.students);

        console.log(result, "res");
      })
      .catch((error) => console.log("error", error));
  }, []);

  console.log(pro, "pr");
  const [isShown, setIsShown] = useState(false);
  const [Data, SetData] = useState();
  const [dataValue, setDataValue] = useState(null);

  const { Option } = Select;
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  const [form] = Form.useForm();

  const [filePath, setFilePath] = useState(null);
  const [fileObj, setFileObj] = useState(null);
  useEffect(() => {}, [setFilePath]);

  // const [modalText, setModalText] = useState(null);
  const showModal = (stu) => {
    setDataValue(stu);
    setOpen(true);
    console.log(stu);
  };
  const [date1, setDate] = useState();
  const onChangeDate = (date, dateString) => {
    console.log(date, dateString);
    setDate(dateString);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}>
        <Option value="91">+91</Option>
      </Select>
    </Form.Item>
  );

  const onSucess = (p) => {
    setOpen(p);
  };

  // const mob1 = {
  //   marginLeft: "auto",
  //   marginRight: "auto",
  // };
  // const desk1 = {};
  const mobileview = {
    width: "auto",
    height: "40px",
    
  };
  const desktopStyle = {
    marginTop:"75px"
  };

  return (
    <div>
      {run ? (
        <>
          <center style={{ marginTop: "15%" }}>
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>

            <div style={{ width: "100px", margin: "", display: "block" }}>
              <h4 style={{ marginLeft: "15px" }}>Welcome to</h4>
              <br />
              <LogoLink style={{ color: "navy" }}>CareerPage</LogoLink>
            </div>
          </center>
        </>
      ) : (
        // <AnimationRevealPage>
        <div
          className="container"
          style={{
            overflowX: "hidden",
            maxWidth: "1600px",
            marginTop: "20px",
          }}>
          <div className="max-w-fit mx-auto my-auto grid lg:grid-cols-3 md:grid-cols-2 gap-3 px-[10px] mt-4">
            {/* //style={isMobile ? mob1 : desk1} */}
            {records?.map((p) => {
              return (
                <div
                  className="cards shadow-2xl h-[420px] mb-2 rounded-lg  "
                  style={{ backgroundColor: "rgb(197, 235, 235)" }}>
                  <img
                    src={localUrlImage + p.image}
                    alt=""
                    className="h-[220px] rounded-lg shadow-inner w-full"
                  />
                  {/* #337AB7 */}
                  <p
                    className="font-sans pt-[10px] font-bold hover:font-black text-md mx-3"
                    style={{ color: "blue" }}>
                    {p.title}
                  </p>
                  <div className=" items-center  mx-4 my-[-5px]  ">
                    <div className="flex items-center justify-between">
                      <div className="flex mb-3 ">
                        <FactoryIcon
                          className="w-1 h-1"
                          style={{ color: "#A9A9A9" }}
                        />
                        <p
                          className="font-sans text-sm ml-1 font-bold"
                          style={{ color: "black" }}>
                          {p.Industry}
                        </p>{" "}
                      </div>
                      <div className=" flex ">
                        <SchoolIcon
                          className="w-1 h-1"
                          style={{ color: "#A9A9A9" }}
                        />
                        <p
                          className="font-sans text-sm ml-1 font-bold"
                          style={{ color: "black" }}>
                          {p.Education}
                        </p>
                      </div>{" "}
                    </div>

                    <div className="flex items-center justify-between">
                      <div className=" flex ">
                        <PrecisionManufacturingIcon
                          className="w-1 h-1"
                          style={{ color: "#A9A9A9" }}
                        />
                        <p
                          className="font-sans text-sm ml-1 font-bold"
                          style={{ color: "black" }}>
                          {p.Experience}
                        </p>
                      </div>
                      <div className=" flex ">
                        <LocationOnIcon
                          className="w-1 h-1"
                          style={{ color: "#A9A9A9" }}
                        />
                        <p
                          className="font-sans text-sm ml-1 font-bold "
                          style={{ color: "black" }}>
                          {p.Job_Location}
                        </p>
                      </div>
                    </div>
                  </div>

                  <button
                    className=" w-auto bg-blue-600 text-white px-2 py-2 rounded-lg text-sm hover:bg-blue-800 flex justify-center m-auto "
                    onClick={() => {
                      setOpen(true);
                      setId(p);
                    }}>
                    Apply Now
                  </button>

                  <div className="mt-5">
                    <Modal
                      title="Apply For The Position"
                      // style={{ top: 100 }}
                      style={isMobile ? mobileview : desktopStyle}
                      centered
                      open={open}
                      onOk={() => setOpen(false)}
                      onCancel={() => setOpen(false)}
                      width={1050}
                      footer={false}
                      bodyStyle={{ height: 850 }}>
                      <CareerForm jobs={id} onSucess={onSucess} />
                    </Modal>
                  </div>
                </div>
              );
            })}

            <div className="col-lg-6"></div>
            <div className="col-lg-7" style={{ marginLeft: "180px" }}>
              <nav>
                <ul className="pagination">
                  {/* <li className="page-item">
                      <button className="page-link" onClick={prevPage}>
                        Prev
            <div>
              <nav>
                <ul className="pagination">
                  {number.map((n, i) => (
                    <li
                      className={`page-link ${
                        currentPage === n ? "active" : ""
                      }`}
                      key={i}
                    >
                      <button className="" onClick={() => changePage(n)}>
                        {n}
                      </button>
                    </li> */}
                  {number.map((n, i) => (
                    <li
                      className={`page-link ${
                        currentPage === n ? "active" : ""
                      }`}
                      key={i}>
                      <button className="" onClick={() => changePage(n)}>
                        {n}
                      </button>
                    </li>
                  ))}
                  {/* <li className="page-item">
                      <button className="page-link" onClick={nextPage}>
                        Next
                      </button>
                    </li> */}
                </ul>
              </nav>
            </div>
          </div>
          <FiveColumnWithInputForm />
          {/* </AnimationRevealPage> */}
        </div>
      )}
    </div>
  );
};

export default CareerPage1;
