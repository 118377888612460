import FiveColumnWithInputForm from "components/footers/FiveColumnWithInputForm";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Header from "components/headers/light.js";
// import 'antd/dist/reset.css';
import Footer from "components/footers/FiveColumnWithInputForm.js";
import "./Applynow1.css";
import React, { useState, useEffect } from "react";
import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  Modal,
  Avatar,
} from "antd";

import { PlusOutlined, RestFilled, UploadOutlined } from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import { transform } from "framer-motion";
import { isMobile } from "react-device-detect";
import { localUrl } from "pages/env";
import { PrimaryButton } from "components/misc/Buttons";
import { fontWeight } from "@mui/system";
import { useLocation } from "react-router-dom";

const mymob = { width: "auto" };
const mydesk = {
  width: "13.8cm",
  marginLeft: "100px",
};
const Applynow = ({updateFormData ,location1 }) => {
  const mobileStyle = { width: "300px", margin: "auto" };
  const desktopStyle = { width: "600px", margin: "auto" };
  const [filename1, setFileName] = useState();


  

 

  // const props = {
  //   user: "file",
  //   action: "#",
  //   headers: {
  //     authorization: "authorization-text",
  //   },

  //   onChange(info) {
  //     console.log(info.file.name);
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //       setFileName(info.file.name);
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },

  const { TextArea } = Input;

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },

    onChange(info) {
      console.log(info, "check file");

      // if (info.file.status !== "uploading") {
      //   console.log(info.file, info.fileList);
      // }
      // if (info.file.status === "done") {
      //   message.success(`${info.file.name} file uploaded successfully`);
      //   setFileName(info.file.name);
      // } else if (info.file.status === "error") {
      //   message.error(`${info.file.name} file upload failed.`);
      // }
    },
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 20,
    },
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const [date1, setDate] = useState();
  const [filePath, setFilePath] = useState(null);
  const [fileObj, setFileObj] = useState(null);
  const onChangeDate = (date, dateString) => {
    console.log(date, dateString);
    setDate(dateString);
  };

  const success = () => {
    Modal.success({
      content:
        " You have successfully applied your training course we'll get back to you soon..",
    });
  };
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log(values, "values");
    var formdata = new FormData();
    formdata.append("name", values.user.name);
    formdata.append("email", values.user.email);
    formdata.append("phone", values.phone);
    // formdata.append("Date_of_Birth", date1);
    // formdata.append("gender", values.gender);
    // formdata.append("Introduction", values.user.Introduction);
    formdata.append("qualification", values.qualification);
    formdata.append("fresher", values.fresher);
    formdata.append("lookingFor", values.lookingFor);
    formdata.append("skills", values.skills);

    // formdata.append("filename", fileInput.files[0], "/C:/Users/kanda/OneDrive/Documents/shyam_WR.docx");
    // formdata.append("filename", fileObj);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(localUrl + "jobs/jobDetails", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        success();
      })
      .catch((error) => console.log("error", error));
    };
    // var formdata = new FormData();
    // // debugger;
    // let finalobj = {};
    // finalobj["name"] = values.user.name;
    // finalobj["email"] = values.user.email;
    // finalobj["phone"] = values.phone;
    // finalobj["Date_of_Birth"] = date1;
    // finalobj["gender"] = values.gender;
    // finalobj["Introduction"] = values.user.Introduction;
    // finalobj["filename"] = filename1;
    // console.log(finalobj);
    // var requestOptions = {
    //   method: "POST",
    //   body: formdata,
    //   redirect: "follow",
    //   ContentType: "application/pdf",
    // };

    // fetch("https://procareerbackend.pythonanywhere.com/api/user/jobDetails", requestOptions)
    // fetch(url + "user/jobDetails", requestOptions)
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log(result);
    //     // success();
    //   })
    //   .catch((error) => console.log("error", error));

    useEffect(() => {
      // code to clear the form on component unmount
      return () => {
        form.resetFields();
      }
    }, [location1])

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <b>+91</b>
    </Form.Item>
  );
  const mob = {
    width: "auto",
  };
  const desk = {
    marginLeft: "10px",
  };
  const mob1 = {
    height: "auto",
  };
  useEffect(() => {}, [setFilePath]);

  return (
    <>
      <AnimationRevealPage>
        {/* <div className="bodyContainer"> */}
        <div
          className="container"
          style={{
            backgroundColor: "#d3d3d342",
            borderRadius: "10px",
            // marginTop: "10px",
            marginBottom: "10px",
            height: "auto",
            marginTop: "-27px",
          }}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="layoutForm d-flex justify-content-center">
                <Form
                  form={form}
                  {...layout}
                  name="nest-messages"
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                  className="screen"
                >
                  <br />
                  <Form.Item
                    name={["user", "name"]}
                    label="Full Name"
                    rules={[
                      {
                        required: true,
                        type: "string",
                      },
                      {
                        pattern: new RegExp(/^[A-Za-z\s]*$/),
                        message: "field does not accept numbers",
                      },
                    ]}
                    className="mb-3"
                  >
                    <Input className="form-control " />
                  </Form.Item>

                  <Form.Item
                    name="phone"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                      {
                        pattern: new RegExp("^[0-9]*$"),
                        message: "Wrong format!",
                      },
                      {      len: 10,      message: "Phone number must be exactly 10 digits!",    },
                    ]}
                  >
                    <Input
                      // onKeyPress={(event) => {
                      //   if (!/[0-9]/.test(event.key)) {
                      //     event.preventDefault();
                      //   }
                      // }}
                      maxLength={10}
                      minLength={10}
                      addonBefore="+91"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      { required: true, message: "Please input your email!" },
                      {
                        pattern:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]{1,1}$/,
                        message: "Please enter a valid email!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="qualification"
                    label="Qualification"
                    rules={[
                      {
                        required: "true",
                      },
                    ]}
                    className="mb-3"
                  >
                    {/* <Input.TextArea className="form-control mb-3" /> */}
                    {/* className="mb-3" > */}
                    <Select
                      className="h-30"
                      placeholder="Select an option "
                      allowClear
                    >
                      <Option value="10th">10th</Option>
                      <Option value="12th/intermediate">
                        12th/intermediate
                      </Option>
                      <Option value="Degree">Degree</Option>
                      <Option value="BE/Btech">BE/Btech</Option>
                      <Option value="ME/Mtech">ME/Mtech</Option>
                      <Option value="MBA">MBA</Option>
                      <Option value="MCA">MCA</Option>
                    </Select>
                  </Form.Item>
                  {/* <Form.Item name="DOB" label="DOB" className="mb-3">
                    <DatePicker
                      onChange={onChangeDate}
                      className="form-control"
                    />
                  </Form.Item> */}

                  <Form.Item
                    name="fresher"
                   
                    rules={[{ required: true }]}
                    className=" mb-3"
                  >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '8px', fontWeight:"bolder" ,color:"#111"}}>
                  Fresher/Experienced:
                  </div>
                     <Select
                       className="h-30"
                       placeholder="Select an option "
                       allowClear
                     >
                       <Option value="Fresher">Fresher</Option>
                       <Option value="Experience">Experienced</Option>
                     </Select>
                    </div>
                  </Form.Item>
                  {/* <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[{ required: true }]}
                    className=" mb-3"
                  >
                    <Select
                      className="h-30"
                      placeholder="Select an option "
                      allowClear
                    >
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                      <Option value="other">Others</Option>
                    </Select>
                  </Form.Item> */}

                  {/* <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.fresher !== currentValues.fresher
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("fresher") === "other" ? (
                        <Form.Item
                          name="fresher"
                          label="fersher"
                          rules={[{ required: true }]}
                        >
                          <Input
                            style={{
                              height: "100%",
                              padding: "0.375rem 0.75rem",
                              lineHeight: "2px",
                              borderRadius: "0.25rem",
                              border: "1px solid #ced4da",
                            }}
                          />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item> */}
                  <Form.Item
                    name="skills"
                    label="Your Skills"
                    className="mb-3"
                    rules={[
                      {
                        required: "true",
                        value: "text",
                      },
                    ]}
                  >
                    <Input.TextArea className="form-control" />
                  </Form.Item>

                  <Form.Item
                    name="lookingFor"
                    label="Looking For"
                    rules={[{ required: true }]}
                    className=" mb-3"
                  >
                    <Select
                      className="h-30"
                      placeholder="Select an option "
                      allowClear
                    >
                      <Option value="job">Job</Option>
                      <Option value="internship">Internship</Option>
                    </Select>
                  </Form.Item>

                  {/* <Form.Item
                    name={["user", "Introduction"]}
                    label="Tell us something"
                    className="mb-3"
                  >
                    <Input.TextArea className="form-control" />
                  </Form.Item> */}

                  {/* <input
                    required
                    className="form-control mb-3"
                    type="file"
                    onChange={(e) => {
                      setFileObj(e.target?.files[0]);
                      console.log("change", e.target.files[0]);
                    }}
                  /> */}

                  <Form.Item className="form-group text-center align-items-center">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="buttons mt-3 "
                      style={{
                        // marginLeft: "185px",
                        backgroundColor: "darkblue",
                        borderRadius: "5px",
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div style={isMobile ? mob : desk}>{/* <Footer /> */}</div>
      </AnimationRevealPage>
    </>
  );
};

export default Applynow;
