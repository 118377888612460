import React, { Component } from "react";
import Slider from "react-slick";
import { ImQuotesLeft } from "react-icons/im";
import { SliderContainer } from "./SliderContainer";

import projectphoto from "../../src/images/demo/projectphoto.jpg";
import projectphoto1 from "../../src/images/demo/Manclient.jpg";
import projectphoto2 from "../../src/images/demo/wome.jpg";
import projectphoto3 from "../../src/images/demo/Manclient4.jpg";
import projectphoto4 from "../../src/images/demo/Womenclient.jpg";
import projectphoto5 from "../../src/images/demo/Manclient2.jpg";
import projectphoto6 from "../../src/images/demo/Manclient3.jpg";
import projectphoto7 from "../../src/images/demo/Womenclient3.jpg";
import projectphoto8 from "../../src/images/demo/Manclient5.jpg";
import projectphoto9 from "../../src/images/demo/Womenclient4.jpg";

const CardSlider = () => {
  const array = [
    {
      id: 1,
      img: projectphoto,
      text: "Hii friends,I have been trained in data science under the Guidance of Procareers , I have been placed at Walmart as a Data Scientist.Their excellent conceptual teaching made me like this Today.Thank you Procareers and All Teaching Staff's for Your guidance and Support",
      heading: "Rasmika Setty",
      subheading: "Data Scientist",
    },
    {
      id: 2,
      img: projectphoto1,
      text: " I have joined Procareers for Java certification Course , I searched a lot for Java training in Bengalore , but at the end i found Procareers most suitable as per my requirements. I recommend you to join the Procareers to pursue your dreams .",
      heading: "Ayush Sharma",
      subheading: "Java developer",
    },
    {
      id: 3,
      img: projectphoto2,
      text: "Procareers gave me good knowledge and experience about SoftWare Trainings.Trainer knowledge is excellent and with this course I got useful knowledge of SoftWare Engineering.",
      heading: "Divya Kanoor",
      subheading: "Software Engineer",
    },
    {
      id: 4,
      img: projectphoto3,
      text: "I have done my Data Base training from Procareers and this is the best training institute in Bengaluru",
      heading: "Avinash Rathod",
      subheading: "Data Base Engineer",
    },
    {
      id: 5,
      img: projectphoto4,
      text: "Best Training institute in Bengaluru for SAP-FICO training. I have learned many new things in my course. It was nice experience for me.",
      heading: "Tanuja Sharma",
      subheading: "SAP FICO",
    },
    {
      id: 6,
      img: projectphoto5,
      text: " It was a pleasure for me to got Trained from Procarees, they had a very friendly staff their, they teach us not like a student but like friend. So I personally want every one who wants to do Web Designing visit Procareers.",
      heading: "Akhill Gowda",
      subheading: "Full-Stack Developer",
    },
    {
      id: 7,
      img: projectphoto6,
      text: "I have done my Full-stack from this institute and this is the best traning institute in Bengaluru",
      heading: "Bhavesh Baviskar",
      subheading: "Full-Stack Developer",
    },
    {
      id: 8,
      img: projectphoto7,
      text: "Thank u Procareers for the super guidence any dbt will be cleared by staff with out delay Trustfull...and supportive",
      heading: "Pratiksha gupta",
      subheading: "AWS DevOps Engineer",
    },
    {
      id: 9,
      img: projectphoto8,
      text: "Wonderful experience with Procareers. They are really doing wonderful job of helping many students to pursue their dreams",
      heading: "Sameer Ansari",
      subheading: "Web Devloper",
    },
    {
      id: 10,
      img: projectphoto9,
      text: "India’s best education institute. many people in shine future People are promoted in private sector and govt. sector",
      heading: "Sonom Sahoo",
      subheading: "Data Analysts",
    },
  ];
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="row">
        {" "}
        <SliderContainer>
          <Slider {...settings}>
            {array.map((val, index) => {
              return (
                <div className="col-lg-2 ">
                  {index % 2 == 0 ? (
                    <div className="border">
                      <div className="position-relative">
                        <img alt="imag" src={val.img} className="w-100" />
                        <span
                          className="position-absolute bottom-0   badge rounded-circle p-2  bg-info"
                          style={{ marginBottom: "-15px", marginLeft: "10px" }}
                        >
                          <ImQuotesLeft style={{ fontSize: "14px" }} />
                        </span>
                      </div>
                      <p className="mt-3 p-2">{val.text}</p>
                      <div className="p-2" style={{ marginTop: "-20px" }}>
                        {" "}
                        <p style={{ fontWeight: "bold", color: "black" }}>
                          {val.heading}
                        </p>
                        <p style={{ marginTop: "-20px" }}>
                          {" "}
                          <i>{val.subheading}</i>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="border mt-5 ">
                      <div className="position-relative">
                        <img alt="imag" src={val.img} className="w-100" />
                        <span
                          className="position-absolute bottom-0   badge rounded-circle p-2  bg-info"
                          style={{ marginBottom: "-15px", marginLeft: "10px" }}
                        >
                          <ImQuotesLeft style={{ fontSize: "14px" }} />
                        </span>
                      </div>
                      <p className="mt-3 p-2">{val.text}</p>
                      <div className="p-2" style={{ marginTop: "-20px" }}>
                        {" "}
                        <p style={{ fontWeight: "bold", color: "black" }}>
                          {val.heading}
                        </p>
                        <p style={{ marginTop: "-20px" }}>
                          {" "}
                          <i>{val.subheading}</i>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </Slider>
        </SliderContainer>
      </div>
    </>
  );
};

export default CardSlider;
