import React from "react";
import Who from "../../images/Who.svg";
import HireForm from "./HireForm";
import Footer from "components/footers/FiveColumnWithInputForm";
import { isMobile } from "react-device-detect";
import FiveColumnWithInputForm from "components/footers/FiveColumnWithInputForm";
const HireTalent = () => {
  return (
    <div className="overflow-x-hidden">
      <div className="grid lg:grid-cols-2 flex  space-x-10 mt-10 px-10 mb-6">
        <div>
          <img src={Who} alt="" className="" />
        </div>
        <div className="mt-4">
          <h1
            style={{ color: "black" }}
            className="mb-3 lg:text-[35px]   font-bold"
          >
            &nbsp;Looking Talent For Your Business!
          </h1>
          <div className="mt-2">
            <HireForm />
          </div>
        </div>
      </div>
      <FiveColumnWithInputForm />
    </div>

    // <div style={{ overflowX: "hidden" }}>
    //   <div style={isMobile ? mob : desk}>
    //     <div className="grid md:grid-flow-col grid-cols-2 my-4">
    //       <div className="col-span-1 hidden md:block lg:w-full xl:w-full xxl:w-full ">
    //         <img
    //           className="h-full object-cover my-1"
    //           src={Who}
    //           alt="hire-talent"
    //         />
    //       </div>
    //       <div className="col-span-9 sm:col-span-12">
    //         <h1 style={{color:"black"}} className="mb-3 text-[25px]">
    //           &nbsp;Looking Talent For Your Business!
    //         </h1>
    //         <HireForm />
    //       </div>
    //     </div>
    //   </div>
    //   <Footer />
    // </div>
  );
};

export default HireTalent;
