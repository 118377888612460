import React, { Component } from "react";
import Slider from "react-slick";
import "./TrustSlider.css";
import {
  AiOutlineCloudServer,
  AiOutlineCopyrightCircle,
  AiOutlineDesktop,
  AiOutlineDollarCircle,
  AiOutlineEdit,
  AiOutlineFieldTime,
  AiOutlineGlobal,
  AiOutlineSafetyCertificate,
  AiOutlineSchedule,
  AiOutlineSetting,
  AiOutlineSound,
  AiOutlineUserAdd,
  AiOutlineUserSwitch,
} from "react-icons/ai";
import { isMobile } from "react-device-detect";
const TrustSlider = () => {
  const topmob = {
    fontWeight: "bold",
    color: "black",
    marginTop: "10px",
  };
  const topdesk = {
    fontWeight: "bold",
    color: "black",
  };
  const arr = [
    {
      // txt: "AWS DevOps ",
      txt: (
        <p className="mt-2 " style={{ fontWeight: "bold", color: "black" }}>
          AWS DevOps
        </p>
      ),

      icons: <AiOutlineSafetyCertificate className=" text-primary" />,
    },
    {
      // txt: "Sales Force",

      txt: (
        <p className="mt-2 " style={{ fontWeight: "bold", color: "black" }}>
          Sales Force{" "}
        </p>
      ),

      icons: <AiOutlineCloudServer className="text-primary" />,
    },
    {
      txt: <p style={isMobile ? topmob : topdesk}>SAP MM Consultant </p>,

      icons: <AiOutlineDesktop className=" text-primary" />,
    },
    {
      txt: <p style={isMobile ? topmob : topdesk}>FullStack Development </p>,

      icons: <AiOutlineGlobal className=" text-primary" />,
    },
    {
      txt: (
        <p className="mt-2 " style={{ fontWeight: "bold", color: "black" }}>
          SAP ABAB{" "}
        </p>
      ),

      icons: <AiOutlineUserAdd className=" text-primary" />,
    },
    {
      txt: (
        <p className="mt-2 " style={{ fontWeight: "bold", color: "black" }}>
          SAP BASIS{" "}
        </p>
      ),

      icons: <AiOutlineCopyrightCircle className=" text-primary" />,
    },
    {
      // txt: "SoftWare Testing",
      txt: <p style={isMobile ? topmob : topdesk}>SoftWare Testing </p>,

      icons: <AiOutlineSetting className=" text-primary" />,
    },
    {
      txt: (
        <p className="mt-2 " style={{ fontWeight: "bold", color: "black" }}>
          .Net DeveLoper{" "}
        </p>
      ),
      // para: "50+ courses available",
      icons: <AiOutlineUserSwitch className=" text-primary" />,
    },
  ];

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="p-2">
        <div className="row mt-3">
          <Slider {...settings}>
            {arr.map((val) => {
              return (
                <div className="col-lg-2">
                  <div
                    className="d-flex justify-content-center"
                    style={{
                      height: "60px",
                      border: "1px solid #d9d9d940",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      className="p-3   "
                      style={{ fontSize: "30px", backgroundColor: "#ffc0cb24" }}
                    >
                      {val.icons}
                    </div>
                    <div
                      className="p-2"
                      style={{ backgroundColor: "#0000ff08" }}
                    >
                      <p style={{ fontWeight: "bold", color: "black" }}>
                        {val.txt}
                      </p>
                    </div>
                  </div>
                  <div>
                    <a
                      className="carousel-control-prev mt-5"
                      href="#projectsCarouselMob"
                      role="button"
                      data-slide="prev"
                    >
                      <i className="fas fa-arrow-left" />
                      <span className="sr-only">Previous</span>
                    </a>
                    <a
                      className="carousel-control-next mt-5"
                      href="#projectsCarouselMob"
                      role="button"
                      data-slide="next"
                    >
                      <i className="fas fa-arrow-right" />
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default TrustSlider;
