import React from "react";
import Light from "components/headers/light";
import Footer from "components/footers/FiveColumnWithInputForm";
import { Link, useParams } from "react-router-dom";
import "../src/styles/Mg.css";
import { useEffect, useState } from "react";
import { localUrl, localUrlImage, url } from "pages/env";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import { white } from "color-name";
import { margin } from "@mui/system";
import { Button } from "antd";
import tw from "twin.macro";
import Certificateimg from "../src/images/Certificateimg.jpg";
import "../src/index.css";
import Gmg from "./images/Gmg.png";
import { isMobile } from "react-device-detect";
import "./Course.css";

// import Certificateimg from "../../images/Certificateimg.jpg";

const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-2 md:py-2`;

const NavLink = tw.a`  text-lg my-5 lg:text-sm lg:mx-3 lg:my-0  font-semibold tracking-wide transition duration-300  pb-1 border-b-2 border-transparent hover:border-primary-900 hocus:text-primary-500`;
const PrimaryLink = tw(
  NavLink
)`lg:mx-0 px-2 py-1 rounded bg-primary-500 text-gray-100 hocus:bg-primary-900 hocus:text-gray-200 focus:shadow-outline border-b-0`;

const Np = () => {
  const [pro, setPro] = useState([{}]);
  const { id } = useParams();
  const padmob = { marginTop: "20px" };
  const paddesk = {};
  //  console.log("patrrram",param)
  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(localUrlImage + `/job/jobs_details/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPro(result);

        console.log(result, "res");
      })
      .catch((error) => console.log("error", error));
  }, [id]);

  return (
    <>
      <AnimationRevealPage>
        <div className="my-0"></div>
        <div className="gc_main_menu_wrapper pt-0">
          <section>
            <div
              data-track="true"
              data-track-app="front_page"
              data-track-page="front_page_story"
              data-track-action="click"
              data-track-component="enterprise_cta_view"
              className="css-1wg4got"
              role="presentation"
            >
              <section
                className="rc-EnterpriseCtaRebrand css-4c78qm"
                style={{ padding: "15px 7px" }}
              >
                <div className="css-17wkacp">
                  <h2 className="cds-7 css-102hgme cds-9">
                    Training For {pro?.title}
                  </h2>

                  <div className="css-d29qp5">
                    {/* <a
                    data-click-key="front_page.front_page_story.click.coursera_for_enterprise"
                    data-click-value='{"href":"/business?utm_campaign=website&utm_content=banner-from-B2C-home-for-enterprise&utm_medium=coursera&utm_source=enterprise","namespace":{"action":"click","app":"front_page","component":"coursera_for_enterprise","page":"front_page_story"},"schema_type":"FRONTEND"}'
                    data-track="true"
                    data-track-app="front_page"
                    data-track-page="front_page_story"
                    data-track-action="click"
                    data-track-component="coursera_for_enterprise"
                    data-track-href="/business?utm_campaign=website&utm_content=banner-from-B2C-home-for-enterprise&utm_medium=coursera&utm_source=enterprise"
                    href="/business?utm_campaign=website&utm_content=banner-from-B2C-home-for-enterprise&utm_medium=coursera&utm_source=enterprise"
                    to="/business?utm_campaign=website&utm_content=banner-from-B2C-home-for-enterprise&utm_medium=coursera&utm_source=enterprise"
                    className="cds-66 cds-37 cds-39 css-l8m146 cds-48 cds-button-disableElevation"
                    tabIndex={0}
                    aria-disabled="false"
                    role="button"
                  >
                    <span className="cds-38 cds-button-label">
                      whitehr for Enterprise
                    </span>
                  </a> 
                     <a
                    data-click-key="front_page.front_page_story.click.coursera_for_teams"
                    data-click-value='{"href":"/business/teams?utm_campaign=website&utm_content=banner-from-B2C-home-for-teams&utm_medium=coursera&utm_source=enterprise","namespace":{"action":"click","app":"front_page","component":"coursera_for_teams","page":"front_page_story"},"schema_type":"FRONTEND"}'
                    data-track="true"
                    data-track-app="front_page"
                    data-track-page="front_page_story"
                    data-track-action="click"
                    data-track-component="coursera_for_teams"
                    data-track-href="/business/teams?utm_campaign=website&utm_content=banner-from-B2C-home-for-teams&utm_medium=coursera&utm_source=enterprise"
                    href="/business/teams?utm_campaign=website&utm_content=banner-from-B2C-home-for-teams&utm_medium=coursera&utm_source=enterprise"
                    to="/business/teams?utm_campaign=website&utm_content=banner-from-B2C-home-for-teams&utm_medium=coursera&utm_source=enterprise"
                    className="cds-66 cds-37 cds-39 css-l8m146 cds-48 cds-button-disableElevation"
                    tabIndex={0}
                    aria-disabled="false"
                    role="button"
                  >
                    <span className="cds-38 cds-button-label">
                      whitehr for Teams
                    </span>
                  </a> * */}
                    <p>{pro.desc}</p>
                  </div>
                </div>
                <div className="css-bjriq3">
                  <p className="css-tttaxl">
                    {/* <span className="cds-7 css-1wpet1o cds-9">
                    Thousands of institutions use whitehr enterprise offering.
                  </span> */}
                  </p>
                  <ul className="css-18zzja8">
                    <li className="css-9c3b03">
                      <div>
                        <img
                          src={pro.image}
                          className="css-jbpof6"
                          alt="Mindtree"
                          style={{ height: "250px", width: "950px" }}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </section>
            </div>
            <div
              className="row"
              style={
                {
                  // border: "1px solid black",
                  // backgroundColor: "white",
                  // maxHeight: 400,
                }
              }
            >
              <div
                className="col-lg-6 col-md-6 col-sm-12 "
                style={{
                  // background: "white",
                  backgroundImage: `url(${Gmg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <img
                  className="img "
                  alt="ITIL 4 Foundation"
                  style={{ width: "100%", height: "96%" }}
                  src={Certificateimg}
                />
              </div>
            </div>

            <section
              id="training-highlights"
              className="section highlights-feature"
            >
              <p className="heading">Course Highlights</p>
              <div className="highlights">
                <div className="highlight">
                  <div className="image-container">
                    <img
                      src="https://trainings.internshala.com/cached_uploads/machine-learning/signup_page_media/training_highlight_icons_tmt/video_tutorials.png"
                      alt="Learn online"
                      className="section-img"
                    />
                  </div>
                  <div className="details">
                    <p className="sub-heading">Learn online</p>
                    <p className="description">At your own schedule</p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="image-container">
                    <img
                      src="https://trainings.internshala.com/cached_uploads/machine-learning/signup_page_media/training_highlight_icons_tmt/mobile_friendly.png"
                      alt="Mobile friendly"
                      className="section-img"
                    />
                  </div>
                  <div className="details">
                    <p className="sub-heading">Mobile friendly</p>
                    <p className="description">No laptop? No problem</p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="image-container">
                    <img
                      src="https://trainings.internshala.com/cached_uploads/machine-learning/signup_page_media/training_highlight_icons_tmt/placement_assistance.png"
                      alt="Placement assistance"
                      className="section-img"
                    />
                  </div>
                  <div className="details">
                    <p className="sub-heading">Placement assistance</p>
                    <p className="description">To build your career</p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="image-container">
                    <img
                      src="https://trainings.internshala.com/cached_uploads/machine-learning/signup_page_media/training_highlight_icons_tmt/certificate_of_training.png"
                      alt="Certificate of Training"
                      className="section-img"
                    />
                  </div>
                  <div className="details">
                    <p className="sub-heading">Certificate of Training</p>
                    <p className="description">From Internshala Trainings</p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="image-container">
                    <img
                      src="https://trainings.internshala.com/cached_uploads/machine-learning/signup_page_media/training_highlight_icons_tmt/projects-new.png"
                      alt="5 projects"
                      className="section-img"
                    />
                  </div>
                  <div className="details">
                    <p className="sub-heading">5 projects</p>
                    <p className="description">For hands-on practice</p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="image-container">
                    <img
                      src="https://trainings.internshala.com/cached_uploads/machine-learning/signup_page_media/training_highlight_icons_tmt/doubt_clearing.png"
                      alt="Doubt clearing"
                      className="section-img"
                    />
                  </div>
                  <div className="details">
                    <p className="sub-heading">Doubt clearing</p>
                    <p className="description">Through Q&amp;A forum</p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="image-container">
                    <img
                      src="https://trainings.internshala.com/cached_uploads/machine-learning/signup_page_media/training_highlight_icons_tmt/beginner_friendly.png"
                      alt="Beginner friendly"
                      className="section-img"
                    />
                  </div>
                  <div className="details">
                    <p className="sub-heading">Beginner friendly</p>
                    <p className="description">No prior knowledge required</p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="image-container">
                    <img
                      src="https://trainings.internshala.com/cached_uploads/machine-learning/signup_page_media/training_highlight_icons_tmt/weeks.png"
                      alt="6 weeks duration"
                      className="section-img"
                    />
                  </div>
                  <div className="details">
                    <p className="sub-heading">6 weeks duration</p>
                    <p className="description">1 hr/day (flexible schedule)</p>
                  </div>
                </div>
                <div className="highlight">
                  <div className="image-container">
                    <img
                      src="https://trainings.internshala.com/cached_uploads/machine-learning/signup_page_media/training_highlight_icons_tmt/downloadable_content.png"
                      alt="Downloadable content"
                      className="section-img"
                    />
                  </div>
                  <div className="details">
                    <p className="sub-heading">Downloadable content</p>
                    <p className="description">With lifetime access</p>
                  </div>
                </div>
              </div>
            </section>

            {/* <div className="skills-section-wrapper">
              <div className="skills-section container" id="skills-section">
                <h3 className="skills-section__heading">
                  What you learn in {pro?.title} ?
                </h3>
                <div className="skills-concepts">
                  <div className="skills-concepts__item">
                    <div className="skills-concepts__img">
                      <img
                        src="https://d1vwxdpzbgdqj.cloudfront.net/assets/enterprise/icons/check-1dc37933bd2805f15bf40a321cf78bb8c305006e900bfb99bb97aa1f942ce541.svg"
                        alt="tick"
                      />
                    </div>
                    <div className="skills-concepts__concept">
                      {pro?.title1}
                    </div>
                  </div>
                  <div className="skills-concepts__item">
                    <div className="skills-concepts__img">
                      <img
                        src="https://d1vwxdpzbgdqj.cloudfront.net/assets/enterprise/icons/check-1dc37933bd2805f15bf40a321cf78bb8c305006e900bfb99bb97aa1f942ce541.svg"
                        alt="tick"
                      />
                    </div>
                    <div className="skills-concepts__concept">
                      {pro?.title2}
                    </div>
                  </div>
                  <div className="skills-concepts__item">
                    <div className="skills-concepts__img">
                      <img
                        src="https://d1vwxdpzbgdqj.cloudfront.net/assets/enterprise/icons/check-1dc37933bd2805f15bf40a321cf78bb8c305006e900bfb99bb97aa1f942ce541.svg"
                        alt="tick"
                      />
                    </div>
                    <div className="skills-concepts__concept">
                      {pro?.title3}
                    </div>
                  </div>
                  <div className="skills-concepts__item">
                    <div className="skills-concepts__img">
                      <img
                        src="https://d1vwxdpzbgdqj.cloudfront.net/assets/enterprise/icons/check-1dc37933bd2805f15bf40a321cf78bb8c305006e900bfb99bb97aa1f942ce541.svg"
                        alt="tick"
                      />
                    </div>
                    <div className="skills-concepts__concept">
                      {pro?.title4}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </section>
          <section>
            <div
              className="trining-options sticky_place"
              id="trainingOptions"
              data-sticky-name="Training Options"
            >
              <div className="container">
                {/* <h2>
                  <b>Training Options</b>
                </h2> */}

                <div
                  className="course-outline-wrapper"
                  style={{ width: "100%" }}
                >
                  <div className="course-outline container" id="course-outline">
                    <h3 className="course-outline__heading">Course Outline</h3>

                    <section id="why-learn">
                      <h2 className="heading" style={{ color: "black" }}>
                        What you learn in {pro?.title}
                      </h2>
                      <div className="details">
                        <div className="points">
                          <div className="point">
                            <h3 className="sub-heading">{pro?.title1}</h3>
                            <p className="description">{pro?.desc1}</p>
                          </div>
                          <div className="point">
                            <h3 className="sub-heading">{pro?.title2}</h3>
                            <p className="description">{pro?.desc2}</p>
                          </div>
                          <div className="point">
                            <h3 className="sub-heading">{pro?.title3}</h3>
                            <p className="description">{pro?.desc3}</p>
                          </div>
                          <div className="point">
                            <h3 className="sub-heading">{pro?.title4}</h3>
                            <p className="description">{pro?.desc4}</p>
                          </div>
                        </div>
                        {/* <div className="image-container">
      <img
        src="https://trainings.internshala.com/cached_uploads/machine-learning/signup_page_media/illustration-images/why-learn.png"
        alt="Machine Learning Course"
        className="section-img"
      />
    </div> */}
                      </div>
                    </section>

                    {/* <div className="acc-section gl-accordion-new">
                      <div className="gl-accordion acc-nested">
                        <div className="acc-card">
                          <div className="acc-title " data-init="init">
                            {pro?.title1}
                          </div>
                          <div className="acc-body">
                            <div className="acc-content show_bulletin_pts">
                              <p>{pro?.desc1}</p>
                              <p>&nbsp;</p>
                            </div>
                          </div>
                        </div>
                        <div className="acc-card">
                          <div className="acc-title " data-init="init">
                            {pro?.title2}
                          </div>
                          <div className="acc-body">
                            <div className="acc-content show_bulletin_pts">
                              <p>
                                {pro?.desc2}
                                <br />
                                &nbsp;
                              </p>
                              <p>&nbsp;</p>
                            </div>
                          </div>
                        </div>
                        <div className="acc-card">
                          <div className="acc-title " data-init="init">
                            {pro?.title3}
                          </div>
                          <div className="acc-body">
                            <div className="acc-content show_bulletin_pts">
                              <p>{pro?.desc3}</p>
                              <p>&nbsp;</p>
                            </div>
                          </div>
                        </div>
                        <div className="acc-card">
                          <div className="acc-title " data-init="init">
                            {pro?.title4}
                          </div>
                          <div className="acc-body">
                            <div className="acc-content show_bulletin_pts">
                              <p>{pro?.desc4}</p>
                              <p>&nbsp;</p>
                            </div>
                          </div>
                        </div>  */}

                    {/* <button
          className="acc-view-more-btn"
          element=".js-course-module"
          target="#course-outline"
        >
          <span className="btn-text">View More </span>{" "}
          <img
            className="btn-img lazy gl-lazy-loaded fadeIn"
            data-src="https://d1vwxdpzbgdqj.cloudfront.net/assets/icons/blue-down-arrow-5a4de4addc964d44c148d9ab1c1e3cf89faf986971035ae94a49320f605b0dbe.svg"
            src="https://d1vwxdpzbgdqj.cloudfront.net/assets/icons/blue-down-arrow-5a4de4addc964d44c148d9ab1c1e3cf89faf986971035ae94a49320f605b0dbe.svg"
            data-loaded="true"
          />
        </button> */}
                    {/* </div>
                    </div> */}
                  </div>
                </div>

                {/* <ul>
    <li>
      <input type="checkbox" defaultChecked="" />
      <i />
      <h2>Languages Used</h2>
      <p>
        This page was written in HTML and CSS. The CSS was compiled from SASS. I
        used Normalize as my CSS reset and -prefix-free to save myself some
        headaches. I haven't quite gotten the hang of Slim for compiling into
        HTML, but someday I'll use it since its syntax compliments that of SASS.
        Regardless, this could all be done in plain HTML and CSS.
      </p>
    </li>
    <li>
      <input type="checkbox" defaultChecked="" />
      <i />
      <h2>How it Works</h2>
      <p>
        Using the sibling and checked selectors, we can determine the styling of
        sibling elements based on the checked state of the checkbox input
        element. One use, as demonstrated here, is an entirely CSS and HTML
        accordion element. Media queries are used to make the element responsive
        to different screen sizes.
      </p>
    </li>
    <li>
      <input type="checkbox" defaultChecked="" />
      <i />
      <h2>Points of Interest</h2>
      <p>
        By making the open state default for when :checked isn't detected, we
        can make this system accessable for browsers that don't recognize
        :checked. The fallback is simply an open accordion. The accordion can be
        manipulated with Javascript (if needed) by changing the "checked"
        property of the input element.
      </p>
    </li>
  </ul> */}

                {/* <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        {pro?.title1}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse show"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">{pro?.desc1}</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        {pro?.title2}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">{pro?.desc2}</div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        {pro?.title3}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">{pro?.desc3}</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        {pro?.title4}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">{pro?.desc4}</div>
                    </div>
                  </div>
                </div> */}

                <div className="option-list no-bar">
                  <div
                    className="col-md-4 selfpaced pb-[35px]"
                    style={{ paddingBottom: "35px" }}
                  >
                    {/* <br></br> */}
                    <div style={isMobile ? padmob : paddesk}>
                      <h3 className="cp-type" data-test="selfpaced-cp-type">
                        {pro.title}
                      </h3>
                      {/* <div className="tr-head no-emi">
                      <p className="type-info">
                        <span
                          id="osl-strike-final"
                          className="price"
                          data-test="selfpaced-price"
                        > */}
                      <div
                        className="list-info"
                        id="self-placed"
                        data-test="self-placed-list-info"
                      >
                        <ul>
                          <li>{pro.why1}</li>
                          <li>{pro.why2}</li>
                          <li>{pro.why3}</li>
                          <li>{pro.why4}</li>
                          <li>{pro.why5}</li>
                        </ul>
                      </div>
                      <div id="ContactUsForm">
                        <center>
                          <Link
                            to={`/TrainingPricing/${id}`}
                            style={{ marginTop: "30px" }}
                            // className="btn btn-primary btn-sm"
                            // style={{ paddingBottom: "6px", color: "white", borderRadius:"5px" }}
                          >
                            <Button
                              type="primary"
                              className="btn-primary my-0 rounded"
                              style={{
                                width: "3cm",
                                backgroundColor: "blue",
                              }}
                            >
                              Enroll Now
                            </Button>
                          </Link>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div>
            <Footer />
          </div>
        </div>
      </AnimationRevealPage>
    </>
  );
};

export default Np;
