import React, { useState, useEffect } from "react";
import {
  BsEnvelope,
  BsFillBookFill,
  BsFillBriefcaseFill,
  BsFillPencilFill,
  BsGeoAlt,
  BsPencilSquare,
  BsPersonSquare,
  BsPhone,
  BsWindowSidebar,
} from "react-icons/bs";
import AppliedJobs from "./AppliedJobs";
import EditProfile from "./EditProfile";
import MyCourse from "./MyCourse";
import { localUrl } from "pages/env";
import { useSelector } from "react-redux";
import MyProfile from "./MyProfile";

const Index = () => {
  const count = useSelector((state) => state.userSlice);
  console.log(count, "op");

  const [toggle, setToggle] = useState(true);
  const [active, setActive] = useState("My Profile");
  const [userid, setUserId] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  useEffect(() => {
    console.log(localStorage, "local");
    if (localStorage.getItem("access_token") != null) {
      var myHeaders = new Headers();
      myHeaders.append(
        "authorization",
        "Bearer " + localStorage.getItem("access_token")
      );

      var formdata = new FormData();

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(localUrl + "user/profile/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result, "re");
          setName(result.name);
          setEmail(result.email);
          setUserId(result.id);
        })
        .catch((error) => console.log("error", error));
    }
  }, [count]);
  return (
    <>
      {/* <div className="row mt-2 p-2"> */}
      {/* <div className="col-lg-2"></div> */}
      <div
        className="row border rounded m-2"
        style={{ backgroundColor: "#337ab7" }}
      >
        <div className="col-lg-12  ">
          <div className="col-lg-2 "></div>
          <div className="col-lg-8 mt-5 mb-2">
            {" "}
            <b style={{ fontSize: "32px", color: "white" }}>
              USER PROFILE
            </b>{" "}
            <hr className="mt-3 mb-2 text-white" />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="col-lg-2"></div>
          <div className="col-lg-8  ">
            <p>
              <b style={{ fontSize: "30px", color: "white" }}>{name}</b>

              <p
                className="d-flex mt-2 text-white"
                style={{ marginBottom: "-1px" }}
              >
                <BsEnvelope className="mt-1 text-white" /> &nbsp;{email}
              </p>
            </p>
            {/* <p className="mt-2 text-white">
              <BsFillPencilFill />
            </p> */}
          </div>
        </div>
      </div>

      {/* </div> */}
      <div className="row mt-2 p-4">
        <div className="col-lg-3 h-100">
          <div className="card text-center">
            <ul className="list-group list-group-flush">
              <li className="list-group-item p-4">
                <button
                  onClick={() => {
                    setActive("My Profile");
                    setToggle(!toggle);
                  }}
                  style={{ fontSize: "20px", display: "flex" }}
                >
                  <BsPersonSquare className="mt-1" /> &nbsp; My Profile
                </button>
              </li>
              <hr style={{ color: "#80808017" }} />
              <li className="list-group-item p-4">
                {" "}
                <button
                  onClick={() => {
                    setActive("Edit Profile");
                    setToggle(!toggle);
                  }}
                  style={{ fontSize: "20px", display: "flex" }}
                >
                  <BsPencilSquare className="mt-1" />
                  &nbsp; Edit Profile
                </button>
              </li>
              <hr style={{ color: "#80808017" }} />
              <li className="list-group-item p-4">
                {" "}
                <button
                  onClick={() => {
                    setActive("My Course");
                    setToggle(!toggle);
                  }}
                  style={{ fontSize: "20px", display: "flex" }}
                >
                  <BsFillBookFill className="mt-1" />
                  &nbsp; My Course
                </button>
              </li>
              <hr style={{ color: "#80808017" }} />
              <li className="list-group-item p-4">
                {" "}
                <button
                  onClick={() => {
                    setActive("Applied Jobs");
                    setToggle(!toggle);
                  }}
                  style={{ fontSize: "20px", display: "flex" }}
                >
                  <BsFillBriefcaseFill className="mt-1" />
                  &nbsp; Applied Jobs
                </button>
              </li>
            </ul>
            {/* <div className="card-footer">
                {" "}
                <button
                  onClick={() => {
                    setActive("Applied Jobs");
                    setToggle(!toggle);
                  }}
                  style={{ fontSize: "20px" }}
                >
                  Applied Jobs
                </button>
              </div> */}
          </div>
        </div>
        <div className="col-lg-9 p-3" style={{ border: "1px solid #0000000d" }}>
          {active === "My Profile" && (
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-7">
                <p
                  className="mb-2 text-center font-bold text-decoration-underline"
                  style={{ fontSize: "22px" }}
                >
                  MY PROFILE
                </p>
                <MyProfile />
              </div>
            </div>
          )}
          {active === "Edit Profile" && <EditProfile />}
          {active === "My Course" && <MyCourse props={userid} />}
          {active === "Applied Jobs" && <AppliedJobs props={userid} />}
        </div>
      </div>
    </>
  );
};

export default Index;
