import React from "react";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { useState } from "react";
import { message } from "antd";
import Procareerlogo from "images/Procareerlogo.png";
import validator from "validator";
import { Spin } from "antd";
import { loginInfoVar } from "store/slices/userSlice";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import { localUrl, localUrlImage } from "pages/env";
import { Loading3QuartersOutlined } from "@ant-design/icons";
// import HeaderLogin from "components/headers/HeaderLogin.css"

const SignupHeader2 = () => {
  const navigate = useNavigate();
  const [Data, SetData] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    phoneNumber: "",
  });
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [checkbox, setCheckbox] = useState(false);

  // const ChangeHandler = (e) => {
  //   SetData({ ...Data, [e.target.name]: e.target.value });
  // };
  const [emptyName, setemptyName] = useState(null);
  const [emptyEmailSignup, setemptyEmailSignup] = useState(null);
  const [emptyPhoneSignup, setemptyPhoneSignup] = useState(null);
  const [emptyPassSignup, setemptyPassSignup] = useState(null);
  const [emptyConfirmPassSignup, setemptyConfirmPassSignup] = useState(null);
  const ChangeHandler = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    if (name === "name" && value === "") {
      setemptyName(value);
    } else if (name === "email" && value === "") {
      setemptyEmailSignup(value);
    } else if (name === "phoneNumber" && value === "") {
      setemptyPhoneSignup(value);
    } else if (name === "password" && value === "") {
      setemptyPassSignup(value);
    } else if (name === "password2" && value === "") {
      setemptyConfirmPassSignup(value);
    } else {
      setemptyName(value);
      setemptyEmailSignup(value);
      setemptyPhoneSignup(value);
      setemptyPassSignup(value);
      setemptyConfirmPassSignup(value);
    }

    // setsignupform({ ...signupform, [name]: value });
    SetData({ ...Data, [e.target.name]: e.target.value });
  };
  const [messageApi, contextHolder] = message.useMessage();
  const SignupHandler = (e) => {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("username", Data.name);
    formdata.append("email", Data.email);
    formdata.append("password", Data.password);
    formdata.append("confirm_password", Data.password2);
    // formdata.append("phoneNumber", Data.phoneNumber);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
  
    if (
      Data.name == "" ||
      Data.phoneNumber == "" ||
      Data.password == "" ||
      Data.password2 == "" ||
      Data.email == ""
    ) {
      setError("Please fill in all the fields");
    } else if (Data.name.trim() == "") {
      setError("Username cannot be empty");
    } else if (Data.email == "") {
      setError("Email cannot be empty");
    } else if (!checkbox) {
      setError("Please accept the Terms and Conditions");
    } else if (!validator.isEmail(Data.email)) {
      setError("Invalid email format");
    } else if (Data.name.length <= 3) {
      setError("Username must be at least 4 characters");
    } else if (Data.phoneNumber == "") {
      setError("Phone number cannot be empty");
    } else if (Data.phoneNumber.length !== 10 || !validator.isMobilePhone(Data.phoneNumber)) {
      setError("Invalid phone number format");
    } else if (Data.password.length <= 7) {
      setError("Password must be at least 8 characters");
    } else if (Data.password !== Data.password2) {
      setError("Passwords do not match");
    } else {
      setError(null);
      setLoading(true);
      fetch(localUrlImage + "/user/register/", requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((result) => {
          localStorage.setItem("access_token", result?.token);
          dispatch(loginInfoVar({ loginFlag: true }));
          console.log(Data);
          navigate("/UserProfile");
        })
        .catch((error) => {
          console.error('Error:', error);
          setError("An error occurred. Please try again later.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  function validateForm(form) {
    const checked = form.target.checked;
    if (checked) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  }

  return (
    <div>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Spin />
        </div>
      ) : (
        <AnimationRevealPage>
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              {/* <div className="col-lg-12 col-xl-11"> */}
              <div className="colorofBody">
                <div className="card-body p-md-5">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                      <p
                        className="h4 fw-bold mb-3 mx-1 mx-md-4 mt-4 text-decoration-underline"
                        style={{ color: "white", textAlign: "center" }}
                      >
                        Sign up
                      </p>
                      <form className="mx-1 mx-md-4">
                        <div className="align-items-center mb-2">
                          <div className="form-outline flex-fill mb-0">
                            <label
                              className="form-label"
                              htmlFor="form3Example1c"
                              style={{
                                color: "white",
                                marginBottom: "-10px",
                              }}
                            >
                              User Name
                            </label>
                            <input
                              type="text"
                              id="form3Example1c"
                              className="form-control"
                              onChange={ChangeHandler}
                              value={Data.name}
                              placeholder="Enter your name"
                              name="name"
                              onKeyDown={(e) =>
                                [
                                  "+",
                                  "-",
                                  ".",
                                  "@",
                                  "#",
                                  "%",
                                  "$",
                                  ";",
                                  ">",
                                  "?",
                                  "&",
                                  "^",
                                  "!",
                                  "*",
                                  "(",
                                  ")",
                                  "0",
                                  "1",
                                  "2",
                                  "3",
                                  "4",
                                  "5",
                                  "6",
                                  "7",
                                  "8",
                                  "9",
                                ].includes(e.key) && e.preventDefault()
                              }
                            />
                          </div>
                          {emptyName === "" ? (
                            <small className="text-white">
                              name cannot be empty
                            </small>
                          ) : null}
                        </div>
                        <div className="d align-items-center mb-2">
                          <div className="form-outline flex-fill mb-0">
                            <label
                              className="form-label"
                              htmlFor="form3Example3c"
                              style={{
                                color: "white",
                                marginBottom: "-10px",
                              }}
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              id="form3Example3c"
                              className="form-control"
                              onChange={ChangeHandler}
                              value={Data.email}
                              name="email"
                              placeholder="Enter Your Email"
                           
                            />
                          </div>
                        </div>
                        {emptyEmailSignup === "" ? (
                          <small className="text-white">
                            Please Input Your Email
                          </small>
                        ) : null}
                        {!Data.email == "" && !validator.isEmail(Data.email) ? (
                          <small className="text-white">
                            Email Need To Be Validate"
                          </small>
                        ) : null}
                        <div className="align-items-center mb-2">
                          <div className="form-outline flex-fill mb-0">
                            <label
                              className="form-label"
                              htmlFor="form3Example4c"
                              style={{
                                color: "white",
                                marginBottom: "-10px",
                              }}
                            >
                              phone Number
                            </label>
                            <input
                             type="phonenumber"
                             pattern="[0-9]*"
                             maxLength={10}
                            //  minLength={10}
                             id="form3Example4c"
                             className="form-control"
                             onChange={ChangeHandler}
                             value={Data.phoneNumber}
                             name="phoneNumber"
                             placeholder="Enter Your Phone number"
                             onKeyDown={(e) =>
                             !/^[0-9]$/.test(e.key) && e.key !== "Backspace" &&
                              e.key !== "Delete" && e.preventDefault()
                              }
                            />

                          </div>
                        </div>
                        {emptyPhoneSignup === "" ? (
                          <small className="text-white">
                            Please Input Your Phone Number
                          </small>
                        ) : null}
                        {!Data.phoneNumber == "" &&
                        !validator.isMobilePhone(Data.phoneNumber) ? (
                          <small className="text-white">
                            phoneNumber must be 10 digits
                          </small>
                        ) : null}

                        <div className=" align-items-center mb-2">
                          <div className="form-outline flex-fill mb-0">
                            <label
                              className="form-label"
                              htmlFor="form3Example4c"
                              style={{
                                color: "white",
                                marginBottom: "-10px",
                              }}
                            >
                              Password
                            </label>
                            <input
                              type={passwordShown ? "text" : "password"}
                              id="form3Example4c"
                              minLength={7}
                              className="form-control"
                              onChange={ChangeHandler}
                              value={Data.password}
                              name="password"
                              placeholder=" Enter Your Password"
                            />
                          </div>
                        </div>
                        {emptyPassSignup === "" ? (
                          <small className="text-white">
                            Please Input Your password
                          </small>
                        ) : null}
                        {!Data.password == "" && Data.password.length <= 7 ? (
                          <small className="text-white">
                            Password must have length of 8 Chars 
                          </small>
                        ) : null}
                        <div className=" align-items-center mb-2">
                          <div className="form-outline flex-fill mb-0">
                            <label
                              className="form-label"
                              htmlFor="form3Example4c"
                              style={{
                                color: "white",
                                marginBottom: "-10px",
                              }}
                            >
                              confirm Password
                            </label>

                            <input
                              type={passwordShown ? "text" : "password"}
                              id="form3Example4cd"
                              minLength={7}
                              className="form-control"
                              onChange={ChangeHandler}
                              value={Data.password2}
                              name="password2"
                              placeholder="Enter Your Confirm Password"
                            />
                              {emptyConfirmPassSignup === "" ? (
                          <small className="text-white">
                            Please Input Your Confirm Password
                          </small>
                        ) : null}
                        {!Data.password2 == "" && Data.password2.length <= 7 ? (
                          <small className="text-white" style={{width:"au"}}>
                            Confirm Password must have length of 8 Chars
                          </small>
                        ) : null}
                            <div className="col-lg-8 col-sm-12">

                          

                              <p
                                className="text-decoration-underline "
                                onClick={togglePassword}
                                style={{
                                  color: "white",
                                  padding: "2px",
                                  marginBottom: "-5px",
                                  width: "auto",
                                  fontSize: "15px",
                                  marginTop:"10px"
                                }}
                              >
                                Show Password
                              </p>
                            </div>
                            <div className="mx-3 mt-8 " style={{marginTop:"   "}}>
                              <div className="form-check d-flex justify-content-center mb-1">
                                <input
                                  className="form-check-input me-2"
                                  type="checkbox"
                                  // defaultValue=""
                                  id="form2Example3c"
                                  value="true"
                                  required
                                  onChange={validateForm}
                                />
                      
                                {/* <label
                            className="form-check-label"
                            // htmlFor="form2Example3"
                            style={{ color: "white" }}
                          > */}
                                <div style={{ color: "white", fontSize:"12px" }}>
                                  I agree all condition in{" "}
                                </div>  
                                <a
                                  href="/TermsAndCond"
                                  style={{ color: "white",marginLeft:"10px", fontSize:"15px" } }
                                > <u>
                                  Terms & Conditions
                                  </u>
                                </a>
                                {/* <Link to="/TermsAndCond">Terms of service</Link> */}
                                {/* </label> */}
                              </div>
                            </div>
                            {/* <input type="checkbox"/>
                            <label
                              className="form-check-label"
                              htmlFor="form2Example3"
                              style={{ color: "white",marginTop:"35px" }}
                            >
                              I agree all statements in{" "}
                              <a href="/TermsAndCond" style={{color:"blue"}}>Terms of service</a>
                            </label> */}
                          </div>
                        </div>
                     
                        <div className="form-check d-flex justify-content-start mb-3">
                          <div className="col-lg-8 col-sm-12">
                            <button
                              type="button"
                              onClick={SignupHandler}
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                border: "1px solid white",
                                fontSize: "18px",
                                borderRadius: "5px",
                                padding: "4px",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              Register
                            </button>
                          </div>
                        </div>
                        {error ? (
                          <div className="text-white fs-14 text-center">
                            {error}
                          </div>
                        ) : null}
                      </form>
                    </div>
                    <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">
                      <img
                        src={Procareerlogo}
                        className="img-fluid"
                        alt="Sample image"
                        style={{ borderRadius: "50%", marginBottom: "100px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </AnimationRevealPage>
      )}
    </div>
  );
};

export default SignupHeader2;
