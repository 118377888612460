import React, { useEffect, useLayoutEffect } from "react";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "components/hero/FullWidthWithImage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
// import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";

// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import Features from "components/features/VerticalWithAlternateImageAndText.js";
// import Features from "components/features/DashedBorderSixFeatures";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// import Pricing from "components/pricing/ThreePlans.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

// import SliderCard from "components/cards/ThreeColSlider.js";
// import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import TabGrid from "components/cards/TabCardGrid.js";

// import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";

// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

// import FAQ from "components/faqs/SimpleWithSideImage.js";
// import FAQ from "components/faqs/SingleCol.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

// import ContactUsForm from "components/forms/SimpleContactUs.js";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
//
// import GetStarted from "components/cta/GetStarted.js";
// import GetStarted from "components/cta/GetStartedLight.js";
// import DownloadApp from "components/cta/DownloadApp.js";

// import Footer from "components/footers/SimpleFiveColumn.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer from "components/footers/FiveColumnWithBackground.js";
// import Footer from "components/footers/FiveColumnDark.js";
// import Footer from "components/footers/MiniCenteredFooter.js";

/* Ready Made Pages (from demos folder) */
// import EventLandingPage from "demos/EventLandingPage.js";
// import HotelLandingPage from "demos/HotelLandingPage.js";
// import AgencyLandingPage from "demos/AgencyLandingPage.js";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
// import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

/* Inner Pages */
// import LoginPage from "pages/Login.js";
// import SignupPage from "pages/Signup.js";
// import PricingPage from "pages/Pricing.js";
// import AboutUsPage from "pages/AboutUs.js";
// import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";
// import MainLandingPage from "MainLandingPage.js";
import SaaSProductLandingPage from "./demos/SaaSProductLandingPage";
import ThankYouPage from "ThankYouPage.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import AboutUs from "./pages/AboutUs";
import BlogIndex from "./pages/BlogIndex";
import ContactUs from "./pages/ContactUs";
// import Pricing from "./components/pricing/ThreePlans";
import { WhatsappChat } from "components/WhatsappChat";

import Form1 from "./pages/Form1";
import FaqFooterLink from "components/faqs/FaqFooterLink";
import PrivacyPolicy from "components/Privacy&policy/PrivacyPolicy";
import TermsAndCond from "pages/TermsAndCond";
import Training from "components/Training/Training";

import RefundPolicy from "components/RefundPolicy/RefundPolicy";
import LoginHeader from "components/headers/LoginHeader";
import SignupHeader from "components/headers/SignupHeader";
import ForgotPassward from "components/ForgotPassward/ForgotPassward";
import SignupHeader2 from "components/headers/SignupHeader2";

import TrainingPricing from "components/Training/TrainingPricing";
import CareerPage1 from "components/CareerPage/CareerPage1";
import MainLayout from "components/UserProfile/layout/MainLayout";
import ScrollToTop from "ScrollToTop";
// import Careerpage from "components/CareerPage/Careerpage";
// import light from "components/headers/light";
import Light from "components/headers/light";
import { Header } from "antd/lib/layout/layout";
import Np from "Np";
import MemberPricing from "./MemberPricing";

import Footer from "rc-table/lib/Footer";
import Certification from "components/Training/Certification";
import Education from "Educa/Education";
import HireTalent from "components/hire-talent/HireTalent";
import FrontEnd from "components/Training/FrontEnd";
import BackEnd from "components/Training/BackEnd";
// import FullStack from "components/Training/FullStack";

import Batch from "components/Batchs/Batch";
import TrainAndCerti from "components/Course/TrainAndCerti";
import FullStack from "components/Batchs/FullStack";
import Intrenship from "components/Training/Intrenship";
import ApplyNowForm from "components/headers/ApplyNowForm";
import Demoup from "components/Dashboard/Demoup";

// import FullStack from "components/Batchs/FullStack"
// import UserProfile from "components/UserProfile/UserProfile";

function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  // const [location, setLocation] = useState(window.location.pathname);
  // // // Scroll to top if path changes
  // useEffect(() => {
  //   console.log("location", location);
  //   console.log("hello 1");
  //   window.scrollTo(0, 0);
  // }, [location]);

  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <ScrollToTop />
        <Light />

        <ScrollToTop />
        {/* <Light/> */}
        <Routes>
          {/* <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} /> */}
          {/* <Route path="/components/:type/:name" element={<ComponentRenderer />} /> */}
          <Route path="/thank-you" element={<ThankYouPage />} />
          {/* <Route path="/" element={<MainLandingPage />} /> */}

          <Route path="/form/:id" element={<Form1 />} />
          <Route path="/" exact element={<SaaSProductLandingPage />} />
          <Route path="/about" exact element={<AboutUs />} />
          <Route path="/blog" exact element={<BlogIndex />} />
          <Route path="/Contact" exact element={<ContactUs />} />
          {/* <Route path="/pricing" exact element={<Pricing />} /> */}
          <Route path="/FaqFooterLink" exact element={<FaqFooterLink />} />
          <Route path="/signup" exact element={<Signup />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/PrivacyPolicy" exact element={<PrivacyPolicy />} />
          <Route path="/TermsAndCond" exact element={<TermsAndCond />} />
          <Route path="/Training" exact element={<Training />} />
          <Route path="/CareerPage" exact element={<CareerPage1 />} />
          <Route path="/hire-talent" exact element={<HireTalent />} />
          <Route path="/Applynow" exact element={<ApplyNowForm />} />

          <Route path="/RefundPolicy" exact element={<RefundPolicy />} />
          <Route path="/LoginHeader" exact element={<LoginHeader />} />
          <Route path="/SignupHeader" exact element={<SignupHeader />} />
          <Route path="/ForgotPassward" exact element={<ForgotPassward />} />
          <Route path="/SignupHeader2" exact element={<SignupHeader2 />} />
          {/* <Route path="/FrontEnd" exact element={<FrontEnd />} />
          <Route path="/BackEnd" exact element={<BackEnd />} /> */}
          {/* <Route path="/FullStack" exact element={<FullStack />} /> */}
          <Route
            path="/TrainingPricing/:id"
            exact
            element={<TrainingPricing />}
          />

          <Route
            path="/training-certificate/:id"
            exact
            element={<TrainAndCerti />}
          />
          <Route path="/Intrenship/:id" exact element={<Intrenship />} />

          <Route path="/trainingCertificates/:id" exact element={<Np />} />
          <Route path="/UserProfile" exact element={<MainLayout />} />
          <Route path="/memberPricing/:id" exact element={<MemberPricing />} />
          <Route path="/Certification/:id" exact element={<Certification />} />
          <Route path="/Batch" exact element={<Batch />} />
          <Route path="/FullStack" exact element={<FullStack />} />
          <Route path="/Demoup" exact element={<Demoup />} />
        </Routes>
        <WhatsappChat />
      </BrowserRouter>
    </>
  );
}

// export default EventLandingPage;
// export default HotelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
export default App;
