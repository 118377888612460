import React from "react";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

export const WhatsappChat = () => {
  return (
    <div>
      <WhatsAppWidget
        phoneNo="919606910681"
        position="right"
        widgetWidth="360px"
        widgetWidthMobile="360px"
        autoOpen={false}
        autoOpenTimer={5000}
        messageBox={true}
        messageBoxTxt="Hi Team, is there any related service available ?"
        iconSize="70"
        iconColor="white"
        iconBgColor="green"
        headerIcon=" https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUhz6HUxMd9mUMfDncjUVOfnmPSnmbNn2BTg&usqp=CAU"
        headerIconColor="white"
        headerTxtColor="yellow"
        headerBgColor="white"
        headerTitle="Ruhi"
        headerCaption="Online"
        bodyBgColor="#bbb"  
        chatPersonName="Support"
        chatMessage={
          <>
            Hi there 👋 <br />
            <br /> How can I help you?
          </>
        }
        footerBgColor="#999"
        btnBgColor="yellow"
        btnTxtColor="black"
        btnTxt="Start Chat"
      />




    </div>
  );
};
